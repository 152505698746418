import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { useState } from "react";
import Layout from "../../../components/Layout";
import usePaginatedApiData from "../../../hooks/usePaginatedApiData";
import { useNavigate } from "react-router-dom";
import AcceptButton from "../../../components/AcceptButton";
import AdminButton from "../../../components/AdminButton";
import CancelButton from "../../../components/CancelButton";
import EmptyData from "../../../components/EmptyData";
import FilterButton from "../../../components/FilterButton";
import TableFooter from "../../../components/TableFooter";
import TableHeader from "../../../components/TableHeader";
import TableSkeleton from "../../../components/TableSkeleton";
import { put } from "../../../utils/Api";
import { toast } from "react-toastify";

type CodeData = {
  id: number;
  name: string;
  code: string;
  min_purchase_method: string;
  min_purchase_value: number;
  discount_method: string;
  discount_value: number;
  target_type: string;
  is_never_expire: number;
  valid_date_start: string;
  valid_date_end: string;
  status: number;
  created_at: string;
  is_all_product: number;
  order_count: number;
  order_total: number;
};

type PromotionCodeSearchParams = {
  code: any;
  start_date: any;
  end_date: any;
};

const PromotionCode = (formikValues: any) => {
  const navigate = useNavigate();
  // const [stockVisible, setStockVisible] = useState(false);
  // const [stockMovementProductId, setStockMovementProductId] = useState(0);
  // const [showDetail, setShowDetail] = useState(false);
  // const [showDetailParam, setShowDetailParam] = useState({});
  const {
    data,
    loading,
    error,
    searchParams,
    currentPage,
    totalPages,
    handleSearchParamsChange,
    handlePageChange,
    handleReload,
  } = usePaginatedApiData<CodeData, PromotionCodeSearchParams>(
    "/admin/promotion",
    {
      code: null,
      start_date: null,
      end_date: null,
    },
    1,
  );

  // if (error) return <div>Error: {error.message}</div>;

  const [filterVisible, setFilterVisible] = useState(false);
  const [filterCode, setFilterCode] = useState("");
  const [filterDate, setFilterDate] = useState<any>(null);

  const rawRecords = data as any;
  const records = rawRecords?.data?.map((item: CodeData) => {
    return {
      ...item,
    };
  });

  const search = searchParams as PromotionCodeSearchParams;

  const formatFilterDate = (date: (Date | null)[]) => {
    if (!date || date.length < 1 || !date[0]) {
      return { startDate: "", endDate: "" };
    }

    const startDate = date[0];
    const endDate = date[1];

    let startDateString = "";
    let endDateString = "";

    if (startDate) {
      const start_year = startDate.getFullYear();
      const start_month = String(startDate.getMonth() + 1).padStart(2, "0");
      const start_day = String(startDate.getDate()).padStart(2, "0");
      startDateString = `${start_year}-${start_month}-${start_day}`;
    }

    if (endDate) {
      const end_year = endDate.getFullYear();
      const end_month = String(endDate.getMonth() + 1).padStart(2, "0");
      const end_day = String(endDate.getDate()).padStart(2, "0");
      endDateString = `${end_year}-${end_month}-${end_day}`;
    }

    return { startDate: startDateString, endDate: endDateString };
  };

  const formattedFilterDate = formatFilterDate(filterDate);

  const toggleCodeStatus = async (data: any) => {
    const newStatus = data.status === 1 ? 0 : 1;

    try {
      const response = await put(`/admin/promotion/${data.id}`, {
        status: newStatus,
      });
      if (response?.success) {
        toast.success(response?.message);
        handleSearchParamsChange({
          code: filterCode || null,
          start_date: formattedFilterDate?.startDate || null,
          end_date: formattedFilterDate?.endDate || null,
        });
      }
    } catch (error: any) {
      // setCodeLoading(false);
      if (
        error?.response?.data?.message &&
        typeof error?.response?.data?.message === "string"
      ) {
        toast.error(error.response.data.message);
      }
    }
    console.log("toggleCodeStatus");
  };

  return (
    <Layout>
      <Dialog
        header="Filter"
        visible={filterVisible}
        style={{
          width: "100vw",
          maxWidth: "450px",
          margin: 0,
          border: 0,
        }}
        onHide={() => setFilterVisible(false)}
      >
        <div className="mb-[10px]">
          <label htmlFor="code-id" className="mb-[5px] block">
            Code
          </label>
          <div>
            <InputText
              id="code"
              className="w-full"
              value={filterCode}
              onChange={(e) => setFilterCode(e.target.value)}
            />
          </div>
        </div>
        <div className="mb-[10px]">
          <label htmlFor="filter-mobile" className="mb-[5px] block">
            Promotion Date
          </label>
          <Calendar
            className="w-full"
            name="delivery_date"
            dateFormat="dd M yy"
            selectionMode="range"
            value={filterDate}
            onChange={(e) => {
              if (Array.isArray(e.value) && e.value.length === 2) {
                const [startDate, endDate] = e.value;
                setFilterDate([startDate, endDate]);
              }
            }}
          />
        </div>
        <div className="flex flex-row justify-between items-center pt-[10px]">
          <CancelButton
            label="Reset"
            onClick={() => {
              setFilterCode("");
              setFilterDate(null);
              handleSearchParamsChange({
                code: null,
                start_date: null,
                end_date: null,
              });
              setFilterVisible(false);
            }}
          />
          <AcceptButton
            label="Apply"
            onClick={() => {
              handleSearchParamsChange({
                code: filterCode ? filterCode : null,
                start_date: formattedFilterDate?.startDate,
                end_date: formattedFilterDate?.endDate,
              });
              setFilterVisible(false);
            }}
          />
        </div>
      </Dialog>
      <TableHeader>
        <FilterButton
          onClick={() => setFilterVisible(true)}
          className="mb-[5px] mr-[10px]"
        />
        <AdminButton
          label="Create"
          onClick={() => {
            // Use the navigate function to navigate to the /promotion-code-create route
            navigate("/promotion-code-create");
          }}
        />
      </TableHeader>
      <div className="px-[17px] pt-[17px] card-cfg">
        {loading && <TableSkeleton />}
        {!loading && records?.length > 0 && (
          <>
            <div className="overflow-x-auto">
              <table className="table-cfg">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Code</th>
                    <th>Order Quantity</th>
                    <th>Total Sales</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {records.map((row: CodeData) => {
                    return (
                      <tr key={`code-table-row-${row.id}`}>
                        <td data-label="Name">{row.name}</td>
                        <td data-label="Code ID">{row.code}</td>
                        <td data-label="Order Quantity">{row.order_count}</td>
                        <td data-label="Total Sales">{row.order_total}</td>
                        <td data-label="Start Date">{row.valid_date_start}</td>
                        <td data-label="End Date">
                          {row.is_never_expire
                            ? "Never Expire"
                            : row.valid_date_end}
                        </td>
                        <td data-label="Status" className="text-center">
                          <div
                            className={`${
                              row.status === 1 ? "bg-green-300" : "bg-gray-300"
                            } px-[8px] py-[3px] rounded-[30px] inline text-xs whitespace-nowrap w-fit lg:w-full`}
                          >
                            {row.status === 1 ? "Active" : "Inactive"}
                          </div>
                        </td>
                        <td data-label="Action">
                          <div className="flex flex-wrap gap-3 justify-end lg:justify-start">
                            <Button
                              className="p-0 text-sm shrink-0"
                              text
                              onClick={() => {
                                navigate("/promotion-code-create", {
                                  state: { code_id: row.id },
                                });
                              }}
                            >
                              <img
                                src={require("../../../assets/images/icon-view.png")}
                                alt="View icon"
                                className="w-[24px]"
                              />
                            </Button>
                            <Button
                              className="p-0 text-sm shrink-0"
                              text
                              label={row.status === 1 ? "Inactive" : "Active"}
                              onClick={() => toggleCodeStatus(row)}
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <TableFooter
              itemsFrom={rawRecords?.from}
              itemsTo={rawRecords?.to}
              itemsTotal={rawRecords?.total}
              currentPage={currentPage}
              totalPages={totalPages}
              setCurrentPage={handlePageChange}
            />
          </>
        )}
        {!loading && records?.length <= 0 && <EmptyData />}
      </div>
    </Layout>
  );
};

export default PromotionCode;
