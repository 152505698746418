import Layout from "../../../components/Layout";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { get, post, put } from "../../../utils/Api";
import { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import AcceptButton from "../../../components/AcceptButton";
import usePaginatedApiData from "../../../hooks/usePaginatedApiData";
import { Calendar } from "primereact/calendar";
import { InputSwitch } from "primereact/inputswitch";
import { Checkbox } from "primereact/checkbox";
import { SelectButton } from "primereact/selectbutton";
import { InputNumber } from "primereact/inputnumber";
import { MultiSelect } from "primereact/multiselect";
import { format } from "date-fns";
import ValidationError from "../../../components/ValidationError";
import TableFooter from "../../../components/TableFooter";
import { formatCurrency, formatDate } from "../../../utils/Common";
import TableSkeleton from "../../../components/TableSkeleton";
import FullScreenDialog from "../../../components/FullScreenDialog";
import OrderDetails from "../../OrderDetails";
import CancelButton from "../../../components/CancelButton";
import { Dialog } from "primereact/dialog";
import TableHeader from "../../../components/TableHeader";
import FilterButton from "../../../components/FilterButton";
import { Dropdown } from "primereact/dropdown";

type OrderData = {
  id: number;
  total: number;
  status: number;
  created_at: string;
};

type OrderSearchParams = {
  start_date: string;
  end_date: string;
};

const FreeGiftCreate = (props: any) => {
  const navigate = useNavigate();
  const [gift_id] = useState(useLocation()?.state?.gift_id);
  const [giftLoading, setGiftLoading] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState<any>(null);
  const [allProducts, setAllProducts] = useState<any>(null);
  const [orderFilterVisible, setOrderFilterVisible] = useState(false);
  const [filterOrderDate, setFilterOrderDate] = useState<any>(null);
  const [ordersData, setOrdersData] = useState([]);

  const [showDetail, setShowDetail] = useState(false);
  const [showDetailParam, setShowDetailParam] = useState({});

  // const sources = useApiData("/admin/get_sources");
  // const sourcesData = sources.data as any;
  // const sourcesList = sourcesData?.map((item: any) => {
  //   return { label: item?.name, value: item?.id };
  // });
  const productTemplate = (option: any) => {
    return (
      <div className="flex align-items-center">
        <img
          alt={option.name}
          src={option.image_url}
          className={`mr-2`}
          style={{ width: "18px" }}
        />
        <div>{option.name}</div>
      </div>
    );
  };

  const sourcesOptions = [
    { label: "POS", value: 1 },
    { label: "E-Commerce", value: 2 },
  ];
  const giftTypeOptions = [
    { label: "Product", value: 1 },
    { label: "Category", value: 2 },
  ];
  const purchaseMethodOptions = [
    { name: "Order Price", value: "order_price" },
    { name: "Order Quantity", value: "order_qty" },
  ];
  const discountMethodOptions = [
    { name: "Percentage", value: "percent" },
    { name: "Fixed Value", value: "fixed" },
  ];
  const targetTypeOptions = [
    { name: "New Register", value: "new_register" },
    { name: "Existing Customor", value: "existing_customor" },
    { name: "All Customor", value: "all" },
  ];
  const productsOptions = [
    { name: "All Products", value: 1 },
    { name: "Designated Products", value: 0 },
  ];

  useEffect(() => {
    // if (gift_id) {
    //   getCodeDetail();
    // }
    getAllProducts();
  }, []);

  const getCodeDetail = async () => {
    try {
      const response = await get(`/admin/promotion/${gift_id}`);
      if (response?.success && response?.data) {
        const productIds: any = response?.data?.products.map(
          (product: any) => product.id,
        );
        setSelectedProducts(productIds);

        setOrdersData(response?.data?.orders);

        // formik.setValues({
        //   id: response?.data?.id || null,
        //   name: response?.data?.name || "",
        //   source: response?.data?.code || "",
        //   min_purchase_method: response?.data?.min_purchase_method || "",
        //   min_purchase_value: response?.data?.min_purchase_value || null,
        //   discount_method: response?.data?.discount_method || "",
        //   discount_value: response?.data?.discount_value || null,
        //   target_type: response?.data?.target_type || "",
        //   is_never_expire: response?.data?.is_never_expire || 0,
        //   valid_date_start: response?.data?.valid_date_start
        //     ? format(new Date(response?.data?.valid_date_start), "yyyy-MM-dd")
        //     : "",
        //   valid_date_end: response?.data?.valid_date_end
        //     ? format(new Date(response?.data?.valid_date_end), "yyyy-MM-dd")
        //     : "",
        //   status: response?.data?.status || 0,
        //   created_at: response?.data?.created_at || "",
        //   is_all_product: response?.data?.is_all_product || 0,
        //   product_ids: productIds.toString() || "",
        // });
      }
    } catch (error: any) {
      if (
        error?.response?.data?.message &&
        typeof error?.response?.data?.message === "string"
      ) {
        toast.error(error.response.data.message);
      }
    }
  };

  const getAllProducts = async () => {
    try {
      const response = await get("/admin/get_all_products");
      if (response?.success && response?.data) {
        setAllProducts(response?.data);
      }
    } catch (error: any) {
      if (
        error?.response?.data?.message &&
        typeof error?.response?.data?.message === "string"
      ) {
        toast.error(error.response.data.message);
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      id: null,
      name: "",
      source: "",
      gift_type: "",
      quantity: 0,
      group_number: 0,
      min_purchase_method: "order_price",
      min_purchase_value: null,
      discount_method: "percent",
      discount_value: null,
      target_type: "new_register",
      is_never_expire: 0,
      valid_date_start: "",
      valid_date_end: "",
      status: 0,
      optional: 0,
      created_at: "",
      is_all_product: 1,
      product_ids: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      code: Yup.string()
        .required("Code is required")
        .min(4, "At least 4 characters"),
      min_purchase_value: Yup.number()
        .required("Value is required")
        .min(0.01, "Minimum 0.01"),
      discount_value: Yup.number()
        .required("Discount is required")
        .min(0.01, "Minimum 0.01"),
      is_never_expire: Yup.number(),
      valid_date_start: Yup.date()
        .required("Start Date is required")
        .when("is_never_expire", {
          is: 0,
          then: (schema: any) =>
            schema.max(
              Yup.ref("valid_date_end"),
              "Start Date cannot be after End Date",
            ),
        }),
      valid_date_end: Yup.date().when("is_never_expire", {
        is: 0,
        then: (schema: any) =>
          schema
            .required("End Date is required")
            .min(
              Yup.ref("valid_date_start"),
              "End Date cannot be before Start Date",
            ),
        otherwise: (schema: any) => schema.nullable(),
      }),
      is_all_product: Yup.number(),
      product_ids: Yup.string().when("is_all_product", {
        is: 0,
        then: (schema: any) => schema.required("Select Products is required"),
        otherwise: (schema: any) => schema.nullable(),
      }),
    }),
    onSubmit: async (values) => {
      const {
        id,
        name,
        // code,
        min_purchase_method,
        min_purchase_value,
        discount_method,
        discount_value,
        target_type,
        is_never_expire,
        valid_date_start,
        valid_date_end,
        status,
        created_at,
        is_all_product,
        product_ids,
      } = values;
      console.log("onSubmit");
      let response;
      try {
        setGiftLoading(true);
        if (gift_id) {
          const { valid_date_end, ...filteredValues } = values;
          if (is_never_expire === 1) {
            response = await put(`/admin/promotion/${gift_id}`, filteredValues);
          } else {
            response = await put(`/admin/promotion/${gift_id}`, values);
          }
        } else {
          const formData = new FormData();
          formData.append("name", name);
          // formData.append("code", code);
          formData.append("min_purchase_method", min_purchase_method);
          formData.append(
            "min_purchase_value",
            min_purchase_value as unknown as string,
          );
          formData.append("discount_method", discount_method);
          formData.append(
            "discount_value",
            discount_value as unknown as string,
          );
          formData.append("target_type", target_type);
          formData.append(
            "is_never_expire",
            is_never_expire as unknown as string,
          );
          formData.append("valid_date_start", valid_date_start);

          if (!is_never_expire) {
            formData.append("valid_date_end", valid_date_end);
          }
          formData.append("status", status as unknown as string);
          formData.append(
            "is_all_product",
            is_all_product as unknown as string,
          );
          if (!is_all_product) {
            formData.append("product_ids", product_ids);
          }
          response = await post("/admin/promotion", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
        }
        setGiftLoading(false);

        if (response?.success) {
          toast.success(response?.message);
        }
      } catch (error: any) {
        setGiftLoading(false);
        if (
          error?.response?.data?.message &&
          typeof error?.response?.data?.message === "string"
        ) {
          toast.error(error.response.data.message);
        }
      }
    },
  });

  const handleDateChange = (fieldName: any, selectedDate: any) => {
    const formattedDate = selectedDate
      ? format(selectedDate, "yyyy-MM-dd")
      : "";
    formik.setFieldValue(fieldName, formattedDate);
  };

  // if(code_id) {
  const {
    data,
    loading,
    error,
    searchParams,
    currentPage,
    totalPages,
    handleSearchParamsChange,
    handlePageChange,
    handleReload,
  } = usePaginatedApiData<OrderData, OrderSearchParams>(
    `admin/promotion/${gift_id}/orders`,
    {
      start_date: "",
      end_date: "",
    },
    1,
  );
  const rawRecords = data as any;
  const records = rawRecords.orders as OrderData[];
  // }

  const handleView = (id: any) => {
    setShowDetailParam({ order_id: id });
    setShowDetail(true);
  };

  const formatFilterDate = (date: (Date | null)[]) => {
    if (!date || date.length < 1 || !date[0]) {
      return { startDate: "", endDate: "" };
    }

    const startDate = date[0];
    const endDate = date[1];

    let startDateString = "";
    let endDateString = "";

    if (startDate) {
      const start_year = startDate.getFullYear();
      const start_month = String(startDate.getMonth() + 1).padStart(2, "0");
      const start_day = String(startDate.getDate()).padStart(2, "0");
      startDateString = `${start_year}-${start_month}-${start_day}`;
    }

    if (endDate) {
      const end_year = endDate.getFullYear();
      const end_month = String(endDate.getMonth() + 1).padStart(2, "0");
      const end_day = String(endDate.getDate()).padStart(2, "0");
      endDateString = `${end_year}-${end_month}-${end_day}`;
    }

    return { startDate: startDateString, endDate: endDateString };
  };

  const formattedFilterDate = formatFilterDate(filterOrderDate);

  return (
    <Layout>
      <FullScreenDialog
        visible={showDetail}
        onHide={() => setShowDetail(false)}
      >
        <OrderDetails params={showDetailParam} close={setShowDetail} />
      </FullScreenDialog>
      <Dialog
        header="Filter"
        position="bottom"
        visible={orderFilterVisible}
        style={{
          width: "100vw",
          maxWidth: "450px",
          margin: 0,
          border: 0,
        }}
        onHide={() => setOrderFilterVisible(false)}
      >
        <div className="mb-[10px]">
          <label htmlFor="filter-mobile" className="mb-[5px] block">
            Order Date
          </label>
          <Calendar
            className="w-full"
            name="order_date"
            dateFormat="dd M yy"
            selectionMode="range"
            value={filterOrderDate}
            onChange={(e) => {
              if (Array.isArray(e.value) && e.value.length === 2) {
                const [startDate, endDate] = e.value;
                setFilterOrderDate([startDate, endDate]);
              }
            }}
          />
        </div>
        <div className="flex flex-row justify-between items-center pt-[10px]">
          <CancelButton
            label="Reset"
            onClick={() => {
              setFilterOrderDate(null);
              handleSearchParamsChange({
                start_date: "",
                end_date: "",
              });
              setOrderFilterVisible(false);
            }}
          />
          <AcceptButton
            label="Apply"
            onClick={() => {
              handleSearchParamsChange({
                start_date: formattedFilterDate?.startDate,
                end_date: formattedFilterDate?.endDate,
              });
              setOrderFilterVisible(false);
            }}
          />
        </div>
      </Dialog>
      <button onClick={() => navigate(-1)} className="py-[8px] px-[12px]">
        <img
          src={require("../../../assets/images/icon-back.png")}
          alt="Back icon"
          className="w-[34px] h-[34px]"
        />
      </button>
      <div className="min-h-auto p-[17px] card-cfg">
        <form onSubmit={formik.handleSubmit}>
          <div className="flex justify-left items-center pb-[15px] text-[#DA5E18]">
            <label>Free Gift</label>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
            <div className="flex flex-col">
              <label className="ml-[5px] mb-[5px]">Name</label>
              <InputText
                className={`w-full ${
                  formik.touched.name && formik.errors.name ? "p-invalid" : ""
                }`}
                name="name"
                placeholder="Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <ValidationError
                className={
                  formik.touched.name && formik.errors.name ? "block" : "hidden"
                }
              >
                {formik.errors.name}
              </ValidationError>
            </div>
            <div className="flex flex-col">
              <label className="ml-[5px] mb-[5px]">Sources</label>
              <Dropdown
                // name={`base_plan.foc.item_details[${index}].api_key_id`}
                className={`w-full ${
                  formik.touched.source && formik.errors.source
                    ? "p-invalid"
                    : ""
                }}`}
                options={sourcesOptions}
                // inputId={`api_key_id_${index}`}
                placeholder="Select Source"
                value={formik.values.source}
                onChange={(e) => formik.setFieldValue(`source`, e.value)}
                onBlur={formik.handleBlur}
              />
              <ValidationError
                className={
                  formik.touched.source && formik.errors.source
                    ? "block"
                    : "hidden"
                }
              >
                {formik.errors.source}
              </ValidationError>
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
            <div className="flex flex-col">
              <label className="ml-[5px] mb-[5px]">Gift Type</label>
              <Dropdown
                className={`w-full ${
                  formik.touched.gift_type && formik.errors.gift_type
                    ? "p-invalid"
                    : ""
                }}`}
                options={giftTypeOptions}
                placeholder="Select Gift Type"
                value={formik.values.gift_type}
                onChange={(e) => formik.setFieldValue(`gift_type`, e.value)}
                onBlur={formik.handleBlur}
              />
              <ValidationError
                className={
                  formik.touched.gift_type && formik.errors.gift_type
                    ? "block"
                    : "hidden"
                }
              >
                {formik.errors.gift_type}
              </ValidationError>
            </div>
            <div className="flex flex-col">
              <label className="ml-[5px] mb-[5px]">Free Gift</label>
              <MultiSelect
                value={selectedProducts}
                onChange={(e) => {
                  setSelectedProducts(e.value);
                  formik.setFieldValue(`product_ids`, e.value.toString());
                  console.log(e.value);
                }}
                options={allProducts}
                optionLabel="name"
                optionValue="id"
                filter
                placeholder="Select Free Gift"
                maxSelectedLabels={1}
                className={`w-auto md:w-20rem ${
                  formik.touched.product_ids && formik.errors.product_ids
                    ? "p-invalid"
                    : ""
                }`}
                itemTemplate={productTemplate}
              />
              <ValidationError
                className={
                  formik.touched.product_ids && formik.errors.product_ids
                    ? "block"
                    : "hidden"
                }
              >
                {formik.errors.product_ids}
              </ValidationError>
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
            <div className="flex flex-col">
              <label className="ml-[5px] mb-[5px]">Quantity</label>
              <InputNumber
                className={`w-full ${
                  formik.touched.quantity && formik.errors.quantity
                    ? "p-invalid"
                    : ""
                }`}
                name="quantity"
                placeholder="Quantity"
                value={formik.values.quantity}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <ValidationError
                className={
                  formik.touched.quantity && formik.errors.quantity
                    ? "block"
                    : "hidden"
                }
              >
                {formik.errors.quantity}
              </ValidationError>
            </div>
            <div className="flex flex-col">
              <label className="ml-[5px] mb-[5px]">
                Free Gift Group Number
              </label>
              <InputNumber
                className={`w-full ${
                  formik.touched.group_number && formik.errors.group_number
                    ? "p-invalid"
                    : ""
                }`}
                name="group_number"
                placeholder="Group Number"
                value={formik.values.group_number}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <ValidationError
                className={
                  formik.touched.group_number && formik.errors.group_number
                    ? "block"
                    : "hidden"
                }
              >
                {formik.errors.group_number}
              </ValidationError>
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
            <div className="flex flex-col">
              <label className="ml-[5px] mb-[5px]">Start Date</label>
              <Calendar
                className={`w-full ${
                  formik.touched.valid_date_start &&
                  formik.errors.valid_date_start
                    ? "p-invalid"
                    : ""
                }`}
                name="start_date"
                dateFormat="dd M yy"
                placeholder="Start Date"
                value={
                  formik.values.valid_date_start
                    ? new Date(formik.values.valid_date_start)
                    : null
                }
                onChange={(e) => handleDateChange("valid_date_start", e.value)}
              />
              <ValidationError
                className={
                  formik.touched.valid_date_start &&
                  formik.errors.valid_date_start
                    ? "block"
                    : "hidden"
                }
              >
                {formik.errors.valid_date_start}
              </ValidationError>
            </div>
            <div className="flex flex-col">
              <div className="mb-[5px]">
                <label className="ml-[5px]">End Date</label>
                <Checkbox
                  className="ml-[10px]"
                  name="is_never_expire"
                  value={formik.values.is_never_expire === 1}
                  onChange={(e) => {
                    formik.setFieldValue(`is_never_expire`, e.checked ? 1 : 0);
                    formik.setFieldValue(`valid_date_end`, "");
                  }}
                  checked={formik.values.is_never_expire === 1}
                />
                <label className="pl-1 text-xs select-none">
                  Never expires
                </label>
              </div>
              <Calendar
                className={`w-full ${
                  formik.touched.valid_date_end && formik.errors.valid_date_end
                    ? "p-invalid"
                    : ""
                }`}
                name="end_date"
                dateFormat="dd M yy"
                placeholder="End Date"
                disabled={formik.values.is_never_expire === 1}
                value={
                  formik.values.valid_date_end
                    ? new Date(formik.values.valid_date_end)
                    : null
                }
                onChange={(e) => handleDateChange("valid_date_end", e.value)}
              />
              <ValidationError
                className={
                  formik.touched.valid_date_end && formik.errors.valid_date_end
                    ? "block"
                    : "hidden"
                }
              >
                {formik.errors.valid_date_end}
              </ValidationError>
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
            <div className="flex flex-col">
              <label className="ml-[5px] mb-[5px]">Min Purchase Method</label>
              <div className="flex gap-2">
                <SelectButton
                  className="w-auto"
                  value={formik.values.min_purchase_method}
                  onChange={(e) => {
                    formik.setFieldValue(`min_purchase_method`, e.value);
                  }}
                  optionLabel="name"
                  unselectable={false}
                  options={purchaseMethodOptions}
                />
                <InputNumber
                  className={`w-auto ${
                    formik.touched.min_purchase_value &&
                    formik.errors.min_purchase_value
                      ? "p-invalid"
                      : ""
                  }`}
                  name="min_purchase_value"
                  placeholder="Min Purchase"
                  minFractionDigits={2}
                  value={formik.values.min_purchase_value}
                  onChange={(e) =>
                    formik.setFieldValue("min_purchase_value", e.value)
                  }
                  onBlur={formik.handleBlur}
                />
              </div>
              <ValidationError
                className={
                  formik.touched.min_purchase_value &&
                  formik.errors.min_purchase_value
                    ? "block"
                    : "hidden"
                }
              >
                {formik.errors.min_purchase_value}
              </ValidationError>
            </div>
            <div className="flex flex-col">
              <label className="ml-[5px] mb-[5px]">Target Customer</label>
              <SelectButton
                optionLabel="name"
                value={formik.values.target_type}
                onChange={(e) => {
                  formik.setFieldValue(`target_type`, e.value);
                }}
                unselectable={false}
                options={targetTypeOptions}
              />
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
            <div className="flex flex-col">
              <label className="ml-[5px] mb-[5px]">Optional</label>
              <InputSwitch
                name="status"
                inputId="status"
                checked={formik.values.optional ? true : false}
                onChange={(e) => {
                  formik.setFieldValue(`optional`, e.value ? 1 : 0);
                }}
                onBlur={formik.handleBlur}
              />
            </div>
            <div className="flex flex-col">
              <label className="ml-[5px] mb-[5px]">
                Status : {formik.values.status ? "Active" : "Inactive"}
              </label>
              <InputSwitch
                name="status"
                inputId="status"
                checked={formik.values.status ? true : false}
                onChange={(e) => {
                  formik.setFieldValue(`status`, e.value ? 1 : 0);
                }}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>
          <AcceptButton
            label="Update"
            className="mt-[20px] ml-auto block"
            loading={giftLoading}
          />
        </form>
        {gift_id && (
          <div className="pt-[17px]">
            <div className="flex flex-wrap">
              <div className="flex w-full justify-between items-center pb-[15px] text-[#DA5E18]">
                <div className="flex flex-wrap gap-3 items-center">
                  <label>Orders History</label>
                  <div
                    className={
                      "w-fit flex items-center bg-gradient-to-b via-white to-white text-[14px] leading-[20px] rounded-[10px] p-[12px] ring-1 text-[#3EB2D7] from-[#B5F2FF] ring-[#5BDDFA]"
                    }
                  >
                    Orders
                    <span className="text-[24px] ml-[8px]">
                      {rawRecords?.orders_total_qty ?? 0}
                    </span>
                  </div>
                  <div
                    className={
                      "w-fit flex items-center bg-gradient-to-b via-white to-white text-[14px] leading-[20px] rounded-[10px] p-[12px] ring-1 text-[#3EB2D7] from-[#B5F2FF] ring-[#5BDDFA]"
                    }
                  >
                    Sales
                    <span className="text-[24px] ml-[8px]">
                      {rawRecords?.orders_total_sales
                        ? formatCurrency(rawRecords.orders_total_sales)
                        : 0}
                    </span>
                  </div>
                </div>
                <FilterButton onClick={() => setOrderFilterVisible(true)} />
              </div>
            </div>
            {loading && gift_id && <TableSkeleton />}
            {!loading && gift_id && records && (
              <div className="overflow-x-auto">
                <table className="table-cfg">
                  <thead>
                    <tr>
                      <th>Order ID</th>
                      <th>Total</th>
                      <th>Status</th>
                      <th>Created At</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {records.map((row: OrderData) => {
                      return (
                        <tr key={`table-row-${row?.id}`}>
                          <td data-label="Order ID">{row?.id}</td>
                          <td data-label="Total">{row?.total}</td>
                          <td data-label="Status">{row?.status}</td>
                          <td data-label="Date">
                            <div className="whitespace-nowrap">
                              {formatDate(row?.created_at)}
                            </div>
                          </td>
                          <td data-label="Action">
                            <button
                              className="flex ml-auto lg:mx-auto"
                              onClick={() => handleView(row?.id)}
                            >
                              <img
                                src={require("../../../assets/images/icon-view.png")}
                                alt="View icon"
                                className="w-[24px]"
                              />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}

            {/* <TableFooter
              itemsFrom={rawRecords?.from}
              itemsTo={rawRecords?.to}
              itemsTotal={rawRecords?.total}
              currentPage={currentPage}
              totalPages={totalPages}
              setCurrentPage={handlePageChange}
            /> */}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default FreeGiftCreate;
function useApiData(arg0: string) {
  throw new Error("Function not implemented.");
}
