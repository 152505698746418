import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../hooks/useStore";
import {
  setAdditionalInfo,
  setBillingInfo,
  setCart,
  updateOrderSettings,
} from "../../redux/slices/orderSlice";
import Layout from "../../components/Layout";
import { get, post } from "../../utils/Api";
import { Button } from "primereact/button";
import {
  formatCurrency,
  formatDate,
  isRole,
  showConfirmDialog,
} from "../../utils/Common";
import { InputTextarea } from "primereact/inputtextarea";
import { useFormik } from "formik";
import { Dropdown } from "primereact/dropdown";
import { useCopyToClipboard } from "usehooks-ts";
import { toast } from "react-toastify";
import QRcode from "../../components/QRcode";
import { Dialog } from "primereact/dialog";
import AcceptButton from "../../components/AcceptButton";
import { Tooltip } from "primereact/tooltip";
import GridView from "../NewOrder/GridView";
import { Inventory, InventoryGroup } from "../../interfaces";
import { InputText } from "primereact/inputtext";
import * as Yup from "yup";
import ValidationError from "../../components/ValidationError";
import { RadioButton } from "primereact/radiobutton";
import { Calendar } from "primereact/calendar";

const OrderDetails = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let order_id = useLocation()?.state?.order_id;
  if (props?.params?.order_id) {
    order_id = props?.params?.order_id;
  }
  let doNotCallPusher = useLocation()?.state?.doNotCallPusher ?? true;
  if (props?.params?.doNotCallPusher) {
    doNotCallPusher = props?.params?.doNotCallPusher;
  }
  let openQRdialog = useLocation()?.state?.openQRdialog ?? false;
  if (props?.params?.openQRdialog === true) {
    openQRdialog = props?.params?.openQRdialog;
  }
  let clicked_shipment_id: any;
  if (props?.params?.current_month_index) {
    clicked_shipment_id = props?.params?.current_month_index;
  }
  const { id } = useAppSelector((state) => state.userAuth.user) || {
    id: null,
  };
  const [orderDetail, setOrdersHistoryData] = useState<any>(null);
  const [agents, setAgents] = useState<any[]>([]);
  const [alertVisible, setAlertVisible] = useState(false);
  const [beanChangeVisible, setBeanChangeVisible] = useState(false);
  const [coffeeBeans, setCoffeeBeans] = useState<any[]>([]);
  const [updateType, setUpdateType] = useState("single");
  const [allIds, setAllIds] = useState([]);
  const [reasons, setReasons] = useState<any[]>([]);
  const [selectedReason, setSelectedReason] = useState<any>(null);
  const [reasonVisible, setReasonsVisible] = useState(false);
  const [primaryAgentVisible, setprimaryAgentVisible] = useState(false);
  const [secondaryAgentVisible, setSecondaryAgentVisible] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<any>(null);
  const [selectedShippmentStatus, setSelectedShipmentStatus] =
    useState<any>(null);
  const [selectedAgent, setSelectedAgent] = useState<any>(null);
  const [statusOptions, setStatusOptions] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(
    clicked_shipment_id === undefined ? 1 : clicked_shipment_id,
  );
  const [value, copy] = useCopyToClipboard();
  const [QRcodeVisible, setQRcodeVisible] = useState(openQRdialog);
  const [passedQuantity, setPassedQuantity] = useState<any>(null);
  const [newTotalQuantity, setNewTotalQuantity] = useState<any>(null);
  const [selectedQuantity, setSelectedQuantity] = useState<any>(null);
  const [editBeanId, setEditBeanId] = useState<any>(null);
  const [orderBundleId, setOrderBundleId] = useState<any>(null);
  const [selectedItems, setSelectedItems] = useState<
    { id: number; quantity: number; order_bundle_id: number }[]
  >([]);
  const [sameOrderItem, setSameOrderItem] = useState<any>(null);
  const [AddressVisible, setAddressVisible] = useState(false);
  const [allAddress, setAllAddress] = useState<any[]>([]);
  const [selectedAddress, setSelectedAddress] = useState("");
  const [showAddressDialog, setShowAddressDialog] = useState(false);
  const [changeShipmentDate, setChangeShipmentDate] = useState<any>(null);

  const shippingStatusOptions = [
    { id: 1, name: "To Prepare" },
    { id: 2, name: "Pending Pick Up" },
    { id: 3, name: "Shipped" },
    { id: 4, name: "Delivered" },
    { id: 5, name: "Self Collected" },
  ];

  const [shipmentInfo, setShipmentInfo] = useState<any>({
    shipmentId: null,
    productName: null,
    statusId: null,
    statusName: null,
  });

  useEffect(() => {
    getData();
  }, [order_id, selectedMonth]);

  const getData = async () => {
    try {
      const [
        orderDetail,
        orderSettingsResponse,
        agentsResponse,
        reasonsResponse,
        historyResponse,
      ] = await Promise.all([
        get(`/get_order_details?id=${order_id}&month=${selectedMonth}`),
        get(`get_setting`),
        get(`agent/get_agents?id=${order_id}&include_al=true`),
        get(`agent/get_change_reasons`),
        get(`agent/get_change_agent_history/${order_id}`),
      ]);
      if (agentsResponse?.success && agentsResponse?.data) {
        setAgents(agentsResponse.data);
      }
      if (reasonsResponse?.success && reasonsResponse?.data) {
        setReasons(reasonsResponse.data);
      }

      if (orderDetail.success && orderDetail.data) {
        setOrdersHistoryData(orderDetail.data);
        const monthLength: any = [];
        Array.from(
          Array(orderDetail?.data?.highest_subscription_month),
          (e, i) => {
            monthLength.push({ id: i + 1, name: "Month " + (i + 1) });
          },
        );
        formik.setValues({
          id: orderDetail?.data?.id,
          remarks: orderDetail?.data?.remarks || "",
        });

        setStatusOptions(monthLength);
        setSelectedStatus({
          id: selectedMonth,
          name: `Month ${selectedMonth}`,
        });
        if (orderDetail?.data?.agents[1]) {
          if (orderDetail?.data?.agents[1]?.primary === 0) {
            setSelectedAgent(orderDetail?.data?.agents[1].user_id);
          }
        }
      }
      if (orderSettingsResponse.success && orderSettingsResponse.data) {
        dispatch(updateOrderSettings(orderSettingsResponse.data));
      }
    } catch (error: any) {
      if (
        error?.response?.data?.message &&
        typeof error?.response?.data?.message === "string"
      ) {
        toast.error(error.response.data.message);
      }
    }
  };

  const handleStatusChange = (event: any) => {
    setSelectedStatus(event.target.value);
    setSelectedMonth(event.target.value.id);
  };

  const handleEditOrderClick = () => {
    const cart: any[] = [];
    const additionalInfo: any = {
      order_id: orderDetail?.id,
      delivery: orderDetail?.delivery_fee,
      // deliveryDate: new Date(orderDetail?.delivery_start_date), // Edit Order Agent must choose latest date
      delivery_formatted: formatCurrency(orderDetail?.delivery_fee),
      sum: orderDetail?.subtotal,
      sum_formatted: formatCurrency(orderDetail?.subtotal),
      tax: orderDetail?.gst_amount,
      tax_formatted: formatCurrency(orderDetail?.gst_amount),
      total: orderDetail?.total,
      total_formatted: formatCurrency(orderDetail?.total),
    };
    const billingInfo: any = {
      country_code: orderDetail?.billing_address?.country_code,
      customer_id: orderDetail?.customer_id,
      email: orderDetail?.customer?.email,
      first_name: orderDetail?.billing_address?.first_name,
      last_name: orderDetail?.billing_address?.last_name,
      mobile: orderDetail?.billing_address?.mobile,
      postal_code: orderDetail?.billing_address?.postal_code,
      same_as_billing_address:
        orderDetail?.address?.street === orderDetail?.billing_address?.street &&
        orderDetail?.address?.unit_no ===
          orderDetail?.billing_address?.unit_no &&
        orderDetail?.address?.postal_code ===
          orderDetail?.billing_address?.postal_code,
      shared_agent: orderDetail?.agents[1]
        ? orderDetail?.agents[1].agent?.id
        : null,
      venue: orderDetail?.venue_id ? orderDetail?.venue_id : null,
      shipping_country_code: orderDetail?.address?.country_code,
      shipping_first_name: orderDetail?.address?.first_name,
      shipping_last_name: orderDetail?.address?.last_name,
      shipping_mobile: orderDetail?.address?.mobile,
      shipping_postal_code: orderDetail?.address?.postal_code,
      shipping_street: orderDetail?.address?.street,
      shipping_unit_no: orderDetail?.address?.unit_no,
      street: orderDetail?.billing_address?.street,
      unit_no: orderDetail?.billing_address?.unit_no,
    };
    orderDetail?.order_items_grouped.map((group: any) => {
      const cartItems: any[] = [];
      const orderInfo: any = {
        freeGifts: [],
        purchaseOption: [],
        subscriptionInfo: { subscriptionId: null, selfCollect: false },
        subscriptionOption: [],
        sum: 0,
        sum_formatted: "",
      };
      group.map((item: any) => {
        let type = "";
        if (
          item?.order_bundles?.filter(
            (order_bundle: any) => order_bundle?.type === "product",
          )?.length > 0
        ) {
          type = "product";
        } else if (
          item?.order_bundles?.filter(
            (order_bundle: any) => order_bundle?.type === "add_on",
          )?.length > 0
        ) {
          type = "add_on";
        }
        if (item?.self_collected === 1) {
          orderInfo.subscriptionInfo.selfCollect = true;
        }
        if (item?.product?.free_gift === 0 && item?.price > 0) {
          if ([1, 2].includes(item?.purchase_option_id)) {
            cartItems.push({
              ...item?.product,
              ...{
                delivery_option_id: item?.delivery_option_id,
                quantity: item?.quantity,
              },
            });
          } else if (item?.purchase_option_id === 3) {
            cartItems.push({
              ...item?.product,
              ...{
                delivery_option_id: item?.delivery_option_id,
                price_formatted: formatCurrency(item?.price),
                quantity: item?.quantity,
                total_beans: item?.bundle?.total_beans,
                type,
                bundle: {
                  ...item?.bundle,
                  ...{
                    bundle_add_ons: item?.bundle?.bundle_add_ons?.map(
                      (bundle_add_on: any) => {
                        return {
                          ...bundle_add_on,
                          ...{
                            items_detail: JSON.parse(
                              bundle_add_on?.items_detail,
                            )?.map((item: any) => {
                              const sub_category =
                                orderDetail?.sub_categories?.filter(
                                  (category: any) =>
                                    category?.id === item?.sub_category_id,
                                );
                              return {
                                ...item,
                                ...{
                                  sub_category: sub_category[0]?.name,
                                },
                              };
                            }),
                            price_formatted: formatCurrency(
                              bundle_add_on?.price,
                            ),
                            price_diff_formatted: formatCurrency(
                              bundle_add_on?.price - item?.product?.price,
                            ),
                            quantity: 1,
                          },
                        };
                      },
                    ),
                    bundle_focs: item?.bundle?.bundle_focs?.map(
                      (bundle_foc: any) => {
                        return {
                          ...bundle_foc,
                          ...{
                            items_detail: JSON.parse(
                              bundle_foc?.items_detail,
                            )?.map((item: any) => {
                              const sub_category =
                                orderDetail?.sub_categories?.filter(
                                  (category: any) =>
                                    category?.id === item?.sub_category_id,
                                );
                              return {
                                ...item,
                                ...{
                                  sub_category: sub_category[0]?.name,
                                },
                              };
                            }),
                          },
                        };
                      },
                    ),
                    items_detail: JSON.parse(item?.bundle?.items_detail)?.map(
                      (item: any) => {
                        const sub_category =
                          orderDetail?.sub_categories?.filter(
                            (category: any) =>
                              category?.id === item?.sub_category_id,
                          );
                        return {
                          ...item,
                          ...{
                            sub_category: sub_category[0]?.name,
                          },
                        };
                      },
                    ),
                  },
                },
                bundle_items: item?.order_bundles?.map((order_bundle: any) => {
                  let freegift_no = 0;
                  const bundle_focs = item?.bundle?.bundle_focs
                    ?.filter(
                      (bundle_foc: any) =>
                        bundle_foc?.foc_type === order_bundle?.type,
                    )
                    ?.map((bundle_foc: any) =>
                      JSON.parse(bundle_foc?.items_detail),
                    );
                  if (bundle_focs?.length > 0) {
                    const filter_freegift_no = bundle_focs[0]?.filter(
                      (bundle_foc: any) =>
                        bundle_foc?.sub_category_id ===
                          order_bundle?.product?.sub_category_id ||
                        bundle_foc?.product_id === order_bundle?.product_id,
                    );
                    if (
                      filter_freegift_no?.length > 0 &&
                      filter_freegift_no[0]?.freegift_no
                    ) {
                      freegift_no = filter_freegift_no[0]?.freegift_no;
                    }
                  }
                  return {
                    ...order_bundle?.product,
                    ...{
                      addon_id: order_bundle?.addon_id,
                      quantity: order_bundle?.quantity,
                      type: order_bundle?.type,
                      freegift_no,
                    },
                  };
                }),
              },
            });
          }
        } else {
          orderInfo.freeGifts.push({
            ...item?.product,
            ...{ quantity: item?.quantity },
          });
        }
        orderInfo.purchaseOption = [item?.purchase_option];
        if (item?.subscription_option) {
          orderInfo.subscriptionInfo.subscriptionId =
            item?.subscription_option?.id;
          orderInfo.subscriptionOption = [item?.subscription_option];
        }
        orderInfo.sum += item?.price * item?.quantity;
        orderInfo.sum_formatted = formatCurrency(orderInfo?.sum);
      });
      cart.push({
        cartItems,
        orderInfo,
      });
    });
    // TODO: first order cash & carry
    dispatch(setCart(cart));
    dispatch(setAdditionalInfo(additionalInfo));
    dispatch(setBillingInfo(billingInfo));
    navigate("/new-order-cart");
  };

  const handleSecondaryAgent = () => {
    setSecondaryAgentVisible(true);
  };

  const handleReasons = () => {
    setReasonsVisible(true);
  };

  const handlePrimaryAgent = () => {
    setReasonsVisible(false);
    setprimaryAgentVisible(true);
  };

  const handleShipmentStatus = (
    shippingId: any,
    shippingStatusId: any,
    productName: any,
  ) => {
    const selectedStatus = shippingStatusOptions.find(
      (option) => option.id === shippingStatusId,
    );
    setShipmentInfo({
      shipmentId: shippingId,
      productName: productName,
      statusId: shippingStatusId,
      statusName: selectedStatus?.name,
    });
    setSelectedShipmentStatus(selectedStatus);
    setUpdateType("single");
    setAlertVisible(true);
  };

  const handleBeanChange = async (
    quantity: any,
    rank: number,
    items: any,
    id: any,
    index: any,
    order_bundle_id: any,
  ) => {
    try {
      const updatedItems = items.filter((item: any, i: any) => i !== index);
      console.log(updatedItems);
      let is_pocket_coffee = false;
      let category_id = null;
      for (const item of items) {
        //if item.shipments.order_bundle.type != "product" , remove from updatedItems
        if (item?.product?.pocket_coffee_boxes != null) {
          is_pocket_coffee = true;
          category_id = 4;
        }
      }
      const same_order_item = updatedItems.filter(
        (item: any, i: any) => item.id === id,
      );
      setSameOrderItem(same_order_item);
      console.log(same_order_item);
      let url = "product/get_product_listings?page=1&subscription=1";
      if (rank != null) {
        url += "&rank=" + rank;
      }
      if (is_pocket_coffee) {
        url += "&category_id=" + category_id;
      }
      const [coffeeProducts] = await Promise.all([get(url)]);

      if (coffeeProducts?.success && coffeeProducts?.data) {
        setCoffeeBeans(coffeeProducts.data);
        setBeanChangeVisible(true);
        setSelectedQuantity(0);
        setPassedQuantity(quantity);
        setOrderBundleId(order_bundle_id);
      }
    } catch (error: any) {
      if (
        error?.response?.data?.message &&
        typeof error?.response?.data?.message === "string"
      ) {
        toast.error(error.response.data.message);
      }
    }
  };

  const handleAllShipmentStatus = async (shipmentIds: any) => {
    const allIds = shipmentIds.flatMap((innerArray: any) =>
      innerArray.map((item: any) => item?.shipments?.id),
    );
    setUpdateType("all");
    setSelectedShipmentStatus("");
    setAllIds(allIds);
    setAlertVisible(true);
  };

  const updateShipmentStatus = async (shipmentIds: any, status: any) => {
    try {
      const response = await post("/admin/update_shipment_status", {
        ids: shipmentIds,
        status: status?.id,
      });
      setAlertVisible(false);
      toast.success(response?.message);
      getData(); // update data;
    } catch (error: any) {
      if (
        error?.response?.data?.message &&
        typeof error?.response?.data?.message === "string"
      ) {
        toast.error(error.response.data.message);
      }
    }
  };

  const updateAllShipmentStatus = async (status: any) => {
    try {
      const response = await post("/admin/update_shipment_status", {
        ids: allIds,
        status: status?.id,
      });
      setAlertVisible(false);
      toast.success(response?.message);
      getData(); // update data;
    } catch (error: any) {
      if (
        error?.response?.data?.message &&
        typeof error?.response?.data?.message === "string"
      ) {
        toast.error(error.response.data.message);
      }
    }
  };

  const handleUpdateSubscriptionStatusClick = (
    id: number,
    selectedStatus: number,
  ) => {
    showConfirmDialog({
      icon: "pi pi-info-circle",
      message:
        orderDetail?.subscription?.status === 0
          ? "Do you want to pause this subscription?"
          : orderDetail?.subscription?.status === 2
          ? "Do you want to unpause this subscription?"
          : "",
      header: "Subscription Status",
      accept: async () => {
        const status = selectedStatus === 0 ? 2 : 0;
        try {
          const response = await post("admin/update_subscription_status", {
            id,
            status,
          });
          if (response?.success) {
            getData(); // update data;
            toast.success(response?.message);
          } else {
            toast.error("Something went wrong!");
          }
        } catch (error: any) {
          if (
            error?.response?.data?.message &&
            typeof error?.response?.data?.message === "string"
          ) {
            toast.error(error.response.data.message);
          }
        }
      },
    });
  };

  const handleChangeShipmentDateClick = () => {
    showConfirmDialog({
      icon: "pi pi-info-circle",
      message: `Do you want to change new shipment date to ${formatDate(
        changeShipmentDate,
      )}?`,
      header: "Change Shipment Date",
      accept: async () => {
        try {
          const response = await post("admin/change_date_subscription", {
            month_index: orderDetail?.current_month_index,
            order_id: orderDetail?.id,
            new_date: formatDate(changeShipmentDate),
          });
          if (response?.success) {
            getData(); // update data;
            setChangeShipmentDate(null);
            toast.success(response?.message);
          } else {
            toast.error("Something went wrong!");
          }
        } catch (error: any) {
          if (
            error?.response?.data?.message &&
            typeof error?.response?.data?.message === "string"
          ) {
            toast.error(error.response.data.message);
          }
        }
      },
    });
  };

  const updateBeanSubscription = async () => {
    try {
      const formData = new FormData();
      formData.append(`order_item_id`, editBeanId);
      formData.append(`month_index`, "" + selectedMonth);
      sameOrderItem.forEach((item: any) => {
        console.log(item);
        const obj: any = {
          id: item.shipments.product.id,
          quantity: item.shipments.shipped_out_qty,
          order_bundle_id: item.shipments.order_bundle_id,
        };
        selectedItems.push(obj);
      });
      selectedItems.map((item: any, index: number) => {
        if (item) {
          formData.append(`new_products[${index}][id]`, item?.id);
          formData.append(`new_products[${index}][quantity]`, item?.quantity);
          formData.append(
            `new_products[${index}][order_bundle_id]`,
            item?.order_bundle_id,
          );
        }
      });

      const response = await post("/order/update_subscription", formData);
      if (response?.success) {
        toast.success(response.message);
        getData();
        setBeanChangeVisible(false);
        setSameOrderItem(null);
        setSelectedItems([]);
        setSelectedQuantity(0);
        setNewTotalQuantity(0);
      }
    } catch (error: any) {
      if (
        error?.response?.data?.message &&
        typeof error?.response?.data?.message === "string"
      ) {
        toast.error(error.response.data.message);
      }
    }
  };
  const updatePrimaryAgent = async (orderID: any, agentID: any) => {
    try {
      const response = await post("/update_primary_agent", {
        id: orderID,
        agent_user_id: agentID,
        selected_reason_id: selectedReason,
      });
      setprimaryAgentVisible(false);
      toast.success(response?.message);
      getData(); // update data;
    } catch (error: any) {
      if (
        error?.response?.data?.message &&
        typeof error?.response?.data?.message === "string"
      ) {
        toast.error(error.response.data.message);
      }
    }
  };

  const updateSecondaryAgent = async (orderID: any, agentID: any) => {
    try {
      const response = await post("/update_secondary_agent", {
        id: orderID,
        shared_agent: agentID,
      });
      setSecondaryAgentVisible(false);
      toast.success(response?.message);
      getData(); // update data;
    } catch (error: any) {
      if (
        error?.response?.data?.message &&
        typeof error?.response?.data?.message === "string"
      ) {
        toast.error(error.response.data.message);
      }
    }
  };

  const handleQRdialogOpen = () => {
    setQRcodeVisible(true);
  };

  const handleQRdialogClose = () => {
    setQRcodeVisible(false);
    getData();
  };

  const updateCoffeeBean = (event: { id: number; quantity: number }) => {
    const { id, quantity } = event;
    const selectedItemIndex = selectedItems.findIndex(
      (item: any) => item.id === id,
    );
    if (selectedItemIndex !== -1) {
      if (quantity > 0) {
        const previousQuantity = selectedItems[selectedItemIndex].quantity;
        selectedItems[selectedItemIndex].quantity = quantity;
        setSelectedQuantity(
          (prevSelectedQuantity: any) =>
            prevSelectedQuantity - previousQuantity + quantity,
        );
      } else {
        const removedQuantity = selectedItems[selectedItemIndex].quantity;
        selectedItems.splice(selectedItemIndex, 1);
        setSelectedQuantity(
          (prevSelectedQuantity: any) => prevSelectedQuantity - removedQuantity,
        );
      }
    } else {
      if (quantity > 0) {
        selectedItems.push({ id, quantity: 1, order_bundle_id: orderBundleId });
        setSelectedQuantity(
          (prevSelectedQuantity: any) => prevSelectedQuantity + quantity,
        );
      }
    }
    const newTotalQuantity = selectedItems.reduce(
      (total, item) => total + item.quantity,
      0,
    );
    if (newTotalQuantity <= passedQuantity) {
      setSelectedItems([...selectedItems]);
      setNewTotalQuantity(newTotalQuantity);
    } else {
      toast.error(`You have exceeded the total quantity of ${passedQuantity}`);
    }
    setSelectedQuantity((prevSelectedQuantity: any) =>
      Math.max(prevSelectedQuantity, 0),
    );
  };

  const formik = useFormik({
    initialValues: {
      id: null,
      remarks: "",
    },
    onSubmit: async (values) => {
      const { id, remarks } = values;
      try {
        const response = await post("/order/update_order_details", {
          id: id === 0 ? null : id,
          remarks: remarks,
        });
        if (response?.success) {
          toast.success(response?.message);
        }
      } catch (error: any) {
        if (
          error?.response?.data?.message &&
          typeof error?.response?.data?.message === "string"
        ) {
          toast.error(error.response.data.message);
        }
      }
    },
  });

  const handleRefund = (id: number) => {
    showConfirmDialog({
      icon: "pi pi-info-circle",
      message: "Do you want to refund this order?",
      header: "Send Refund Confirmation",
      accept: async () => {
        try {
          const response = await get(`/order_refund/${id}`);
          if (response.success) {
            toast.success(response.message);
            getData();
          } else {
            toast.error("Something went wrong!");
          }
        } catch (error: any) {
          if (
            error?.response?.data?.message &&
            typeof error?.response?.data?.message === "string"
          ) {
            toast.error(error.response.data.message);
          }
        }
      },
    });
  };

  const handleSendEmailClick = (id: number) => {
    showConfirmDialog({
      icon: "pi pi-info-circle",
      message: "Do you want to send this invoice?",
      header: "Send Confirmation",
      accept: async () => {
        try {
          const response = await get(`/send_order_email/${id}`);
          if (response.success) {
            toast.success(response.message);
          } else {
            toast.error("Something went wrong!");
          }
        } catch (error: any) {
          if (
            error?.response?.data?.message &&
            typeof error?.response?.data?.message === "string"
          ) {
            toast.error(error.response.data.message);
          }
        }
      },
    });
  };

  // const handleDeleteOrderClick = (id: number) => {
  //   showConfirmDialog({
  //     message: "Do you want to delete this order?",
  //     header: "Delete Order Confirmation",
  //     accept: async () => {
  //       try {
  //         const response = await get(`/delete_order/${id}`);
  //         if (response.success) {
  //           navigate("/orders");
  //           toast.success(response.message);
  //         } else {
  //           toast.error("Something went wrong!");
  //         }
  //       } catch (error: any) {
  //         if (
  //           error?.response?.data?.message &&
  //           typeof error?.response?.data?.message === "string"
  //         ) {
  //           toast.error(error.response.data.message);
  //         }
  //       }
  //     },
  //   });
  // };

  const handleMarkAsPaidOrderClick = (order_id: number) => {
    showConfirmDialog({
      icon: "pi pi-info-circle",
      message:
        "Would you like to confirm this order as paid? Please be aware that this action cannot be undone.",
      header: "Update Order Confirmation",
      accept: async () => {
        try {
          const response = await post("admin/mark_order_as_paid", {
            order_id,
          });
          if (response.success) {
            toast.success(response.message);
            getData(); // update data;
          } else {
            toast.error("Something went wrong!");
          }
        } catch (error: any) {
          if (
            error?.response?.data?.message &&
            typeof error?.response?.data?.message === "string"
          ) {
            toast.error(error.response.data.message);
          }
        }
      },
    });
  };

  const addressFormik = useFormik({
    initialValues: {
      postal_code: orderDetail?.address?.postal_code || "",
      street: orderDetail?.address?.street || "",
      unit_no: orderDetail?.address?.unit_no || "",
    },
    validationSchema: Yup.object({
      postal_code: Yup.string()
        .min(6, "Invalid Postal Code")
        .matches(/^[0-9]+$/, "Invalid Postal Code")
        .required("Postal Code is required"),
      street: Yup.string().required("Street Name is required"),
      unit_no: Yup.string().required("Unit No. is required"),
    }),
    onSubmit: async (values) => {
      const { postal_code, street, unit_no } = values;
      try {
        const response = await post("/admin/update_shipping_address", {
          id: order_id,
          postal_code: postal_code,
          street: street,
          unit_no: unit_no,
        });
        if (response?.success) {
          toast.success(response?.message);
          setAddressVisible(false);
          getData();
        }
      } catch (error: any) {
        if (
          error?.response?.data?.message &&
          typeof error?.response?.data?.message === "string"
        ) {
          toast.error(error.response.data.message);
        }
      }
    },
  });

  const getAddress = async (postalcode: string) => {
    try {
      if (!postalcode || postalcode.length < 6) {
        return;
      }
      const response = await get(`get_address/${postalcode}`);
      if (response?.success && response?.data) {
        if (response?.data?.length === 1) {
          const fullAddress = response?.data[0]?.ADDRESS;
          const cleanedAddress = fullAddress
            .replace(/\sSINGAPORE\s\d{6}/, "")
            .replace(/\d{6}/g, "")
            .trim();
          addressFormik.setFieldValue("street", cleanedAddress);
          addressFormik.setFieldValue("unit_no", "");
          toast.success(response?.message);
        } else if (response?.data?.length > 1) {
          //postal code 569933 to get multiple address
          setAllAddress(response?.data);
          setShowAddressDialog(true);
        } else {
          toast.error("Address Not Found");
        }
      } else {
        console.error(response);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleConfirmAddress = () => {
    addressFormik.setFieldValue("street", selectedAddress);
    addressFormik.setFieldValue("unit_no", "");
    setShowAddressDialog(false);
  };

  return (
    <div className="bg-[#F8F8F9]">
      <Layout doNotCallPusher={doNotCallPusher}>
        <QRcode
          open={QRcodeVisible}
          close={handleQRdialogClose}
          data={`${window.location.origin}/checkout?id=${orderDetail?.payment_intent_id}`}
          orderId={order_id}
          entryPoint={"orderDetails"}
        />
        {props?.close && (
          <Button link onClick={() => props.close(false)}>
            <img
              src={require("../../assets/images/icon-back.png")}
              alt="Back icon"
              className="w-[34px] h-[34px]"
            />
          </Button>
        )}
        <Dialog
          header="Select Address"
          visible={showAddressDialog}
          onHide={() => setShowAddressDialog(false)}
          style={{ width: "90vw", border: 0 }}
          closable={false}
        >
          {allAddress.map((item: any, index: number) => {
            const cleanedAddress = item?.ADDRESS.replace(
              /\sSINGAPORE\s\d{6}/,
              "",
            )
              .replace(/\d{6}/g, "")
              .trim();
            return (
              <div
                key={index}
                className="flex items-center mb-[20px] pb-[20px] border-b border-[#B9B7B7]"
              >
                <RadioButton
                  value={item?.ADDRESS}
                  name="addressRadioGroup"
                  onClick={() => setSelectedAddress(cleanedAddress)}
                />
                <label className="ml-2 text-[#495057] text-[16px] leading-5">
                  {cleanedAddress}
                </label>
              </div>
            );
          })}
          <button
            className="rounded-[7px] w-full h-[45px] bg-[#DA5E18] text-white flex flex-row justify-center items-center flex-nowrap"
            onClick={() => handleConfirmAddress()}
          >
            Confirm
          </button>
        </Dialog>
        <Dialog
          header="Address"
          visible={AddressVisible}
          style={{ width: "70vw", border: 0 }}
          onHide={() => {
            setAddressVisible(false);
            addressFormik.resetForm();
          }}
        >
          <form onSubmit={addressFormik.handleSubmit}>
            <div className="pb-[9px]">
              <div className="flex flex-row justify-start items-center gap-x-2 pt-[9px]">
                <InputText
                  className={`w-full ${
                    addressFormik.touched.postal_code &&
                    addressFormik.errors.postal_code
                      ? "p-invalid"
                      : ""
                  }`}
                  name="postal_code"
                  placeholder="Postal Code"
                  value={addressFormik.values.postal_code}
                  onChange={addressFormik.handleChange}
                  onBlur={addressFormik.handleBlur}
                  maxLength={6}
                />
                <Button
                  type="button"
                  severity="secondary"
                  outlined
                  className="rounded-full !bg-[#343426] !text-white p-[7px] w-[33px] h-[33px] flex justify-center items-center shrink-0"
                  onClick={() => getAddress(addressFormik.values.postal_code)}
                  disabled={addressFormik.values.postal_code.length < 6}
                >
                  <img
                    src={require("../../assets/images/icon-search.png")}
                    alt="Search icon"
                    className="w-[18px]"
                  />
                </Button>
              </div>
              <ValidationError
                className={
                  addressFormik.touched.postal_code &&
                  addressFormik.errors.postal_code
                    ? "block"
                    : "hidden"
                }
              >
                {addressFormik.errors.postal_code}
              </ValidationError>
            </div>
            <div className="pb-[9px]">
              <div>
                <InputTextarea
                  className={`w-full resize-none ${
                    addressFormik.touched.street && addressFormik.errors.street
                      ? "p-invalid"
                      : ""
                  }`}
                  rows={5}
                  name="street"
                  placeholder="Street Name"
                  value={addressFormik.values.street}
                  onChange={addressFormik.handleChange}
                  onBlur={addressFormik.handleBlur}
                />
              </div>
              <ValidationError
                className={
                  addressFormik.touched.street && addressFormik.errors.street
                    ? "block"
                    : "hidden"
                }
              >
                {addressFormik.errors.street}
              </ValidationError>
            </div>
            <div className="pb-[29px]">
              <div>
                <InputText
                  className={`w-full ${
                    addressFormik.touched.unit_no &&
                    addressFormik.errors.unit_no
                      ? "p-invalid"
                      : ""
                  }`}
                  name="unit_no"
                  placeholder="Unit No."
                  value={addressFormik.values.unit_no}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    if (!addressFormik.values.unit_no.startsWith("#")) {
                      addressFormik.setFieldValue("unit_no", `#${newValue}`);
                    } else {
                      addressFormik.setFieldValue("unit_no", newValue);
                    }
                  }}
                  onBlur={addressFormik.handleBlur}
                />
              </div>
              <ValidationError
                className={
                  addressFormik.touched.unit_no && addressFormik.errors.unit_no
                    ? "block"
                    : "hidden"
                }
              >
                {addressFormik.errors.unit_no}
              </ValidationError>
            </div>
            <AcceptButton
              type="submit"
              className="mb-[25px] lg:mb-[0px] text-[12px] bg-[#e5622a]"
              label="Update Shipping Address"
            />
          </form>
        </Dialog>
        <div className="p-[17px] card-cfg">
          <div className="md:flex items-center justify-between py-[15px] text-[#DA5E18]">
            <label>
              ORDER #{order_id}
              <div
                className={`rounded-[30px] w-fit lg:w-full ml-1 ${
                  orderDetail?.status === 0
                    ? "bg-gray-300 text-black"
                    : orderDetail?.status === 1
                    ? "bg-green-300"
                    : orderDetail?.status === 2
                    ? "bg-yellow-300 text-black"
                    : orderDetail?.status === 3
                    ? "bg-red-300 text-[#FFF]"
                    : "bg-gray-300"
                } px-[8px] py-[3px] rounded-[30px] inline text-xs w-fit lg:w-full`}
              >
                {orderDetail?.status === 0
                  ? "Pending Payment"
                  : orderDetail?.status === 1
                  ? "Paid"
                  : orderDetail?.status === 2
                  ? "Draft Order"
                  : orderDetail?.status === 3
                  ? "Payment Failed"
                  : orderDetail?.status === 4
                  ? "Refunded"
                  : "Unknown Status"}
              </div>
            </label>
            <div className="flex items-center justify-center">
              {(orderDetail?.status === 0 || orderDetail?.status === 3) &&
                isRole(["Admin", "Manager"]) && (
                  <>
                    <Button
                      type="button"
                      className="mr-[10px] md:mt-0 mt-[10px] text-[12px] bg-[#e5622a]"
                      onClick={() =>
                        handleMarkAsPaidOrderClick(orderDetail?.id)
                      }
                    >
                      Mark as Paid
                    </Button>
                  </>
                )}
              {/* only agent with unpaid / failed orders */}
              {(orderDetail?.status === 0 || orderDetail?.status === 3) &&
                isRole(["Agent", "Agent Leader", "KOL"]) && (
                  <>
                    <Button
                      type="button"
                      className="mr-[10px] md:mt-0 mt-[10px] text-[12px] bg-[#e5622a]"
                      onClick={handleEditOrderClick}
                    >
                      Edit Order
                    </Button>
                    <Button
                      type="button"
                      className="mr-[10px] md:mt-0 mt-[10px] text-[12px] bg-[#e5622a]"
                      onClick={handleQRdialogOpen}
                    >
                      Checkout
                    </Button>
                    {/* if login user is primary agent */}
                    {/* {orderDetail?.agents[0]?.user_id === id && (
                      <Button
                        type="button"
                        className="mr-[10px] md:mt-0 mt-[10px] text-[12px] bg-[#ff2d55]"
                        onClick={() => handleDeleteOrderClick(orderDetail?.id)}
                      >
                        Delete Order
                      </Button>
                    )} */}
                  </>
                )}
              {isRole(["Agent Leader"]) && (
                <Button
                  type="button"
                  className="mr-[10px] md:mt-0 mt-[10px] text-[12px] bg-[#E83D65]"
                  onClick={() => handleReasons()}
                >
                  Edit Primary Agent
                </Button>
              )}
              {isRole(["Agent Leader"]) && (
                <Button
                  type="button"
                  className="mr-[10px] md:mt-0 mt-[10px] text-[12px] bg-[#E83D65]"
                  onClick={() => handleSecondaryAgent()}
                >
                  {orderDetail?.agents.length === 1 ? "Add" : "Edit"} Secondary
                  Agent
                </Button>
              )}
              {orderDetail?.status === 1 && !isRole(["Warehouse"]) && (
                <AcceptButton
                  type="button"
                  className="md:mt-0 mt-[10px] mr-2"
                  onClick={() => handleSendEmailClick(orderDetail?.id)}
                  label="Send Invoice"
                />
              )}
              {orderDetail?.status === 1 && isRole(["Admin"]) && (
                <AcceptButton
                  type="button"
                  className="md:mt-0 mt-[10px]"
                  onClick={() => handleRefund(orderDetail?.id)}
                  label="Refund"
                />
              )}
            </div>
          </div>
          <div className="flex my-[15px] flex-col lg:flex-row">
            <div className="w-full lg:w-2/3 pr-[0px] lg:pr-[20px]">
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-[15px]">
                <div>
                  <label className="ml-[5px] text-[12px]">
                    Order ID{" "}
                    <span
                      onClick={() => {
                        copy(orderDetail?.id);
                        toast.success("Order ID has been copied to clipboard");
                      }}
                    >
                      <span
                        className="pi pi-copy"
                        style={{ fontSize: ".7rem", paddingLeft: 3 }}
                      ></span>
                    </span>
                  </label>
                  <div className="bg-[#F8F8F8] border-[0.3px] rounded-[5px] text-[12px] p-[5px] min-h-[29px]">
                    {orderDetail?.id}
                  </div>
                </div>
                <div>
                  <label className="ml-[5px] text-[12px]">
                    Creation Date
                    <span
                      className="pi pi-calendar"
                      style={{ fontSize: ".7rem", paddingLeft: 3 }}
                    ></span>
                  </label>
                  <div className="bg-[#F8F8F8] border-[0.3px] rounded-[5px] text-[12px] p-[5px] min-h-[29px]">
                    {formatDate(orderDetail?.created_at)}
                  </div>
                </div>
                <div>
                  {/* unsure data */}
                  <label className="ml-[5px] text-[12px]">Status</label>
                  <div className="bg-[#F8F8F8] border-[0.3px] rounded-[5px] text-[12px] p-[5px] min-h-[29px]">
                    {orderDetail?.status === 1
                      ? "Completed"
                      : orderDetail?.status === 4
                      ? "Refunded"
                      : "Pending"}
                  </div>
                </div>
                {orderDetail?.venue_id && (
                  <div>
                    <label className="ml-[5px] text-[12px]">
                      Purchased Venue
                    </label>
                    <div className="bg-[#F8F8F8] border-[0.3px] rounded-[5px] text-[12px] p-[5px] min-h-[29px]">
                      {orderDetail?.venue?.name}
                    </div>
                  </div>
                )}
                <div>
                  <label className="ml-[5px] text-[12px]">Source</label>
                  <div className="bg-[#F8F8F8] border-[0.3px] rounded-[5px] text-[12px] p-[5px] min-h-[29px]">
                    {orderDetail?.source?.name}
                  </div>
                </div>
              </div>
              <div className="w-full border-b-[1px] border-[#BBB]"></div>
              <div className="pt-5">
                {orderDetail?.agents?.map((agentItem: any, index: any) => (
                  <div className="flex flex-col pb-5" key={index}>
                    <div className="flex items-center col-span-2">
                      <label className="text-[16px]">
                        {index === 0 ? "Primary" : "Secondary"} Agent
                      </label>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 my-1">
                      <div>
                        <label className="ml-[5px] text-[12px]">Code</label>
                        <div className="bg-[#F8F8F8] border-[0.3px] rounded-[5px] text-[12px] p-[5px] min-h-[29px]">
                          {agentItem?.agent?.agent_code}
                        </div>
                      </div>
                      <div>
                        <label className="ml-[5px] text-[12px]">Name</label>
                        <div className="bg-[#F8F8F8] border-[0.3px] rounded-[5px] text-[12px] p-[5px] min-h-[29px]">
                          {agentItem?.agent?.full_name}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="w-full border-b-[1px] border-[#BBB]"></div>
              <div className="flex flex-col py-5">
                <label className="text-[16px]">Customer</label>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 my-1">
                  <div>
                    <label className="ml-[5px] text-[12px]">Name</label>
                    <div className="bg-[#F8F8F8] border-[0.3px] rounded-[5px] text-[12px] p-[5px] min-h-[29px] capitalize">
                      {orderDetail?.customer?.full_name}
                    </div>
                  </div>
                  <div>
                    <label className="ml-[5px] text-[12px]">Mobile</label>
                    <div className="bg-[#F8F8F8] border-[0.3px] rounded-[5px] text-[12px] p-[5px] min-h-[29px]">
                      {orderDetail?.customer?.country_code}{" "}
                      {orderDetail?.customer?.mobile}
                    </div>
                  </div>
                  <div>
                    <label className="ml-[5px] text-[12px]">Email</label>
                    <div className="bg-[#F8F8F8] border-[0.3px] rounded-[5px] text-[12px] p-[5px] min-h-[29px]">
                      {orderDetail?.customer?.email}
                    </div>
                  </div>
                </div>
              </div>
              {orderDetail?.change_history &&
                orderDetail.change_history.length > 0 && (
                  <>
                    <div className="w-full border-b-[1px] border-[#BBB]"></div>
                    <div className="flex flex-col py-5">
                      <label className="text-[16px]">
                        Agent Change History
                      </label>
                      {orderDetail?.change_history.map(
                        (change: any, index: any) => (
                          <div
                            key={index}
                            className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 my-1"
                          >
                            <div>
                              <label className="ml-[5px] text-[12px]">
                                From
                              </label>
                              <div className="bg-[#F8F8F8] border-[0.3px] rounded-[5px] text-[12px] p-[5px] min-h-[29px] capitalize">
                                {change?.old?.full_name}
                              </div>
                            </div>
                            <div>
                              <label className="ml-[5px] text-[12px]">To</label>
                              <div className="bg-[#F8F8F8] border-[0.3px] rounded-[5px] text-[12px] p-[5px] min-h-[29px]">
                                {change?.new?.full_name}
                              </div>
                            </div>
                            <div>
                              <label className="ml-[5px] text-[12px]">
                                Changed By
                              </label>
                              <div className="bg-[#F8F8F8] border-[0.3px] rounded-[5px] text-[12px] p-[5px] min-h-[29px]">
                                {change?.changed_by?.full_name}
                              </div>
                            </div>
                          </div>
                        ),
                      )}
                    </div>
                  </>
                )}
              <div className="w-full border-b-[1px] border-[#BBB]"></div>
              <div className="flex flex-col py-5">
                <label className="text-[16px]">Billing</label>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 my-1">
                  <div className="col-span-2 sm:col-span-1">
                    <label className="ml-[5px] text-[12px]">Name</label>
                    <div className="bg-[#F8F8F8] border-[0.3px] rounded-[5px] text-[12px] p-[5px] min-h-[29px]">
                      {orderDetail?.billing_address?.full_name}
                    </div>
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <label className="ml-[5px] text-[12px]">Mobile</label>
                    <div className="bg-[#F8F8F8] border-[0.3px] rounded-[5px] text-[12px] p-[5px] min-h-[29px]">
                      {orderDetail?.billing_address?.country_code}{" "}
                      {orderDetail?.billing_address?.mobile}
                    </div>
                  </div>
                  <div className="col-span-2">
                    <label className="ml-[5px] text-[12px]">Address</label>
                    <div className="bg-[#F8F8F8] border-[0.3px] rounded-[5px] text-[12px] p-[5px] min-h-[48px]">
                      {orderDetail?.billing_address?.street} <br></br>
                      {orderDetail?.billing_address?.unit_no} <br></br>
                      SINGAPORE {orderDetail?.billing_address?.postal_code}
                    </div>
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <label className="ml-[5px] text-[12px]">Payment Date</label>
                    <div className="bg-[#F8F8F8] border-[0.3px] rounded-[5px] text-[12px] p-[5px] min-h-[29px]">
                      {/* unsure data */}
                      {formatDate(orderDetail?.created_at)}
                    </div>
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <label className="ml-[5px] text-[12px]">
                      Stripe Payment Intent ID
                    </label>
                    <div className="bg-[#F8F8F8] border-[0.3px] rounded-[5px] text-[12px] p-[5px] min-h-[29px]">
                      {orderDetail?.payment_intent_id}
                    </div>
                  </div>
                  {orderDetail?.mark_paid_by && (
                    <>
                      <div className="col-span-2">
                        <label className="ml-[5px] text-[12px]">
                          Order Marked as Paid By
                        </label>
                        <div className="bg-[#F8F8F8] border-[0.3px] rounded-[5px] text-[12px] p-[5px] min-h-[29px]">
                          {orderDetail?.mark_paid_by?.full_name}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              {orderDetail?.needsDelivery && (
                <>
                  <div className="w-full border-b-[1px] border-[#BBB]"></div>
                  <div className="flex flex-col py-5">
                    <label className="text-[16px]">Shipping</label>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 my-1">
                      <div className="col-span-2 sm:col-span-1">
                        <label className="ml-[5px] text-[12px]">
                          Estimated Shipping Date
                        </label>
                        <div className="bg-[#F8F8F8] border-[0.3px] rounded-[5px] text-[12px] p-[5px] min-h-[29px]">
                          {/* unsure data */}
                          {formatDate(orderDetail?.delivery_start_date)}
                        </div>
                      </div>
                      <div className="col-span-2 sm:col-span-1">
                        <label className="ml-[5px] text-[12px]">
                          Delivery Name
                        </label>
                        <div className="bg-[#F8F8F8] border-[0.3px] rounded-[5px] text-[12px] p-[5px] min-h-[29px] capitalize">
                          {orderDetail?.address?.first_name}{" "}
                          {orderDetail?.address?.last_name}
                        </div>
                      </div>
                      <div className="col-span-2 sm:col-span-1">
                        <label className="ml-[5px] text-[12px]">
                          Delivery Contact No.
                        </label>
                        <div className="bg-[#F8F8F8] border-[0.3px] rounded-[5px] text-[12px] p-[5px] min-h-[29px]">
                          {orderDetail?.address?.country_code}{" "}
                          {orderDetail?.address?.mobile}
                        </div>
                      </div>
                      {orderDetail?.status === 1 &&
                        statusOptions.length > 0 && (
                          <div className="col-span-2 sm:col-span-1">
                            <label className="ml-[5px] text-[12px]">
                              Packing List Filter (Month)
                            </label>
                            <Dropdown
                              value={selectedStatus}
                              options={statusOptions}
                              onChange={handleStatusChange}
                              optionLabel="name"
                              placeholder="Select Status"
                              className="max-w-[100%] flex text-[20px] customDropDown"
                            />
                          </div>
                        )}
                      <div className="col-span-2">
                        <label className="ml-[5px] text-[12px]">
                          Delivery Address
                        </label>
                        <div className="flex items-center gap-[5px]">
                          <div className="bg-[#F8F8F8] border-[0.3px] rounded-[5px] text-[12px] p-[5px] min-h-[48px] w-full">
                            {orderDetail?.address?.street} <br></br>
                            {orderDetail?.address?.unit_no} <br></br>
                            SINGAPORE {orderDetail?.address?.postal_code}
                          </div>
                          {isRole(["Admin", "Warehouse"]) && (
                            <div className="shrink-0">
                              <img
                                src={require("../../assets/images/icon-edit-image.png")}
                                alt="Edit Icon"
                                className={`w-[26px] h-[26px] cursor-pointer`}
                                onClick={() => {
                                  setAddressVisible(true);
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-span-2 sm:col-span-1">
                        <label className="ml-[5px] text-[12px]">
                          Subscription Status
                        </label>
                        <div className="flex items-center gap-[5px]">
                          <div className="bg-[#F8F8F8] border-[0.3px] rounded-[5px] text-[12px] p-[5px] min-h-[29px] w-full">
                            {orderDetail?.subscription?.status === 2
                              ? "Paused"
                              : orderDetail?.subscription?.status === 0
                              ? "Active"
                              : ""}
                          </div>
                          <AcceptButton
                            label={
                              orderDetail?.subscription?.status === 0
                                ? "Pause"
                                : "Unpause"
                            }
                            className=""
                            onClick={() => {
                              handleUpdateSubscriptionStatusClick(
                                orderDetail?.subscription?.order_id,
                                orderDetail?.subscription?.status,
                              );
                            }}
                          />
                        </div>
                      </div>
                      {orderDetail?.shipments &&
                        orderDetail?.shipments?.length > 0 && (
                          <div className="col-span-2 sm:col-span-1">
                            <label className="ml-[5px] text-[12px]">
                              Change Shipment Date
                            </label>
                            <div className="flex items-center gap-[5px]">
                              <Calendar
                                className="w-full h-[29px]"
                                name="change_shipment_date"
                                dateFormat="dd M yy"
                                value={changeShipmentDate}
                                minDate={new Date()}
                                onChange={(e) => setChangeShipmentDate(e.value)}
                              />
                              <AcceptButton
                                label={"Apply"}
                                className=""
                                onClick={() => {
                                  if (changeShipmentDate) {
                                    handleChangeShipmentDateClick();
                                  } else {
                                    toast.error("Select a new shipment date.");
                                  }
                                }}
                              />
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </>
              )}
              {orderDetail?.status === 1 &&
                orderDetail?.order_items_filtered?.length > 0 && (
                  <>
                    <div className="w-full border-b-[1px] border-[#BBB]"></div>
                    <div className="py-5">
                      <label className="ml-[5px] mb-2 text-[16px] block">
                        Packing List
                        <div
                          className="md:hidden flex items-center text-[#DA5E18]"
                          onClick={() =>
                            handleAllShipmentStatus(
                              orderDetail?.order_items_filtered,
                            )
                          }
                        >
                          Update All{" "}
                          <img
                            src={require("../../assets/images/order/icon-edit-circle.png")}
                            alt="Edit Icon"
                            className={`w-[28px] h-[28px] ml-[10px]`}
                          />
                        </div>
                      </label>
                      <div className="w-full overflow-x-auto">
                        <table className="table-cfg">
                          <thead>
                            <tr>
                              <th className="!text-xs">SKU</th>
                              <th className="!text-xs">Product</th>
                              <th className="!text-xs">Qty</th>
                              <th className="!text-xs">Status</th>
                              {isRole(["Admin", "Warehouse"]) && (
                                <th
                                  className="!text-xs cursor-pointer"
                                  onClick={() =>
                                    handleAllShipmentStatus(
                                      orderDetail?.order_items_filtered,
                                    )
                                  }
                                >
                                  Update All
                                  <img
                                    src={require("../../assets/images/order/icon-edit-circle.png")}
                                    alt="Edit Icon"
                                    className={`w-[28px] h-[28px] m-auto`}
                                  />
                                </th>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {orderDetail?.order_items_filtered.map(
                              (group: any, index: any) => (
                                <React.Fragment key={index}>
                                  {group?.map((items: any, index: any) => (
                                    <React.Fragment key={index}>
                                      {items?.shipments?.status !== 5 && (
                                        <tr key={index}>
                                          <td data-label="SKU">
                                            {items?.shipments?.product?.sku}
                                          </td>
                                          <td data-label="Product name">
                                            <div className="flex justify-between items-center">
                                              <div className="flex items-center gap-2 justify-end lg:justify-start">
                                                <img
                                                  src={
                                                    items?.shipments?.product
                                                      ?.image_url ||
                                                    require("../../assets/images/order/no-image.png")
                                                  }
                                                  alt=""
                                                  className="w-[40px] h-[40px] rounded object-cover shrink-0"
                                                />
                                                <div>
                                                  <div>
                                                    {
                                                      items?.shipments?.product
                                                        ?.name
                                                    }
                                                  </div>
                                                  <div className="text-xs">
                                                    {
                                                      items?.shipments?.product
                                                        ?.category?.name
                                                    }{" "}
                                                    -{" "}
                                                    {
                                                      items?.shipments?.product
                                                        ?.sub_category?.name
                                                    }
                                                  </div>
                                                </div>
                                              </div>
                                              {isRole([
                                                "Admin",
                                                "Manager",
                                                "Agent Leader",
                                                "Warehouse",
                                              ]) &&
                                                items?.subscription_total > 0 &&
                                                items?.shipments?.status ===
                                                  1 &&
                                                (items?.shipments?.order_bundle
                                                  ?.type === "product" ||
                                                  items?.shipments?.order_bundle
                                                    ?.type === "add_on" ||
                                                  items?.shipments
                                                    ?.order_bundle ===
                                                    undefined ||
                                                  items?.shipments
                                                    ?.order_bundle ===
                                                    null) && (
                                                  <div
                                                    className={`cursor-pointer inline-block items-center shrink-0`}
                                                  >
                                                    <img
                                                      src={require("../../assets/images/order/icon-edit-circle.png")}
                                                      alt="Edit Icon"
                                                      className={`w-[28px] h-[28px] m-auto border rounded-[14px]`}
                                                      onClick={() => {
                                                        handleBeanChange(
                                                          items?.shipments
                                                            ?.shipped_out_qty,
                                                          items?.default_product
                                                            ?.sub_category
                                                            ?.rank,
                                                          group,
                                                          items?.id,
                                                          index,
                                                          items?.shipments
                                                            ?.order_bundle_id ??
                                                            0,
                                                        );
                                                        setEditBeanId(
                                                          items?.id,
                                                        );
                                                      }}
                                                    />
                                                  </div>
                                                )}
                                            </div>
                                          </td>
                                          <td
                                            data-label="Qty"
                                            className="text-center"
                                          >
                                            {items?.shipments?.shipped_out_qty}
                                          </td>
                                          <td
                                            data-label="Status"
                                            className="text-center"
                                          >
                                            <div
                                              className={`whitespace-nowrap ${
                                                items?.shipments?.status === 1
                                                  ? "bg-yellow-300"
                                                  : items?.shipments?.status ===
                                                    2
                                                  ? "bg-orange-300"
                                                  : items?.shipments?.status ===
                                                    3
                                                  ? "bg-blue-300"
                                                  : items?.shipments?.status ===
                                                    4
                                                  ? "bg-green-300"
                                                  : items?.shipments?.status ===
                                                    5
                                                  ? "bg-green-300"
                                                  : "bg-red-300 "
                                              } px-[8px] py-[3px] rounded-[30px] inline text-xs w-fit lg:w-full`}
                                            >
                                              {items?.shipments?.status === 1
                                                ? "To Prepare"
                                                : items?.shipments?.status === 2
                                                ? "Pending Pick Up"
                                                : items?.shipments?.status === 3
                                                ? "Shipped"
                                                : items?.shipments?.status === 4
                                                ? "Delivered"
                                                : items?.shipments?.status === 5
                                                ? "Self Collected"
                                                : "Unknown Status"}
                                            </div>
                                          </td>
                                          {isRole(["Admin", "Warehouse"]) && (
                                            <td className="text-right md:text-center">
                                              <div className={`cursor-pointer`}>
                                                <img
                                                  src={require("../../assets/images/order/icon-edit-circle.png")}
                                                  alt="Edit Icon"
                                                  className={`w-[28px] h-[28px] m-auto border rounded-[14px]`}
                                                  onClick={() =>
                                                    handleShipmentStatus(
                                                      items?.shipments?.id,
                                                      items?.shipments?.status,
                                                      items?.name,
                                                    )
                                                  }
                                                />
                                              </div>
                                            </td>
                                          )}
                                        </tr>
                                      )}
                                    </React.Fragment>
                                  ))}
                                </React.Fragment>
                              ),
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                )}
              <div className="w-full border-b-[1px] border-[#BBB]"></div>
              <form onSubmit={formik.handleSubmit}>
                <div className="flex flex-col py-5">
                  <label className="ml-[5px] text-[16px]">Remarks</label>
                  <div className="text-[12px] p-[5px]">
                    <InputTextarea
                      className={`w-full resize-none ${
                        formik.touched.remarks && formik.errors.remarks
                          ? "p-invalid"
                          : ""
                      }`}
                      rows={5}
                      name="remarks"
                      placeholder="Remarks"
                      value={formik.values.remarks}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      style={{ marginBottom: "-5px" }}
                    />
                  </div>
                </div>
                <AcceptButton
                  type="submit"
                  className="mb-[25px] lg:mb-[0px] text-[12px] bg-[#e5622a]"
                  label="Update Remarks"
                />
              </form>
            </div>

            <div className="w-full lg:w-1/3">
              <div className="bg-[#F7F7F7] rounded-[11px] text-[14px] p-[15px] text-[#3E3E3E] font-normal flex flex-col h-full">
                <div>
                  <div className="bg-[#F7F7F7] rounded-[11px] text-[14px] text-[#3E3E3E] font-normal">
                    <div>
                      {orderDetail?.order_items_grouped.map(
                        (group: any, index: any) => (
                          <div key={index}>
                            {group[0] ? (
                              <div>
                                <div className="flex items-center justify-between">
                                  <label className="text-[#3E3E3E] font-medium text-base my-[10px]">
                                    {group[0]?.purchase_option?.name}{" "}
                                    {["Subscription", "Plan"]?.includes(
                                      group[0]?.purchase_option?.name,
                                    ) && (
                                      <span>
                                        ( {group[0]?.subscription_completed} /{" "}
                                        {group[0]?.subscription_total} )
                                      </span>
                                    )}
                                  </label>
                                </div>
                              </div>
                            ) : null}
                            <div className="py-[9px]">
                              {group.map((item: any, indexItem: any) => (
                                <React.Fragment key={indexItem}>
                                  {item?.free_gift === 0 && (
                                    <>
                                      {item?.order_bundles_filtered?.length <=
                                        0 && (
                                        <div className="flex mb-3">
                                          <img
                                            src={
                                              item?.product?.image_url ||
                                              require("../../assets/images/order/no-image.png")
                                            }
                                            alt=""
                                            className="w-[57px] h-[57px] rounded-[16px]"
                                          />
                                          <div className="ml-[26px]">
                                            <p className="font-normal">
                                              {item?.name}
                                            </p>
                                            <p className="text-sm font-bold">
                                              X {item?.quantity}
                                            </p>
                                            <p className="text-sm font-bold">
                                              {item?.bundle_id
                                                ? formatCurrency(item?.price)
                                                : item?.subscription_option
                                                ? formatCurrency(
                                                    item?.price *
                                                      item?.quantity *
                                                      item?.subscription_option
                                                        ?.subscription_month,
                                                  )
                                                : formatCurrency(
                                                    item?.price *
                                                      item?.quantity,
                                                  )}
                                            </p>
                                          </div>
                                        </div>
                                      )}
                                      {item?.order_bundles_filtered?.length >
                                        0 &&
                                        item?.order_bundles_filtered.map(
                                          (order_bundle: any, index: any) =>
                                            order_bundle?.items?.map(
                                              (item: any, index: number) => (
                                                <>
                                                  <div className="mb-2">
                                                    {item?.type === "product"
                                                      ? "Base Plan"
                                                      : order_bundle?.type ===
                                                        "add_on"
                                                      ? "Addon"
                                                      : order_bundle?.type ===
                                                        "base_plan_foc"
                                                      ? "FOC"
                                                      : order_bundle?.type ===
                                                        "add_on_plan_foc"
                                                      ? "Addon - FOC"
                                                      : "Upgraded - FOC"}
                                                  </div>
                                                  <div
                                                    className="flex mb-3"
                                                    key={`order-bundle-${index}`}
                                                  >
                                                    <img
                                                      src={
                                                        item?.product
                                                          ?.image_url ||
                                                        require("../../assets/images/order/no-image.png")
                                                      }
                                                      alt=""
                                                      className="w-[57px] h-[57px] rounded-[16px]"
                                                    />
                                                    <div className="ml-[26px]">
                                                      <p className="font-normal">
                                                        {item?.product?.name}
                                                      </p>
                                                      <p className="text-sm font-bold">
                                                        X {item?.quantity}
                                                      </p>
                                                    </div>
                                                  </div>
                                                </>
                                              ),
                                            ),
                                        )}
                                      {(item?.shipments[0]?.status === 5 ||
                                        (item?.shipments.length === 0 &&
                                          item?.delivery_option_id === 1)) && (
                                        <div className="ml-2 shrink-0">
                                          <Tooltip target=".self-collect-icon" />
                                          <img
                                            className="self-collect-icon w-[16px] h-[16px]"
                                            src={require("../../assets/images/icon-self-collect.png")}
                                            alt="self collect icon"
                                            data-pr-tooltip="Self Collected"
                                            data-pr-position="top"
                                          />
                                        </div>
                                      )}
                                    </>
                                  )}
                                  {item?.free_gift === 1 && (
                                    <>
                                      <div className="mb-2">Free Gift</div>
                                      <div
                                        key={indexItem}
                                        className="flex mb-3"
                                      >
                                        <img
                                          src={
                                            item?.image_url ||
                                            require("../../assets/images/order/no-image.png")
                                          }
                                          alt=""
                                          className="w-[57px] h-[57px] rounded-[16px]"
                                        />
                                        <div className="ml-[26px]">
                                          <p className="font-normal">
                                            {item?.name}
                                          </p>
                                          <p className="text-sm font-bold">
                                            X {item?.quantity}
                                          </p>
                                        </div>
                                        <div className="ml-2 shrink-0">
                                          <Tooltip target=".self-collect-icon" />
                                          <img
                                            className="self-collect-icon w-[16px] h-[16px]"
                                            src={require("../../assets/images/icon-self-collect.png")}
                                            alt="self collect icon"
                                            data-pr-tooltip="Self Collected"
                                            data-pr-position="top"
                                          />
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </React.Fragment>
                              ))}
                            </div>
                            <div className="w-full border-b-[0.5px] border-[#BBB]"></div>
                          </div>
                        ),
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="flex justify-between pt-2">
                    <div className="text-[#434343]">Delivery Fee</div>
                    <div className="text-[#292929]">
                      {formatCurrency(orderDetail?.delivery_fee)}
                    </div>
                  </div>
                  {orderDetail?.gst !== 0 && (
                    <div className="flex justify-between mt-1 pb-1">
                      <div className="text-[#434343]">GST (8%)</div>
                      <div className="text-[#292929]">
                        {formatCurrency(orderDetail?.gst_amount)}
                      </div>
                    </div>
                  )}
                  <div className="w-full border-b-[0.5px] mt-[5px] border-[#BBB]"></div>
                  <div className="flex justify-between mt-1 pt-2">
                    <div className="text-[#434343] font-bold">Total</div>
                    <div className="text-[#292929] font-bold text-[16px]">
                      {formatCurrency(orderDetail?.total)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Dialog
          header="Select Status for Update"
          visible={alertVisible}
          style={{ width: "auto", border: 0 }}
          onHide={() => setAlertVisible(false)}
          modal
        >
          <div className="modal-content">
            {updateType === "single" ? (
              <div className="font-medium">
                Product: {shipmentInfo.productName}
              </div>
            ) : updateType === "all" ? (
              <div className="font-medium">For Month {selectedMonth}</div>
            ) : null}
            <Dropdown
              value={selectedShippmentStatus}
              options={shippingStatusOptions}
              onChange={(e) => setSelectedShipmentStatus(e.value)}
              optionLabel="name"
              placeholder="Select Status"
              className="w-full"
            />
            <AcceptButton
              label="Confirm"
              onClick={
                updateType === "single"
                  ? () =>
                      updateShipmentStatus(
                        [shipmentInfo?.shipmentId],
                        selectedShippmentStatus,
                      )
                  : updateType === "all"
                  ? () => updateAllShipmentStatus(selectedShippmentStatus)
                  : undefined
              }
              className="mt-[15px]"
            />
          </div>
        </Dialog>
        {isRole(["Agent Leader"]) && (
          <Dialog
            header="Select Change Agent Reason ( 1 = Commission Change / 0 = No Change )"
            visible={reasonVisible}
            style={{ width: "auto" }}
            onHide={() => setReasonsVisible(false)}
            modal
          >
            <Dropdown
              className={`w-full`}
              options={reasons.map((reason) => ({
                label: `${reason?.description} - [${reason?.commision_change}]`,
                value: reason.id, // Pass the agent object as the value
              }))}
              name="reasons"
              value={selectedReason}
              placeholder="Please Select Change Reason"
              onChange={(e) => setSelectedReason(e.value)}
            />
            <AcceptButton
              label="Confirm"
              onClick={() => handlePrimaryAgent()}
              className="p-button-primary mt-[15px]"
            />
          </Dialog>
        )}
        {isRole(["Agent Leader"]) && (
          <Dialog
            header="Select Primary Agent"
            visible={primaryAgentVisible}
            style={{ width: "auto" }}
            onHide={() => setprimaryAgentVisible(false)}
            modal
          >
            <Dropdown
              className={`w-full`}
              options={agents.map((agent) => ({
                label: `${agent?.full_name} - [#${agent?.agent_code}]`,
                value: agent.id, // Pass the agent object as the value
              }))}
              filter
              name="primary_agent"
              value={selectedAgent}
              placeholder="Please Select Collaborating Agents"
              onChange={(e) => setSelectedAgent(e.value)}
            />
            <AcceptButton
              label="Confirm"
              onClick={() => updatePrimaryAgent(orderDetail?.id, selectedAgent)}
              className="p-button-primary mt-[15px]"
            />
          </Dialog>
        )}
        {isRole(["Agent Leader"]) && (
          <Dialog
            header="Select Secondary Agent"
            visible={secondaryAgentVisible}
            style={{ width: "auto" }}
            onHide={() => setSecondaryAgentVisible(false)}
            modal
          >
            <Dropdown
              className={`w-full`}
              options={agents.map((agent) => ({
                label: `${agent?.full_name} - [#${agent?.agent_code}]`,
                value: agent.id, // Pass the agent object as the value
              }))}
              filter
              name="shared_agent"
              value={selectedAgent}
              placeholder="Please Select Collaborating Agents"
              onChange={(e) => setSelectedAgent(e.value)}
            />
            <AcceptButton
              label="Confirm"
              onClick={() =>
                updateSecondaryAgent(orderDetail?.id, selectedAgent)
              }
              className="p-button-primary mt-[15px]"
            />
          </Dialog>
        )}
        <Dialog
          header="Change of Beans"
          footer={
            <AcceptButton
              label="Update Coffee Bean"
              onClick={() => updateBeanSubscription()}
              className="mt-[15px] !m-auto"
              disabled={selectedQuantity !== passedQuantity}
            />
          }
          visible={beanChangeVisible}
          style={{
            width: "70%",
            height: "70%",
            border: 0,
            overflow: "hidden",
          }}
          onHide={() => {
            setBeanChangeVisible(false);
            setSelectedItems([]); // Reset selectedItems to an empty array
            setSelectedQuantity(0); // Reset selectedQuantity to 0
            setNewTotalQuantity(0);
          }}
          modal
        >
          <div className="modal-content">
            {coffeeBeans?.length > 0 &&
              coffeeBeans.map((row: InventoryGroup, index: number) => {
                return (
                  <div key={`new-order-subcategory-${index}`}>
                    <div className="py-3 bg-[#343426] rounded-[7px] pl-3 mt-[20px] mb-[10px]">
                      <label className="text-[#DA5E18] text-base capitalize">
                        {row.sub_category.name}
                      </label>
                    </div>
                    <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-2">
                      {row.products?.length > 0 &&
                        row.products.map((row: Inventory) => (
                          <div
                            className="col-span-1"
                            key={`new-order-table-grid-row-${row.id}`}
                          >
                            <GridView
                              item={row}
                              onQuantityChange={(event: any) =>
                                updateCoffeeBean(event)
                              }
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                );
              })}
          </div>
        </Dialog>
      </Layout>
    </div>
  );
};

export default OrderDetails;
