import { useFormik, FormikErrors } from "formik";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Layout from "../components/Layout";
import useApiData from "../hooks/useApiData";
import { post } from "../utils/Api";

import { Chips } from "primereact/chips";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";
import { TabPanel, TabView } from "primereact/tabview";
import { useLocation, useNavigate } from "react-router-dom";
import AcceptButton from "../components/AcceptButton";
import ValidationError from "../components/ValidationError";
import { get } from "../utils/Api";
import { isRole } from "../utils/Common";
import { Fieldset } from "primereact/fieldset";
import { Panel } from "primereact/panel";
import { Checkbox } from "primereact/checkbox";
import BeanInputOptional from "../components/BeanInputOptional";

type FocItemDetailPocketCoffee = {
  sub_category_id: number;
  eg: string;
  product_id: number;
  quantity: number;
  freegift_no: number;
  api_key_id: number;
};

type FocItemDetailFieldPocketCoffee = keyof FocItemDetailPocketCoffee;

type AdvanceFocItemDetailPocketCoffee = {
  sub_category_id: number;
  eg: string;
  product_id: number;
  quantity: number;
  freegift_no: number;
  api_key_id: number;
};

type AdvanceFocItemDetailFieldPocketCoffee =
  keyof AdvanceFocItemDetailPocketCoffee;

type AddOnFocItemDetailPocketCoffee = {
  sub_category_id: number;
  eg: string;
  product_id: number;
  quantity: number;
  freegift_no: number;
  optional: number;
  api_key_id: number;
};

type AddOnFocItemDetailFieldPocketCoffee = keyof AddOnFocItemDetailPocketCoffee;

type OptionGroupPocketCoffee = {
  add_on_name: string;
  cost_price: number;
  price: number;
  total_beans: number;
  item_details: {
    sub_category_id: number;
    eg: string;
    quantity: number;
    optional: number;
  }[];
  foc: {
    type: string;
    item_details: {
      sub_category_id: number;
      eg: string;
      product_id: number;
      quantity: number;
      freegift_no: number;
      optional: number;
      api_key_id: number;
    }[];
  };
};
const BundlePocketCoffeeComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const type = location.state.type;

  const [selectedFile, setSelectedFile] = useState("");
  const [selectedDetailFile, setSelectedDetailFile] = useState("");
  const [productLoading, setProductLoading] = useState(false);

  const sources = useApiData("/admin/get_sources");
  const sourcesData = sources.data as any;
  const sourcesList = sourcesData?.map((item: any) => {
    return { label: item?.name, value: item?.id };
  });
  const category = useApiData("/admin/get_categories");
  const categoryData = category.data as any;
  const categoryList = categoryData?.data.map((item: any) => {
    return { label: item?.name, value: item?.id };
  });

  const FILE_SIZE = 1024 * 1024 * 30; // 30MB
  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

  const onFileUpload = async (event: any, type: string) => {
    const file = event?.target?.files[0];
    if (file) {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onloadend = function () {
        const base64data = reader.result;
        if (type === "product") {
          setSelectedFile(base64data as string);
        } else if (type === "detail") {
          setSelectedDetailFile(base64data as string);
        }
      };
    }
  };

  const [selectedCategoryPocketCoffee, setSelectedCategoryPocketCoffee] =
    useState<any>(null);
  const [
    selectedCategoryAdvancePocketCoffee,
    setSelectedCategoryAdvancePocketCoffee,
  ] = useState<any>(null);

  const [
    freeGiftProductsListAdvancePocketCoffee,
    setFreeGiftProductsListAdvancePocketCoffee,
  ] = useState<any[]>([]);

  const [isOptionGroupVisiblePocketCoffee, setOptionGroupVisiblePocketCoffee] =
    useState(false);

  const [
    freeGiftProductsListsPocketCoffee,
    setFreeGiftProductsListsPocketCoffee,
  ] = useState<any[]>([] as any);

  const [
    freeGiftAddOnProductsListPocketCoffee,
    setFreeGiftAddOnProductsListPocketCoffee,
  ] = useState<any[]>([] as any);

  const subscriptionOptionsPocketCoffee = useApiData(
    `/product/get_subscription_options`,
  );
  const subscriptionOptionDataPocketCoffee =
    subscriptionOptionsPocketCoffee.data as any;
  const subscriptionOptionListPocketCoffee =
    subscriptionOptionDataPocketCoffee?.map((item: any) => {
      return { label: item?.name, value: item?.id };
    });

  // const onBeansOptionalChangePocketCoffee = (
  //   index: number,
  //   checked: boolean,
  // ) => {
  //   const updatedItemDetailsPocketCoffee = [
  //     ...formik_subscriptionBundlePocketCoffee.values.base_plan.item_details,
  //   ];

  //   updatedItemDetailsPocketCoffee[index].optional = checked
  //     ? 1 - updatedItemDetailsPocketCoffee[index].optional
  //     : 0;

  //   formik_subscriptionBundlePocketCoffee.setFieldValue(
  //     `base_plan.item_details[${index}].optional`,
  //     updatedItemDetailsPocketCoffee[index].optional,
  //   );

  //   formik_subscriptionBundlePocketCoffee.setFieldValue(
  //     "base_plan.item_details",
  //     updatedItemDetailsPocketCoffee,
  //   );
  // };

  const addFocItemGroupPocketCoffee = () => {
    const newItemPocketCoffee = {
      sub_category_id: 0,
      eg: "",
      product_id: null,
      quantity: 0,
      freegift_no: 0,
      optional: 0,
    };
    setFocItemDetailsPocketCoffee([
      ...focItemDetailsPocketCoffee,
      newItemPocketCoffee,
    ]);

    // Check if `foc` object exists in Formik state
    if (formik_subscriptionBundlePocketCoffee.values.base_plan.foc) {
      // If `foc` object exists, update `item_details`
      formik_subscriptionBundlePocketCoffee.setFieldValue(
        `base_plan.foc.item_details`,
        [
          ...formik_subscriptionBundlePocketCoffee.values.base_plan.foc
            .item_details,
          newItemPocketCoffee,
        ],
      );
    } else {
      // If `foc` object doesn't exist, create it with the new `item_details`
      formik_subscriptionBundlePocketCoffee.setFieldValue(`base_plan.foc`, {
        item_details: [newItemPocketCoffee],
      });
    }
  };

  const removeFocItemGroupPocketCoffee = (index: number) => {
    const updatedGroupsPocketCoffee = [...focItemDetailsPocketCoffee];
    updatedGroupsPocketCoffee.splice(index, 1);
    setFocItemDetailsPocketCoffee(updatedGroupsPocketCoffee);

    // Update Formik values
    const updatedFormikValuesPocketCoffee = [
      ...formik_subscriptionBundlePocketCoffee.values.base_plan.foc
        .item_details,
    ];
    updatedFormikValuesPocketCoffee.splice(index, 1);

    // If the length of updatedFormikValuesPocketCoffee is 0, remove the whole foc object
    if (updatedFormikValuesPocketCoffee.length === 0) {
      formik_subscriptionBundlePocketCoffee.setFieldValue(
        "base_plan.foc",
        undefined,
      );
    } else {
      formik_subscriptionBundlePocketCoffee.setFieldValue(
        `base_plan.foc.item_details`,
        updatedFormikValuesPocketCoffee,
      );
    }
  };

  const handleInputChangeFocItemPocketCoffee = (
    index: number,
    field: FocItemDetailFieldPocketCoffee,
    value: any,
  ) => {
    const updatedFocItemDetailsPocketCoffee = [...focItemDetailsPocketCoffee];
    (updatedFocItemDetailsPocketCoffee[index] as any)[field] = value;
    setFocItemDetailsPocketCoffee(updatedFocItemDetailsPocketCoffee);
    formik_subscriptionBundlePocketCoffee.setFieldValue(
      `base_plan.foc.item_details[${index}].${field}`,
      value,
    );
  };

  // const calculateTotalRequiredBeansPocketCoffee = (itemDetails: any) => {
  //   let uncheckedTotal = 0;
  //   let maxCheckedQuantity = 0;
  //   for (const item of itemDetails) {
  //     if (item.optional === 1) {
  //       if (item.quantity > maxCheckedQuantity) {
  //         maxCheckedQuantity = item.quantity;
  //       }
  //     } else {
  //       uncheckedTotal += item.quantity;
  //     }
  //   }
  //   const totalBeans = uncheckedTotal + maxCheckedQuantity;
  //   return totalBeans;
  // };

  // const calculateTotalAddOnRequiredBeansPocketCoffee = (itemDetails: any) => {
  //   let uncheckedTotal = 0;
  //   let maxCheckedQuantity = 0;
  //   for (const item of itemDetails) {
  //     if (item.optional === 1) {
  //       if (item.quantity > maxCheckedQuantity) {
  //         maxCheckedQuantity = item.quantity;
  //       }
  //     } else {
  //       uncheckedTotal += item.quantity;
  //     }
  //   }
  //   const totalBeans = uncheckedTotal + maxCheckedQuantity;
  //   return totalBeans;
  // };

  const getProductListPocketCoffee = useCallback(async (index: any) => {
    try {
      const response = await get(`/admin/get_all_products`);
      if (response?.data && response?.success) {
        const product = response.data.map((item: any) => ({
          label: item.name,
          value: item.id,
          image_url: item.image_url,
          sub_category_id: item.sub_category_id,
        }));
        setFreeGiftProductsListsPocketCoffee((prevLists) => {
          const updatedLists = [...prevLists];
          updatedLists[index] = product;
          return updatedLists;
        });
      }
    } catch (error: any) {
      if (
        error?.response?.data?.message &&
        typeof error?.response?.data?.message === "string"
      ) {
        toast.error(error.response.data.message);
      }
    }
  }, []);

  const getProductCategoryPocketCoffee = useCallback(async (index: any) => {
    try {
      const response = await get(`/admin/get_category_by_id/2`);
      if (response?.data && response?.success) {
        const category = response.data.sub_category
          .filter(
            (item: any) =>
              item.name !== "Roadshow Promotion" &&
              item.name !== "Roadshow Promo",
          )
          .map((item: any) => ({
            label: item.name,
            value: item.id,
            sub_category_id: item.id,
          }));
        setFreeGiftProductsListsPocketCoffee((prevLists) => {
          const updatedLists = [...prevLists];
          updatedLists[index] = category;
          return updatedLists;
        });
      }
    } catch (error: any) {
      if (
        error?.response?.data?.message &&
        typeof error?.response?.data?.message === "string"
      ) {
        toast.error(error.response.data.message);
      }
    }
  }, []);

  const addFocAdvanceItemGroupPocketCoffee = () => {
    const newItemPocketCoffee = {
      sub_category_id: 0,
      eg: "",
      product_id: null,
      quantity: 0,
      freegift_no: 0,
      optional: 0,
    };
    setFocAdvanceItemDetailsPocketCoffee([
      ...focAdvanceItemDetailsPocketCoffee,
      newItemPocketCoffee,
    ]);

    // Check if `advance_foc` object exists in Formik state
    if (formik_subscriptionBundlePocketCoffee.values.base_plan.advance_foc) {
      // If `advance_foc` object exists, update `item_details`
      formik_subscriptionBundlePocketCoffee.setFieldValue(
        `base_plan.advance_foc.item_details`,
        [
          ...formik_subscriptionBundlePocketCoffee.values.base_plan.advance_foc
            .item_details,
          newItemPocketCoffee,
        ],
      );
    } else {
      // If `advance_foc` object doesn't exist, create it with the new `item_details`
      formik_subscriptionBundlePocketCoffee.setFieldValue(
        `base_plan.advance_foc`,
        {
          item_details: [newItemPocketCoffee],
          minimum_bundle_purchase_qty: 0,
        },
      );
    }
  };

  const removeFocAdvanceItemGroupPocketCoffee = (index: number) => {
    const updatedGroupsPocketCoffee = [...focAdvanceItemDetailsPocketCoffee];
    updatedGroupsPocketCoffee.splice(index, 1);
    setFocAdvanceItemDetailsPocketCoffee(updatedGroupsPocketCoffee);

    // Update Formik values
    const updatedFormikValuesPocketCoffee = [
      ...formik_subscriptionBundlePocketCoffee.values.base_plan.advance_foc
        .item_details,
    ];
    updatedFormikValuesPocketCoffee.splice(index, 1);

    // If the length of updatedFormikValuesPocketCoffee is 0, remove the whole `advance_foc` object
    if (updatedFormikValuesPocketCoffee.length === 0) {
      formik_subscriptionBundlePocketCoffee.setFieldValue(
        "base_plan.advance_foc",
        undefined,
      );
    } else {
      formik_subscriptionBundlePocketCoffee.setFieldValue(
        `base_plan.advance_foc.item_details`,
        updatedFormikValuesPocketCoffee,
      );
    }
  };

  const handleInputChangeFocAdvanceItemPocketCoffee = (
    index: number,
    field: AdvanceFocItemDetailFieldPocketCoffee,
    value: any,
  ) => {
    const updatedAdvanceFocItemDetailsPocketCoffee = [
      ...focAdvanceItemDetailsPocketCoffee,
    ];
    (updatedAdvanceFocItemDetailsPocketCoffee[index] as any)[field] = value;
    setFocAdvanceItemDetailsPocketCoffee(
      updatedAdvanceFocItemDetailsPocketCoffee,
    );
    // Update Formik values
    formik_subscriptionBundlePocketCoffee.setFieldValue(
      `base_plan.advance_foc.item_details[${index}].${field}`,
      value,
    );
  };

  const getAdvanceProductListPocketCoffee = useCallback(async (index: any) => {
    try {
      const response = await get(`/admin/get_all_products`);
      if (response?.data && response?.success) {
        const product = response.data.map((item: any) => ({
          label: item.name,
          value: item.id,
          image_url: item.image_url,
          sub_category_id: item.sub_category_id,
        }));

        // Update the specific product list based on the index
        setFreeGiftProductsListAdvancePocketCoffee((prevLists) => {
          const updatedLists = [...prevLists];
          updatedLists[index] = product;
          return updatedLists;
        });
      }
    } catch (error: any) {
      if (
        error?.response?.data?.message &&
        typeof error?.response?.data?.message === "string"
      ) {
        toast.error(error.response.data.message);
      }
    }

    return []; // Return an empty array as a fallback
  }, []);

  const getAdvanceProductCategoryPocketCoffee = useCallback(
    async (index: any) => {
      try {
        const response = await get(`/admin/get_category_by_id/2`);
        if (response?.data && response?.success) {
          const category = response.data.sub_category
            .filter(
              (item: any) =>
                item.name !== "Roadshow Promotion" &&
                item.name !== "Roadshow Promo",
            )
            .map((item: any) => ({
              label: item.name,
              value: item.id,
              sub_category_id: item.id,
            }));

          // Update the specific product list based on the index
          setFreeGiftProductsListAdvancePocketCoffee((prevLists) => {
            const updatedLists = [...prevLists];
            updatedLists[index] = category;
            return updatedLists;
          });
        }
      } catch (error: any) {
        if (
          error?.response?.data?.message &&
          typeof error?.response?.data?.message === "string"
        ) {
          toast.error(error.response.data.message);
        }
      }

      return []; // Return an empty array as a fallback
    },
    [],
  );

  const [optionGroupsPocketCoffee, setOptionGroupsPocketCoffee] = useState<
    OptionGroupPocketCoffee[]
  >([]);

  const addOptionGroupPocketCoffee = () => {
    const newGroupPocketCoffee = {
      add_on_name: "",
      cost_price: 0,
      price: 0,
      total_beans: 0,
      item_details: [
        { sub_category_id: 2, eg: "Premium", quantity: 0, optional: 0 },
        { sub_category_id: 1, eg: "Signature", quantity: 0, optional: 0 },
        { sub_category_id: 4, eg: "Standard", quantity: 0, optional: 0 },
        { sub_category_id: 3, eg: "Blended", quantity: 0, optional: 0 },
      ],
      foc: {
        type: "",
        item_details: [], // Start with an empty array
      },
    };

    setOptionGroupsPocketCoffee((prevGroups) => [
      ...prevGroups,
      newGroupPocketCoffee,
    ]);
    const currentAddOnPlansPocketCoffee =
      formik_subscriptionBundlePocketCoffee.values.add_on_plans ?? [];
    formik_subscriptionBundlePocketCoffee.setFieldValue("add_on_plans", [
      ...currentAddOnPlansPocketCoffee,
      newGroupPocketCoffee,
    ]);
  };

  const removeOptionGroupPocketCoffee = (index: number) => {
    const updatedGroupsPocketCoffee = [...optionGroupsPocketCoffee];
    updatedGroupsPocketCoffee.splice(index, 1);
    setOptionGroupsPocketCoffee(updatedGroupsPocketCoffee);

    const updatedPlansPocketCoffee = [
      ...formik_subscriptionBundlePocketCoffee.values.add_on_plans,
    ];
    updatedPlansPocketCoffee.splice(index, 1);
    formik_subscriptionBundlePocketCoffee.setFieldValue(
      "add_on_plans",
      updatedPlansPocketCoffee,
    );
  };

  const [beansAddOnOptionalPocketCoffee, setBeansAddOnOptionalPocketCoffee] =
    useState<any[]>(optionGroupsPocketCoffee.map(() => []));

  const onBeansAddOnOptionalChangePocketCoffee = (
    planIndex: number,
    itemIndex: number,
    checked: boolean,
  ) => {
    const updatedAddOnPlansPocketCoffee = [
      ...formik_subscriptionBundlePocketCoffee.values.add_on_plans,
    ];
    const updatedItemDetailsPocketCoffee =
      updatedAddOnPlansPocketCoffee[planIndex].item_details;

    updatedItemDetailsPocketCoffee[itemIndex].optional = checked
      ? 1 - updatedItemDetailsPocketCoffee[itemIndex].optional
      : 0;

    formik_subscriptionBundlePocketCoffee.setFieldValue(
      `add_on_plans[${planIndex}].item_details[${itemIndex}].optional`,
      updatedItemDetailsPocketCoffee[itemIndex].optional,
    );
  };

  const addFocAddOnItemGroupPocketCoffee = (planIndex: number) => {
    const newItemPocketCoffee = {
      sub_category_id: 0,
      eg: "",
      product_id: null,
      quantity: 0,
      freegift_no: 0,
      optional: 0,
    };

    setFocAddOnItemDetailsPocketCoffee((prevDetails: any) => {
      const updatedDetailsPocketCoffee = { ...prevDetails };
      updatedDetailsPocketCoffee[planIndex] =
        updatedDetailsPocketCoffee[planIndex] ?? [];
      updatedDetailsPocketCoffee[planIndex].push(newItemPocketCoffee);
      return updatedDetailsPocketCoffee;
    });

    const existingFocItemDetailsPocketCoffee =
      formik_subscriptionBundlePocketCoffee.values.add_on_plans[planIndex]?.foc
        ?.item_details ?? [];
    const updatedFormikValuesPocketCoffee = [
      ...existingFocItemDetailsPocketCoffee,
      newItemPocketCoffee,
    ];

    formik_subscriptionBundlePocketCoffee.setFieldValue(
      `add_on_plans[${planIndex}].foc.item_details`,
      updatedFormikValuesPocketCoffee,
    );
  };

  const removeFocAddOnItemGroupPocketCoffee = (
    planIndex: number,
    index: number,
  ) => {
    const updatedDetails = { ...focAddOnItemDetailsPocketCoffee };
    if (updatedDetails[planIndex]) {
      updatedDetails[planIndex].splice(index, 1);
      setFocAddOnItemDetailsPocketCoffee(updatedDetails);

      // Update Formik values
      const updatedFormikValues = [
        ...formik_subscriptionBundlePocketCoffee.values.add_on_plans[planIndex]
          .foc.item_details,
      ];
      updatedFormikValues.splice(index, 1);
      formik_subscriptionBundlePocketCoffee.setFieldValue(
        `add_on_plans[${planIndex}].foc.item_details`,
        updatedFormikValues,
      );
    }
  };

  const handleInputChangeFocAddOnItemPocketCoffee = (
    planIndex: number,
    index: number,
    field: AddOnFocItemDetailFieldPocketCoffee,
    value: any,
  ) => {
    const updatedAddOnFocItemDetails = { ...focAddOnItemDetailsPocketCoffee };

    if (!updatedAddOnFocItemDetails[planIndex]) {
      updatedAddOnFocItemDetails[planIndex] = [];
    }

    if (!updatedAddOnFocItemDetails[planIndex][index]) {
      updatedAddOnFocItemDetails[planIndex][index] = {};
    }

    updatedAddOnFocItemDetails[planIndex][index][field] = value;

    setFocAddOnItemDetailsPocketCoffee(updatedAddOnFocItemDetails);
    formik_subscriptionBundlePocketCoffee.setFieldValue(
      `add_on_plans[${planIndex}].foc.item_details[${index}].${field}`,
      value,
    );
  };

  const getAddOnProductListPocketCoffee = useCallback(
    async (planIndex: any, itemIndex: any) => {
      try {
        const response = await get(`/admin/get_all_products`);
        if (response?.data && response?.success) {
          const product = response.data.map((item: any) => ({
            label: item.name,
            value: item.id,
            image_url: item.image_url,
            sub_category_id: item.sub_category_id,
          }));

          // Update the specific product list based on the index
          setFreeGiftAddOnProductsListPocketCoffee((prevLists) => {
            const updatedLists = { ...prevLists };
            if (!updatedLists[planIndex]) {
              updatedLists[planIndex] = [];
            }
            updatedLists[planIndex][itemIndex] = product;
            return updatedLists;
          });
        }
      } catch (error: any) {
        if (
          error?.response?.data?.message &&
          typeof error?.response?.data?.message === "string"
        ) {
          toast.error(error.response.data.message);
        }
      }

      return []; // Return an empty array as a fallback
    },
    [],
  );

  const getAddOnProductCategoryPocketCoffee = useCallback(
    async (planIndex: any, itemIndex: any) => {
      try {
        const response = await get(`/admin/get_category_by_id/2`);
        if (response?.data && response?.success) {
          const category = response.data.sub_category
            .filter(
              (item: any) =>
                item.name !== "Roadshow Promotion" &&
                item.name !== "Roadshow Promo",
            )
            .map((item: any) => ({
              label: item.name,
              value: item.id,
              sub_category_id: item.id,
            }));

          // Update the specific product list based on the index
          setFreeGiftAddOnProductsListPocketCoffee((prevLists) => {
            const updatedLists = { ...prevLists };
            if (!updatedLists[planIndex]) {
              updatedLists[planIndex] = [];
            }
            updatedLists[planIndex][itemIndex] = category;
            return updatedLists;
          });
        }
      } catch (error: any) {
        if (
          error?.response?.data?.message &&
          typeof error?.response?.data?.message === "string"
        ) {
          toast.error(error.response.data.message);
        }
      }

      return []; // Return an empty array as a fallback
    },
    [],
  );

  const formikValueBundlePocketCoffee = location.state
    ? location.state.formikValueBundlePocketCoffee
    : null;

  useEffect(() => {
    if (
      formikValueBundlePocketCoffee &&
      formikValueBundlePocketCoffee.image_url
    ) {
      setSelectedFile(formikValueBundlePocketCoffee.image_url);
    }
    if (
      formikValueBundlePocketCoffee &&
      formikValueBundlePocketCoffee.detail_image_url
    ) {
      setSelectedDetailFile(formikValueBundlePocketCoffee.detail_image_url);
    }
  }, [formikValueBundlePocketCoffee]);

  const [focItemDetailsPocketCoffee, setFocItemDetailsPocketCoffee] = useState(
    formikValueBundlePocketCoffee &&
      formikValueBundlePocketCoffee.base_plan.foc &&
      formikValueBundlePocketCoffee.base_plan.foc.item_details &&
      formikValueBundlePocketCoffee.base_plan.foc.item_details.length > 0
      ? formikValueBundlePocketCoffee.base_plan.foc.item_details.map(
          (item: any) => ({
            ...item,
            product_id: item.product_id || 0,
            sub_category_id: item.sub_category_id || 0,
            freegift_no: item.freegift_no || 0,
            api_key_id: item.api_key_id || 0,
          }),
        )
      : [],
  );

  const [
    focAdvanceItemDetailsPocketCoffee,
    setFocAdvanceItemDetailsPocketCoffee,
  ] = useState(
    formikValueBundlePocketCoffee &&
      formikValueBundlePocketCoffee.base_plan.advance_foc &&
      formikValueBundlePocketCoffee.base_plan.advance_foc.item_details &&
      formikValueBundlePocketCoffee.base_plan.advance_foc.item_details.length >
        0
      ? formikValueBundlePocketCoffee.base_plan.advance_foc.item_details.map(
          (item: any) => ({
            ...item,
            product_id: item.product_id || 0,
            sub_category_id: item.sub_category_id || 0,
            freegift_no: item.freegift_no || 0,
            api_key_id: item.api_key_id || 0,
          }),
        )
      : [],
  );

  const [focAddOnItemDetailsPocketCoffee, setFocAddOnItemDetailsPocketCoffee] =
    useState(() => {
      if (
        formikValueBundlePocketCoffee &&
        formikValueBundlePocketCoffee.add_on_plans &&
        formikValueBundlePocketCoffee.add_on_plans.length > 0
      ) {
        return formikValueBundlePocketCoffee.add_on_plans.reduce(
          (acc: any, plan: any, index: any) => {
            if (
              plan.foc &&
              plan.foc.item_details &&
              plan.foc.item_details.length > 0
            ) {
              acc[index] = plan.foc.item_details.map((item: any) => ({
                ...item,
                product_id: item.product_id || 0,
                sub_category_id: item.sub_category_id || 0,
                api_key_id: item.api_key_id || 0,
              }));
            } else {
              acc[index] = [];
            }
            return acc;
          },
          {},
        );
      } else {
        return {};
      }
    });

  const initialCategoryValuesPocketCoffee = Array.from(
    { length: focItemDetailsPocketCoffee.length },
    () => 0,
  );

  const [selectedCategoriesPocketCoffee, setSelectedCategoriesPocketCoffee] =
    useState(
      formikValueBundlePocketCoffee?.base_plan?.foc?.item_details &&
        formikValueBundlePocketCoffee?.base_plan?.foc?.item_details?.length
        ? formikValueBundlePocketCoffee?.base_plan?.foc?.item_details?.map(
            (item: any) => (item.product_id !== 0 ? 1 : 2),
          )
        : initialCategoryValuesPocketCoffee,
    );

  const initialAdvanceCategoryValuesPocketCoffee = Array.from(
    { length: focAdvanceItemDetailsPocketCoffee.length },
    () => 0,
  );

  const [
    selectedAdvanceCategoriesPocketCoffee,
    setSelectedAdvanceCategoriesPocketCoffee,
  ] = useState(
    formikValueBundlePocketCoffee?.base_plan?.advance_foc?.item_details &&
      formikValueBundlePocketCoffee?.base_plan?.advance_foc?.item_details
        ?.length
      ? formikValueBundlePocketCoffee?.base_plan?.advance_foc?.item_details?.map(
          (item: any) => (item.product_id !== 0 ? 1 : 2),
        )
      : initialAdvanceCategoryValuesPocketCoffee,
  );

  const initialAddOnCategoryValuesPocketCoffee = Array.from(
    { length: focAddOnItemDetailsPocketCoffee.length },
    () => 0,
  ); // Initialize with 0 for all groups

  const [
    selectedAddOnCategoriesPocketCoffee,
    setSelectedAddOnCategoriesPocketCoffee,
  ] = useState(
    formikValueBundlePocketCoffee?.add_on_plans &&
      formikValueBundlePocketCoffee?.add_on_plans?.length
      ? formikValueBundlePocketCoffee?.add_on_plans?.map((plan: any) =>
          plan?.foc?.item_details?.map((item: any) =>
            item.product_id !== 0 ? 1 : 2,
          ),
        )
      : initialAddOnCategoryValuesPocketCoffee,
  );

  const initialProductValuesPocketCoffee = Array.from(
    { length: focItemDetailsPocketCoffee.length },
    () => null,
  ); // Initialize with null for all groups

  const [selectedProductsPocketCoffee, setSelectedProductsPocketCoffee] =
    useState(initialProductValuesPocketCoffee);

  const initialAdvanceProductValuesPocketCoffee = Array.from(
    { length: focAdvanceItemDetailsPocketCoffee.length },
    () => null,
  ); // Initialize with null for all groups

  const [
    selectedAdvanceProductsPocketCoffee,
    setSelectedAdvanceProductsPocketCoffee,
  ] = useState(initialAdvanceProductValuesPocketCoffee);

  const initialAddOnProductValuesPocketCoffee = Array.from(
    { length: focAddOnItemDetailsPocketCoffee.length },
    () => null,
  ); // Initialize with null for all groups

  const [
    selectedAddOnProductsPocketCoffee,
    setSelectedAddOnProductsPocketCoffee,
  ] = useState(initialAddOnProductValuesPocketCoffee);

  const subscriptionOptions = useApiData(`/product/get_subscription_options`);
  const subscriptionOptionData = subscriptionOptions.data as any;
  const subscriptionOptionList = subscriptionOptionData?.map((item: any) => {
    return { label: item?.name, value: item?.id };
  });

  const initialValuesBundlePocketCoffee = {
    id: 0,
    image_url: "",
    detail_image_url: "",
    status: true,
    plan_name: "",
    sku: "",
    subscription_option_id: "",
    stock: "",
    category_id: 0,
    sub_category_id: 0,
    api_key_id: 0,
    base_plan: {
      cost_price: 0,
      price: 0,
      // total_beans: 0,
      pocket_coffee_boxes: 0,
      item_details: [
        {
          sub_category_id: 2,
          eg: "Premium",
          quantity: 0,
          optional: 0,
        },
        {
          sub_category_id: 1,
          eg: "Signature",
          quantity: 0,
          optional: 0,
        },
        {
          sub_category_id: 4,
          eg: "Standard",
          quantity: 0,
          optional: 0,
        },
        {
          sub_category_id: 3,
          eg: "Blended",
          quantity: 0,
          optional: 0,
        },
      ],
      foc: {
        type: "",
        item_details: focItemDetailsPocketCoffee,
      },
      advance_foc: {
        type: "",
        minimum_bundle_purchase_qty: 0,
        item_details: focAdvanceItemDetailsPocketCoffee,
      },
    },
    add_on_plans: [],
  };

  interface SubscriptionBundlePocketCoffee {
    id: number;
    image_url: string;
    detail_image_url: string;
    status: boolean;
    plan_name: string;
    sku: string;
    api_key_id: number;
    subscription_option_id: string;
    stock: string;
    category_id: number;
    sub_category_id: number;
    base_plan: {
      cost_price: number;
      price: number;
      // total_beans: number;
      pocket_coffee_boxes: number;
      item_details: {
        sub_category_id: number;
        eg: string;
        quantity: number;
        optional: number;
      }[];
      foc: {
        type: string;
        item_details: {
          sub_category_id: number;
          product_id: number;
          quantity: number;
          freegift_no: number;
          optional: number;
          api_key_id: number;
        }[];
      };
      advance_foc: {
        type: string;
        minimum_bundle_purchase_qty: number;
        item_details: {
          sub_category_id: number;
          product_id: number;
          quantity: number;
          freegift_no: number;
          optional: number;
          api_key_id: number;
        }[];
      };
    };
    add_on_plans: {
      add_on_name: string;
      cost_price: number;
      price: number;
      pocket_coffee_boxes: number;
      // total_beans: number;
      item_details: {
        sub_category_id: number;
        eg: string;
        quantity: number;
        optional: number;
      }[];
      foc: {
        type: string;
        item_details: {
          sub_category_id: number;
          product_id: number;
          quantity: number;
          freegift_no: number;
          optional: number;
          api_key_id: number;
        }[];
      };
    }[];
  }

  type SubCategoryIDPocketCoffee = 1 | 2 | 3 | 4;

  const subCategoryIdToLabelPocketCoffee: Record<
    SubCategoryIDPocketCoffee,
    string
  > = {
    2: "Premium",
    1: "Signature",
    4: "Standard",
    3: "Blended",
  };

  const formik_subscriptionBundlePocketCoffee =
    useFormik<SubscriptionBundlePocketCoffee>({
      initialValues:
        formikValueBundlePocketCoffee || initialValuesBundlePocketCoffee,
      validationSchema: Yup.object({
        plan_name: Yup.string().required("Plan name is required"),
        sku: Yup.string().required("SKU is required"),
        // api_key_id: Yup.number().required("Source is required"),
        subscription_option_id: Yup.number().required(
          "Subscription Option Id is required",
        ),
        stock: Yup.string().when("id", (id, schema) => {
          return +id === 0
            ? schema
                .matches(/^\d*$/, "Stock is not valid")
                .required("Stock is required")
            : schema;
        }),
        base_plan: Yup.object().shape({
          cost_price: Yup.number()
            .test(
              "is-greater-than-zero",
              "Cost Price must be greater than 0",
              (value) => value !== undefined && value > 0,
            )
            .required("Cost Price is required"),
          price: Yup.number()
            .test(
              "is-greater-than-zero",
              "Price must be greater than 0",
              (value) => value !== undefined && value > 0,
            )
            .required("Price is required"),
          pocket_coffee_boxes: Yup.number()
            .required("Pocket Coffee Boxes is required")
            .min(1, "Pocket Coffee Boxes must be greater than 0"),
          // total_beans: Yup.number()
          //   .test(
          //     "is-valid-total-beans",
          //     "Total Beans must be greater than 0",
          //     function (value) {
          //       const { item_details } = this.parent; // Get the item_details array
          //       const totalBeans =
          //         calculateTotalRequiredBeansPocketCoffee(item_details);
          //       return totalBeans > 0;
          //     },
          //   )
          //   .required("Total Beans is required"),
          foc: Yup.object().shape({
            item_details: Yup.array().of(
              Yup.object().shape({
                api_key_id: Yup.number().required("Source is required"),
                sub_category_id: Yup.number()
                  .required("Sub Category ID is required")
                  .notOneOf([0], "Sub Category is required"),
                product_id: Yup.number()
                  .required("Product ID is required")
                  .test(
                    "is-not-null",
                    "Product is required",
                    (value) => value !== null,
                  ),
                quantity: Yup.number()
                  .required("Quantity is required")
                  .min(1, "Quantity must be greater than 0"),
                freegift_no: Yup.number()
                  .required("Free Gift number is required")
                  .min(1, "Free Gift number must be greater than 0"),
              }),
            ),
          }),
          advance_foc: Yup.object().shape({
            minimum_bundle_purchase_qty: Yup.number().test(
              "conditional-requirement",
              "Minimum Bundle Purchase Quantity is required",
              function (value) {
                const { item_details } = this.parent;
                return item_details && item_details.length > 0
                  ? value !== undefined && value >= 1
                  : true;
              },
            ),
            item_details: Yup.array().of(
              Yup.object().shape({
                api_key_id: Yup.number().required("Source is required"),
                sub_category_id: Yup.number()
                  .required("Sub Category ID is required")
                  .notOneOf([0], "Sub Category is required"),
                product_id: Yup.number()
                  .required("Product ID is required")
                  .test(
                    "is-not-null",
                    "Product is required",
                    (value) => value !== null,
                  ),
                quantity: Yup.number()
                  .required("Quantity is required")
                  .min(1, "Quantity must be greater than 0"),
                freegift_no: Yup.number()
                  .required("Free Gift number is required")
                  .min(1, "Free Gift number must be greater than 0"),
              }),
            ),
          }),
        }),
        add_on_plans: Yup.array().of(
          Yup.object().shape({
            add_on_name: Yup.string().required("Add On Name is required"),
            cost_price: Yup.number().required("Cost Price is required"),
            price: Yup.number().required("Price is required"),
            pocket_coffee_boxes: Yup.number()
              .required("Pocket Coffee Boxes is required")
              .min(1, "Pocket Coffee Boxes must be greater than 0"),
            // total_beans: Yup.number()
            //   .test(
            //     "is-valid-total-beans",
            //     "Total Beans must be greater than 0",
            //     function (value) {
            //       const { item_details } = this.parent; // Get the item_details array
            //       const totalBeans =
            //         calculateTotalAddOnRequiredBeansPocketCoffee(item_details);
            //       return totalBeans > 0;
            //     },
            //   )
            //   .required("Total Beans is required"),
            foc: Yup.object().shape({
              item_details: Yup.array()
                .of(
                  Yup.object().shape({
                    api_key_id: Yup.number().required("Source is required"),
                    sub_category_id: Yup.number()
                      .required("Sub Category ID is required")
                      .notOneOf([0], "Sub Category is required"),
                    product_id: Yup.number()
                      .required("Product ID is required")
                      .test(
                        "is-not-null",
                        "Product is required",
                        (value) => value !== null,
                      ),
                    quantity: Yup.number()
                      .required("Quantity is required")
                      .min(1, "Quantity must be greater than 0"),
                    freegift_no: Yup.number()
                      .required("Free Gift number is required")
                      .min(1, "Free Gift number must be greater than 0"),
                  }),
                )
                .test(
                  "unique-sub-category-validation-PocketCoffee",
                  "Please select another product as the same category is being chosen",
                  function (item) {
                    const itemDetails = item || [];

                    const specialSubCategoryIds = [1, 2, 3, 4];

                    const specialItemsWithProductId = itemDetails.filter(
                      (item: any) =>
                        specialSubCategoryIds.includes(item.sub_category_id) &&
                        item.product_id !== 0,
                    );

                    if (specialItemsWithProductId.length === 0) return true;

                    const uniqueSubCategoryIds = new Set(
                      specialItemsWithProductId.map(
                        (item: any) => item.sub_category_id,
                      ),
                    );

                    const invalidItemsExist = itemDetails.some(
                      (item: any) =>
                        !item.product_id &&
                        uniqueSubCategoryIds.has(item.sub_category_id),
                    );

                    return !invalidItemsExist;
                  },
                ),
            }),
          }),
        ),
      }),
      validateOnChange: true,
      onSubmit: async (values) => {
        const {
          id,
          image_url,
          detail_image_url,
          plan_name,
          sku,
          // api_key_id,
          stock,
          status,
          sub_category_id,
          subscription_option_id,
          base_plan: {
            cost_price,
            price,
            pocket_coffee_boxes,
            item_details,
            advance_foc,
          },
        } = values;
        console.log(values);
        const file: any = image_url;
        const detail_file: any = detail_image_url;
        // Calculate the total beans with "PocketCoffee" appended to the function name
        // const total_beans =
        //   calculateTotalRequiredBeansPocketCoffee(item_details);

        // Create the 'base_plan' object with "PocketCoffee" naming convention
        const base_planPocketCoffee = {
          cost_price,
          price,
          pocket_coffee_boxes,
          // total_beans,
          item_details: item_details.map((item: any) => ({
            sub_category_id: item.sub_category_id,
            quantity: item.quantity,
            optional: item.optional,
          })),
          // Conditionally include 'foc' property with "PocketCoffee"
          ...(focItemDetailsPocketCoffee.length > 0 && {
            foc: {
              type: "base_plan_foc",
              item_details: focItemDetailsPocketCoffee.map((item: any) => ({
                sub_category_id: item.sub_category_id,
                product_id: item.product_id,
                quantity: item.quantity,
                optional: item.optional,
                freegift_no: item.freegift_no,
                api_key_id: item.api_key_id,
              })),
            },
          }),
          // Conditionally include 'advance_foc' property with "PocketCoffee"
          ...(focAdvanceItemDetailsPocketCoffee.length > 0 && {
            advance_foc: {
              type: "advance_foc",
              minimum_bundle_purchase_qty:
                advance_foc.minimum_bundle_purchase_qty,
              item_details: focAdvanceItemDetailsPocketCoffee.map(
                (item: any) => ({
                  sub_category_id: item.sub_category_id,
                  product_id: item.product_id,
                  quantity: item.quantity,
                  optional: item.optional,
                  freegift_no: item.freegift_no,
                  api_key_id: item.api_key_id,
                }),
              ),
            },
          }),
        };

        // Process the add_on_plans with "PocketCoffee"
        const add_on_plansPocketCoffee =
          formik_subscriptionBundlePocketCoffee.values.add_on_plans?.map(
            (group: any, index: any) => {
              // const total_beans_add_on =
              //   calculateTotalAddOnRequiredBeansPocketCoffee(
              //     group.item_details,
              //   );

              return {
                add_on_name: group.add_on_name,
                cost_price: group.cost_price,
                price: group.price,
                pocket_coffee_boxes: group.pocket_coffee_boxes,
                // total_beans: total_beans_add_on,
                item_details: group.item_details.map((item: any) => ({
                  sub_category_id: item.sub_category_id,
                  quantity: item.quantity,
                  optional: item.optional,
                })),
                // Conditionally include 'foc' property with "PocketCoffee"
                ...(focAddOnItemDetailsPocketCoffee[index] &&
                  focAddOnItemDetailsPocketCoffee[index].length > 0 && {
                    foc: {
                      type: "add_on_plan_foc",
                      item_details: focAddOnItemDetailsPocketCoffee[index].map(
                        (item: any) => ({
                          sub_category_id: item.sub_category_id,
                          product_id: item.product_id,
                          quantity: item.quantity,
                          optional: item.optional,
                          freegift_no: item.freegift_no,
                          api_key_id: item.api_key_id,
                        }),
                      ),
                    },
                  }),
              };
            },
          );

        try {
          setProductLoading(true);
          const formData = new FormData();
          if (id > 0) {
            formData.append("id", id.toString());
          }

          if (typeof file === "object" && file !== null) {
            formData.append("image_url", file);
          }
          if (typeof detail_file === "object" && detail_file !== null) {
            formData.append("detail_image_url", detail_file);
          }
          formData.append("category_id", "3"); // Assuming "3" is an example category ID
          formData.append("plan_name", plan_name);
          formData.append("stock", stock);
          // Append the 'base_plan' object with "PocketCoffee" convention
          formData.append("base_plan", JSON.stringify(base_planPocketCoffee));
          if (add_on_plansPocketCoffee && add_on_plansPocketCoffee.length > 0) {
            // Append the add-on plans with "PocketCoffee" convention
            formData.append(
              "add_on_plans",
              JSON.stringify(add_on_plansPocketCoffee),
            );
          }
          formData.append("sku", sku);
          // formData.append("api_key_id", api_key_id as unknown as string);
          formData.append("status", status ? "1" : "0");
          formData.append("subscription_option_id", subscription_option_id);

          // Assuming 'post' is a function from an API service module
          const response = await post(
            "/admin/save_product_bundle_pocket_coffee",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            },
          );

          setProductLoading(false);

          if (response?.success) {
            toast.success(response.message);
            setSelectedFile("");
            setSelectedDetailFile("");
            formik_subscriptionBundlePocketCoffee.resetForm();
            navigate("/inventory");
          }
        } catch (error: any) {
          setProductLoading(false);

          if (
            error.response?.data?.message &&
            typeof error.response.data.message === "string"
          ) {
            toast.error(error.response.data.message);
          }
        }
      },
    });

  console.log(formik_subscriptionBundlePocketCoffee.values);
  console.log(formik_subscriptionBundlePocketCoffee.errors);

  const hasMountedFocPocketCoffee = useRef(false);
  const hasMountedAdvanceFocPocketCoffee = useRef(false);
  const hasMountedAddOnFocPocketCoffee = useRef(false);

  useEffect(() => {
    if (!hasMountedFocPocketCoffee.current) {
      focItemDetailsPocketCoffee.forEach((item: any, index: any) => {
        if (selectedCategoriesPocketCoffee[index] === 1) {
          getProductListPocketCoffee(index);
        } else if (selectedCategoriesPocketCoffee[index] === 2) {
          getProductCategoryPocketCoffee(index);
        }
        hasMountedFocPocketCoffee.current = true;
      });
    }
  }, [
    focItemDetailsPocketCoffee,
    selectedCategoriesPocketCoffee,
    getProductListPocketCoffee,
    getProductCategoryPocketCoffee,
  ]);

  useEffect(() => {
    if (!hasMountedAdvanceFocPocketCoffee.current) {
      focAdvanceItemDetailsPocketCoffee.forEach((item: any, index: any) => {
        if (selectedAdvanceCategoriesPocketCoffee[index] === 1) {
          getAdvanceProductListPocketCoffee(index);
        } else if (selectedAdvanceCategoriesPocketCoffee[index] === 2) {
          getAdvanceProductCategoryPocketCoffee(index);
        }
        hasMountedAdvanceFocPocketCoffee.current = true;
      });
    }
  }, [
    focAdvanceItemDetailsPocketCoffee,
    selectedAdvanceCategoriesPocketCoffee,
    getAdvanceProductListPocketCoffee,
    getAdvanceProductCategoryPocketCoffee,
  ]);

  useEffect(() => {
    if (
      !hasMountedAddOnFocPocketCoffee.current &&
      focAddOnItemDetailsPocketCoffee &&
      selectedAddOnCategoriesPocketCoffee
    ) {
      Object.entries(focAddOnItemDetailsPocketCoffee).forEach(
        ([planIndex, items]) => {
          if (items) {
            (items as Array<any>).forEach((item: any, itemIndex: any) => {
              if (
                selectedAddOnCategoriesPocketCoffee[planIndex] &&
                selectedAddOnCategoriesPocketCoffee[planIndex][itemIndex] === 1
              ) {
                getAddOnProductListPocketCoffee(planIndex, itemIndex);
              } else if (
                selectedAddOnCategoriesPocketCoffee[planIndex] &&
                selectedAddOnCategoriesPocketCoffee[planIndex][itemIndex] === 2
              ) {
                getAddOnProductCategoryPocketCoffee(planIndex, itemIndex);
              }
            });
          }
        },
      );
      hasMountedAddOnFocPocketCoffee.current = true;
    }
  }, [
    focAddOnItemDetailsPocketCoffee,
    selectedAddOnCategoriesPocketCoffee,
    getAddOnProductListPocketCoffee,
    getAddOnProductCategoryPocketCoffee,
  ]);

  // free gift category selection based on api key
  const freeGiftCategoryListPocketCoffee = [
    {
      label: "Product",
      value: 1,
    },
    {
      label: "Category",
      value: 2,
    },
  ];

  return (
    <div className="card-cfg !shadow-none">
      <form onSubmit={formik_subscriptionBundlePocketCoffee.handleSubmit}>
        <div className="flex md:justify-start md:flex-row flex-col gap-4">
          <div className="bg-[#f7f3f3] text-center p-4 rounded-[7px]">
            <div
              className={`w-[128px] h-[128px] border border-solid rounded-[6px] bg-[#FFFFFF] m-auto flex justify-center items-center relative ${
                formik_subscriptionBundlePocketCoffee.touched.image_url &&
                formik_subscriptionBundlePocketCoffee.errors.image_url
                  ? "border-[#f44336]"
                  : "border-[#ACACAC]"
              }`}
            >
              {selectedFile ||
              formik_subscriptionBundlePocketCoffee.values.image_url ? (
                <div className="w-full h-full relative">
                  <img
                    src={
                      selectedFile ||
                      formik_subscriptionBundlePocketCoffee.values.image_url
                    }
                    onError={() =>
                      setSelectedFile(`${process.env.PUBLIC_URL}/no-image.png`)
                    }
                    alt="Preview"
                    className="m-[auto] w-full h-full object-cover rounded-[6px] p-[10px]"
                  />
                  <button
                    className="absolute top-[-10px] right-[-10px] rounded-[50%] bg-gradient-to-b from-[#FFD3AB] to-[#FFF6E3]"
                    onClick={() => {
                      formik_subscriptionBundlePocketCoffee.setFieldValue(
                        "image_url",
                        null,
                      );
                      setSelectedFile("");
                    }}
                  >
                    <img
                      src={require("../assets/images/icon-close.png")}
                      alt="Remove icon"
                      className="w-[25px]"
                    />
                  </button>
                </div>
              ) : (
                <>
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    id="image_url"
                    name="image_url"
                    hidden
                    onChange={(e: any) => {
                      onFileUpload(e, "product");
                      formik_subscriptionBundlePocketCoffee.setFieldValue(
                        "image_url",
                        e?.target?.files[0],
                      );
                      setSelectedFile(URL.createObjectURL(e?.target?.files[0]));
                    }}
                  />
                  <label
                    htmlFor="image_url"
                    className="text-[12px] leading-[15px] text-[#4C4C4C] font-[400] w-[80px] flex justify-center items-center flex-col text-center gap-y-[7px]"
                  >
                    <img
                      src={require("../assets/images/icon-add-circle.png")}
                      alt="Add icon"
                      className="w-[22px]"
                    />
                    Add Bundle Image
                  </label>
                </>
              )}
            </div>
            <ValidationError
              className={
                formik_subscriptionBundlePocketCoffee.touched.image_url &&
                formik_subscriptionBundlePocketCoffee.errors.image_url
                  ? "visible"
                  : "invisible"
              }
            >
              {formik_subscriptionBundlePocketCoffee.errors.image_url}
            </ValidationError>
            <div
              className={`w-[128px] h-[128px] border border-solid rounded-[6px] bg-[#FFFFFF] m-auto flex justify-center items-center relative ${
                formik_subscriptionBundlePocketCoffee.touched.image_url &&
                formik_subscriptionBundlePocketCoffee.errors.image_url
                  ? "border-[#f44336]"
                  : "border-[#ACACAC]"
              }`}
            >
              {selectedDetailFile ||
              formik_subscriptionBundlePocketCoffee.values.detail_image_url ? (
                <div className="w-full h-full relative">
                  <img
                    src={
                      selectedDetailFile ||
                      formik_subscriptionBundlePocketCoffee.values
                        .detail_image_url
                    }
                    onError={() =>
                      setSelectedDetailFile(
                        `${process.env.PUBLIC_URL}/no-image.png`,
                      )
                    }
                    alt="Preview"
                    className="m-[auto] w-full h-full object-cover rounded-[6px] p-[10px]"
                  />
                  <button
                    className="absolute top-[-10px] right-[-10px] rounded-[50%] bg-gradient-to-b from-[#FFD3AB] to-[#FFF6E3]"
                    onClick={() => {
                      formik_subscriptionBundlePocketCoffee.setFieldValue(
                        "detail_image_url",
                        null,
                      );
                      setSelectedDetailFile("");
                    }}
                  >
                    <img
                      src={require("../assets/images/icon-close.png")}
                      alt="Remove icon"
                      className="w-[25px]"
                    />
                  </button>
                </div>
              ) : (
                <>
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    id="detail_image_url"
                    name="detail_image_url"
                    hidden
                    onChange={(e: any) => {
                      onFileUpload(e, "detail");
                      formik_subscriptionBundlePocketCoffee.setFieldValue(
                        "detail_image_url",
                        e?.target?.files[0],
                      );
                      setSelectedDetailFile(
                        URL.createObjectURL(e?.target?.files[0]),
                      );
                    }}
                  />
                  <label
                    htmlFor="detail_image_url"
                    className="text-[12px] leading-[15px] text-[#4C4C4C] font-[400] w-[80px] flex justify-center items-center flex-col text-center gap-y-[7px]"
                  >
                    <img
                      src={require("../assets/images/icon-add-circle.png")}
                      alt="Add icon"
                      className="w-[22px]"
                    />
                    Add Bundle Detail Image
                  </label>
                </>
              )}
            </div>
            <ValidationError
              className={
                formik_subscriptionBundlePocketCoffee.touched
                  .detail_image_url &&
                formik_subscriptionBundlePocketCoffee.errors.detail_image_url
                  ? "visible"
                  : "invisible"
              }
            >
              {formik_subscriptionBundlePocketCoffee.errors.detail_image_url}
            </ValidationError>
            <div className="order-9 md:order-9 xl:order-9">
              <label
                htmlFor="status"
                className="text-[16px] text-[#292929] font-[500] capitalize mb-[16px] block"
              >
                Status
              </label>
              <InputSwitch
                name="status"
                inputId="status"
                checked={formik_subscriptionBundlePocketCoffee.values.status}
                onChange={formik_subscriptionBundlePocketCoffee.handleChange}
                onBlur={formik_subscriptionBundlePocketCoffee.handleBlur}
              />
            </div>
          </div>
          <div className="grid md:grid-cols-2 w-full gap-4 bg-[#f7f3f3] p-4 rounded-[7px]">
            <div>
              <label
                htmlFor="plan name"
                className="text-[#292929] capitalize block mb-2"
              >
                Plan Name
              </label>
              <InputText
                className={`w-full ${
                  formik_subscriptionBundlePocketCoffee.touched.plan_name &&
                  formik_subscriptionBundlePocketCoffee.errors.plan_name
                    ? "p-invalid"
                    : ""
                }`}
                name="plan_name"
                id="plan_name"
                placeholder="Plan Name"
                value={formik_subscriptionBundlePocketCoffee.values.plan_name}
                onChange={formik_subscriptionBundlePocketCoffee.handleChange}
                onBlur={formik_subscriptionBundlePocketCoffee.handleBlur}
              />
              <ValidationError
                className={
                  formik_subscriptionBundlePocketCoffee.touched.plan_name &&
                  formik_subscriptionBundlePocketCoffee.errors.plan_name
                    ? "block"
                    : "hidden"
                }
              >
                {formik_subscriptionBundlePocketCoffee.errors.plan_name}
              </ValidationError>
            </div>
            {/* <div>
              <label
                htmlFor="api_key_id"
                className="text-[16px] text-[#292929] font-[500] capitalize mb-2 block"
              >
                Sources
              </label>
              <Dropdown
                className={`w-full ${
                  formik_subscriptionBundlePocketCoffee.touched.api_key_id &&
                  formik_subscriptionBundlePocketCoffee.errors.api_key_id
                    ? "p-invalid"
                    : ""
                }`}
                options={sourcesList}
                name="api_key_id"
                inputId="api_key_id"
                placeholder="Sources"
                value={formik_subscriptionBundlePocketCoffee.values.api_key_id}
                onChange={formik_subscriptionBundlePocketCoffee.handleChange}
                onBlur={formik_subscriptionBundlePocketCoffee.handleBlur}
              />
              <ValidationError
                className={
                  formik_subscriptionBundlePocketCoffee.touched.api_key_id &&
                  formik_subscriptionBundlePocketCoffee.errors.api_key_id
                    ? "block"
                    : "hidden"
                }
              >
                {formik_subscriptionBundlePocketCoffee.errors.api_key_id}
              </ValidationError>
            </div> */}
            <div>
              <label
                htmlFor="sku"
                className="text-[#292929] capitalize block mb-2"
              >
                SKU
              </label>
              <InputText
                className={`w-full ${
                  formik_subscriptionBundlePocketCoffee.touched.sku &&
                  formik_subscriptionBundlePocketCoffee.errors.sku
                    ? "p-invalid"
                    : ""
                }`}
                name="sku"
                id="sku"
                placeholder="SKU"
                value={formik_subscriptionBundlePocketCoffee.values.sku}
                onChange={formik_subscriptionBundlePocketCoffee.handleChange}
                onBlur={formik_subscriptionBundlePocketCoffee.handleBlur}
              />
              <ValidationError
                className={
                  formik_subscriptionBundlePocketCoffee.touched.sku &&
                  formik_subscriptionBundlePocketCoffee.errors.sku
                    ? "block"
                    : "hidden"
                }
              >
                {formik_subscriptionBundlePocketCoffee.errors.sku}
              </ValidationError>
            </div>
            {formik_subscriptionBundlePocketCoffee.values.id === 0 && (
              <div>
                <label
                  htmlFor="stock"
                  className="text-[#292929] capitalize block mb-2"
                >
                  Stock
                </label>
                <InputText
                  className={`w-full ${
                    formik_subscriptionBundlePocketCoffee.touched.stock &&
                    formik_subscriptionBundlePocketCoffee.errors.stock
                      ? "p-invalid"
                      : ""
                  }`}
                  name="stock"
                  id="stock"
                  placeholder="Stock"
                  value={formik_subscriptionBundlePocketCoffee.values.stock}
                  onChange={formik_subscriptionBundlePocketCoffee.handleChange}
                  onBlur={formik_subscriptionBundlePocketCoffee.handleBlur}
                />
                <ValidationError
                  className={
                    formik_subscriptionBundlePocketCoffee.touched.stock &&
                    formik_subscriptionBundlePocketCoffee.errors.stock
                      ? "block"
                      : "hidden"
                  }
                >
                  {formik_subscriptionBundlePocketCoffee.errors.stock}
                </ValidationError>
              </div>
            )}
            <div className="md:col-span-2">
              <label
                htmlFor="subscription_option_id"
                className="text-[#292929] capitalize block mb-2"
              >
                Subscription Months
              </label>
              <Dropdown
                className={`w-full ${
                  formik_subscriptionBundlePocketCoffee.touched
                    .subscription_option_id &&
                  formik_subscriptionBundlePocketCoffee.errors
                    .subscription_option_id
                    ? "p-invalid"
                    : ""
                }`}
                options={subscriptionOptionList}
                name="subscription_option_id"
                inputId="subscription_option_id"
                placeholder="Subscription Months"
                value={
                  formik_subscriptionBundlePocketCoffee.values
                    .subscription_option_id
                }
                onChange={formik_subscriptionBundlePocketCoffee.handleChange}
                onBlur={formik_subscriptionBundlePocketCoffee.handleBlur}
              />
              <ValidationError
                className={
                  formik_subscriptionBundlePocketCoffee.touched
                    .subscription_option_id &&
                  formik_subscriptionBundlePocketCoffee.errors
                    .subscription_option_id
                    ? "block"
                    : "hidden"
                }
              >
                {
                  formik_subscriptionBundlePocketCoffee.errors
                    .subscription_option_id
                }
              </ValidationError>
            </div>
            {isRole(["Admin", "Manager"]) && (
              <>
                <div>
                  <label
                    htmlFor="base_plan.price"
                    className="text-[#292929] capitalize block mb-2"
                  >
                    Selling Price
                  </label>
                  <InputNumber
                    name="base_plan.price"
                    id="base_plan.price"
                    className={`w-full ${
                      formik_subscriptionBundlePocketCoffee.touched?.base_plan
                        ?.price &&
                      formik_subscriptionBundlePocketCoffee.errors?.base_plan
                        ?.price
                        ? "p-invalid"
                        : ""
                    }`}
                    placeholder="Selling Price"
                    value={
                      formik_subscriptionBundlePocketCoffee.values.base_plan
                        .price
                    }
                    onValueChange={(e) =>
                      formik_subscriptionBundlePocketCoffee.setFieldValue(
                        "base_plan.price",
                        e.value,
                      )
                    }
                    onBlur={formik_subscriptionBundlePocketCoffee.handleBlur}
                    prefix="$ "
                    minFractionDigits={2}
                  />
                  <ValidationError
                    className={
                      formik_subscriptionBundlePocketCoffee.touched.base_plan
                        ?.price &&
                      formik_subscriptionBundlePocketCoffee.errors.base_plan
                        ?.price
                        ? "block"
                        : "hidden"
                    }
                  >
                    {
                      formik_subscriptionBundlePocketCoffee.errors.base_plan
                        ?.price
                    }
                  </ValidationError>
                </div>
                <div>
                  <label
                    htmlFor="base_plan.cost_price"
                    className="text-[#292929] capitalize block mb-2"
                  >
                    Cost Price
                  </label>
                  <InputNumber
                    name="base_plan.cost_price"
                    id="base_plan.cost_price"
                    className={`w-full ${
                      formik_subscriptionBundlePocketCoffee.touched?.base_plan
                        ?.cost_price &&
                      formik_subscriptionBundlePocketCoffee.errors?.base_plan
                        ?.cost_price
                        ? "p-invalid"
                        : ""
                    }`}
                    placeholder="Cost Price"
                    value={
                      formik_subscriptionBundlePocketCoffee.values.base_plan
                        ?.cost_price
                    }
                    onValueChange={(e) =>
                      formik_subscriptionBundlePocketCoffee.setFieldValue(
                        "base_plan.cost_price",
                        e.value,
                      )
                    }
                    onBlur={formik_subscriptionBundlePocketCoffee.handleBlur}
                    prefix="$ "
                    minFractionDigits={2}
                  />
                  <ValidationError
                    className={
                      formik_subscriptionBundlePocketCoffee.touched.base_plan
                        ?.cost_price &&
                      formik_subscriptionBundlePocketCoffee.errors.base_plan
                        ?.cost_price
                        ? "block"
                        : "hidden"
                    }
                  >
                    {
                      formik_subscriptionBundlePocketCoffee.errors.base_plan
                        ?.cost_price
                    }
                  </ValidationError>
                </div>
                <div>
                  <label
                    htmlFor="base_plan.pocket_coffee_boxes"
                    className="text-[#292929] capitalize block mb-2"
                  >
                    Pocket Coffee Boxes
                  </label>
                  <InputNumber
                    name="base_plan.pocket_coffee_boxes"
                    id="base_plan.pocket_coffee_boxes"
                    className={`w-full ${
                      formik_subscriptionBundlePocketCoffee.touched?.base_plan
                        ?.pocket_coffee_boxes &&
                      formik_subscriptionBundlePocketCoffee.errors?.base_plan
                        ?.pocket_coffee_boxes
                        ? "p-invalid"
                        : ""
                    }`}
                    placeholder="Pocket Coffee Boxes"
                    value={
                      formik_subscriptionBundlePocketCoffee.values.base_plan
                        ?.pocket_coffee_boxes
                    }
                    onValueChange={(e) =>
                      formik_subscriptionBundlePocketCoffee.setFieldValue(
                        "base_plan.pocket_coffee_boxes",
                        e.value,
                      )
                    }
                    onBlur={formik_subscriptionBundlePocketCoffee.handleBlur}
                  />
                  <ValidationError
                    className={
                      formik_subscriptionBundlePocketCoffee.touched.base_plan
                        ?.pocket_coffee_boxes &&
                      formik_subscriptionBundlePocketCoffee.errors.base_plan
                        ?.pocket_coffee_boxes
                        ? "block"
                        : "hidden"
                    }
                  >
                    {
                      formik_subscriptionBundlePocketCoffee.errors.base_plan
                        ?.pocket_coffee_boxes
                    }
                  </ValidationError>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="flex md:justify-start md:flex-col flex-col gap-4  mt-[20px]">
          <div className="grid md:grid-cols-2 xl:grid-cols-4 w-full gap-4 bg-[#f7f3f3] p-4 rounded-[7px]">
            {/* {isRole(["Admin"]) && <></>}
        <div className="order-9 col-span-1 md:col-span-4">
          <Panel
            header={
              <div
                style={{ display: "flex", alignItems: "center" }}
              >
                {`Total Beans Required - ${calculateTotalRequiredBeans(
                  formik_subscriptionBundlePocketCoffee.values
                    .base_plan.item_details,
                )}`}
                <ValidationError
                  className={`!p-0 ${
                    formik_subscriptionBundlePocketCoffee.touched
                      .base_plan?.total_beans &&
                    formik_subscriptionBundlePocketCoffee.errors
                      .base_plan?.total_beans
                      ? "block ml-2"
                      : "hidden"
                  }
              `}
                >
                  {
                    formik_subscriptionBundlePocketCoffee.errors
                      .base_plan?.total_beans
                  }
                </ValidationError>
              </div>
            }
          >
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
              {formik_subscriptionBundlePocketCoffee.values.base_plan.item_details.map(
                (item: any, index: any) => (
                  <BeanInputOptional
                    key={index}
                    inputId={`bean-input-${index}`}
                    inputName={
                      subCategoryIdToLabel[
                        item.sub_category_id as SubCategoryID
                      ]?.toLowerCase() || item?.eg?.toLowerCase()
                    }
                    label={
                      subCategoryIdToLabel[
                        item.sub_category_id as SubCategoryID
                      ] || item.eg
                    }
                    checkBoxValue={`optional_${
                      subCategoryIdToLabel[
                        item.sub_category_id as SubCategoryID
                      ]?.toLowerCase() || item?.eg?.toLowerCase()
                    }`}
                    inputValue={item.quantity}
                    beansOptional={Boolean(item.optional)}
                    onBeansOptionalChange={(value) =>
                      onBeansOptionalChange(index, Boolean(value))
                    }
                    handleInputOnChange={(value) => {
                      formik_subscriptionBundlePocketCoffee.setFieldValue(
                        `base_plan.item_details[${index}].quantity`,
                        value,
                      );
                    }}
                  />
                ),
              )}
            </div>
          </Panel>
        </div> */}

            <div className="order-10 col-span-1 md:col-span-4">
              <Fieldset legend="Free Gift" toggleable>
                {focItemDetailsPocketCoffee.length > 0 &&
                  focItemDetailsPocketCoffee.map((group: any, index: any) => (
                    <div
                      key={index}
                      className={`grid grid-cols-1 md:grid-cols-2 gap-4 items-start ${
                        index !== focItemDetailsPocketCoffee.length - 1
                          ? "border-b-2"
                          : ""
                      } ${index === 0 ? "pb-[30px]" : "py-[30px]"}
                  `}
                    >
                      <div className="col-span-2">
                        <label
                          htmlFor={`api_key_id_${index}`}
                          className="text-[16px] text-[#292929] font-[500] capitalize mb-2 block"
                        >
                          {index + 1}. Sources
                        </label>
                        <Dropdown
                          name={`base_plan.foc.item_details[${index}].api_key_id`}
                          className={`w-full ${
                            formik_subscriptionBundlePocketCoffee.touched
                              .base_plan?.foc?.item_details?.[index]
                              ?.api_key_id &&
                            (
                              formik_subscriptionBundlePocketCoffee.errors
                                .base_plan?.foc?.item_details?.[index] as any
                            )?.api_key_id
                              ? "p-invalid"
                              : ""
                          }`}
                          options={sourcesList}
                          inputId={`api_key_id_${index}`}
                          placeholder="Select Source"
                          value={
                            formik_subscriptionBundlePocketCoffee.values
                              .base_plan.foc.item_details[index].api_key_id
                          }
                          onChange={(e) =>
                            handleInputChangeFocItemPocketCoffee(
                              index,
                              "api_key_id",
                              e.value || 0,
                            )
                          }
                        />
                        <ValidationError
                          className={
                            formik_subscriptionBundlePocketCoffee.touched
                              .base_plan?.foc?.item_details?.[index]
                              ?.api_key_id &&
                            (
                              formik_subscriptionBundlePocketCoffee.errors
                                .base_plan?.foc?.item_details?.[index] as any
                            )?.api_key_id &&
                            selectedCategoriesPocketCoffee[index] !== 0
                              ? "block"
                              : "hidden"
                          }
                        >
                          {
                            (
                              formik_subscriptionBundlePocketCoffee.errors
                                .base_plan?.foc?.item_details?.[index] as any
                            )?.api_key_id
                          }
                        </ValidationError>
                      </div>
                      <div>
                        <label
                          htmlFor={`FocItemCategoryPocketCoffee_${index}`}
                          className="text-[#292929] capitalize block mb-2"
                        >
                          {index + 1}. Gift Type
                        </label>
                        <Dropdown
                          name={`base_plan.foc.item_details[${index}].sub_category_id`}
                          className={`w-full ${
                            formik_subscriptionBundlePocketCoffee.touched
                              .base_plan?.foc?.item_details?.[index]
                              ?.sub_category_id &&
                            (
                              formik_subscriptionBundlePocketCoffee.errors
                                .base_plan?.foc?.item_details?.[index] as any
                            )?.sub_category_id
                              ? "p-invalid"
                              : ""
                          }`}
                          options={freeGiftCategoryListPocketCoffee}
                          value={selectedCategoriesPocketCoffee[index]} // Use selectedCategoriesPocketCoffee[index]
                          placeholder="Select Gift Type"
                          onChange={(e) => {
                            const updatedCategories = [
                              ...selectedCategoriesPocketCoffee,
                            ];
                            updatedCategories[index] = e.value;
                            setSelectedCategoriesPocketCoffee(
                              updatedCategories,
                            );

                            // Logic for updating item details on selection change
                            const updatedFocItemDetailsPocketCoffee =
                              focItemDetailsPocketCoffee.map(
                                (item: any, i: any) => {
                                  if (i === index) {
                                    return {
                                      ...item,
                                      product_id: null,
                                      sub_category_id: 0,
                                    };
                                  }
                                  return item;
                                },
                              );
                            setFocItemDetailsPocketCoffee(
                              updatedFocItemDetailsPocketCoffee,
                            );

                            formik_subscriptionBundlePocketCoffee.setFieldValue(
                              `base_plan.foc.item_details[${index}].sub_category_id`,
                              e.value,
                            );

                            if (e.value === 1) {
                              getProductListPocketCoffee(index);
                            } else if (e.value === 2) {
                              getProductCategoryPocketCoffee(index);
                            } else {
                              const updatedFreeGiftProductsListsPocketCoffee = [
                                ...freeGiftProductsListsPocketCoffee,
                              ];
                              updatedFreeGiftProductsListsPocketCoffee[index] =
                                [];
                              setFreeGiftProductsListsPocketCoffee(
                                updatedFreeGiftProductsListsPocketCoffee,
                              );
                            }
                          }}
                        />
                        <ValidationError
                          className={
                            formik_subscriptionBundlePocketCoffee.touched
                              .base_plan?.foc?.item_details?.[index]
                              ?.sub_category_id &&
                            (
                              formik_subscriptionBundlePocketCoffee.errors
                                .base_plan?.foc?.item_details?.[index] as any
                            )?.sub_category_id &&
                            selectedCategoriesPocketCoffee[index] !== 0
                              ? "block"
                              : "hidden"
                          }
                        >
                          {
                            (
                              formik_subscriptionBundlePocketCoffee.errors
                                .base_plan?.foc?.item_details?.[index] as any
                            )?.sub_category_id
                          }
                        </ValidationError>
                      </div>
                      <div>
                        <label
                          htmlFor={`FocItemPocketCoffee_${index}`}
                          className="text-[#292929] capitalize block mb-2"
                        >
                          {index + 1}. Free Gift
                        </label>
                        <Dropdown
                          className={`w-full ${
                            formik_subscriptionBundlePocketCoffee.touched
                              .base_plan?.foc?.item_details?.[index]
                              ?.product_id &&
                            (
                              formik_subscriptionBundlePocketCoffee.errors
                                .base_plan?.foc?.item_details?.[index] as any
                            )?.product_id
                              ? "p-invalid"
                              : ""
                          }`}
                          filter
                          options={
                            freeGiftProductsListsPocketCoffee[index] || []
                          } // Adjusted for "PocketCoffee"
                          name={`focItemPocketCoffee_${index}`}
                          inputId={`focItemPocketCoffee_${index}`}
                          placeholder="Select Free Gift"
                          value={group.product_id || group.sub_category_id}
                          onChange={(e) => {
                            const selectedValue = e.value;
                            const selectedItem = (
                              freeGiftProductsListsPocketCoffee[index] || []
                            ).find((item: any) => item.value === selectedValue);

                            if (selectedItem) {
                              const updatedFocItemDetailsPocketCoffee =
                                focItemDetailsPocketCoffee.map(
                                  (item: any, i: any) => {
                                    if (i === index) {
                                      if (
                                        selectedCategoriesPocketCoffee[
                                          index
                                        ] === 1
                                      ) {
                                        // If category is 1, set both sub_category_id and product_id
                                        return {
                                          ...item,
                                          sub_category_id:
                                            selectedItem.sub_category_id,
                                          label: selectedItem.label,
                                          product_id: selectedValue,
                                        };
                                      } else if (
                                        selectedCategoriesPocketCoffee[
                                          index
                                        ] === 2
                                      ) {
                                        // If category is 2, only set sub_category_id and clear product_id
                                        return {
                                          ...item,
                                          sub_category_id:
                                            selectedItem.sub_category_id,
                                          label: selectedItem.label,
                                          product_id: 0,
                                        };
                                      }
                                    }
                                    return item;
                                  },
                                );
                              setFocItemDetailsPocketCoffee(
                                updatedFocItemDetailsPocketCoffee,
                              );
                            }
                            if (selectedCategoriesPocketCoffee[index] === 1) {
                              formik_subscriptionBundlePocketCoffee.setFieldValue(
                                `base_plan.foc.item_details[${index}].product_id`,
                                e.value,
                              );
                              formik_subscriptionBundlePocketCoffee.setFieldValue(
                                `base_plan.foc.item_details[${index}].sub_category_id`,
                                selectedItem?.sub_category_id,
                              );
                            }
                            if (selectedCategoriesPocketCoffee[index] === 2) {
                              formik_subscriptionBundlePocketCoffee.setFieldValue(
                                `base_plan.foc.item_details[${index}].product_id`,
                                0,
                              );
                              formik_subscriptionBundlePocketCoffee.setFieldValue(
                                `base_plan.foc.item_details[${index}].sub_category_id`,
                                selectedItem?.sub_category_id,
                              );
                            }
                          }}
                          optionLabel="label"
                          optionValue="value"
                          itemTemplate={(option) => (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {option.image_url && (
                                <img
                                  src={option.image_url}
                                  alt={option.label}
                                  style={{
                                    width: "30px",
                                    height: "30px",
                                    marginRight: "10px",
                                  }}
                                />
                              )}
                              <span>{option.label}</span>
                            </div>
                          )}
                        />

                        <ValidationError
                          className={`w-full ${
                            formik_subscriptionBundlePocketCoffee.touched
                              .base_plan?.foc?.item_details?.[index]
                              ?.product_id &&
                            (
                              formik_subscriptionBundlePocketCoffee.errors
                                .base_plan?.foc?.item_details?.[index] as any
                            )?.product_id
                              ? "p-invalid"
                              : "hidden"
                          }`}
                        >
                          {
                            (
                              formik_subscriptionBundlePocketCoffee.errors
                                .base_plan?.foc?.item_details?.[index] as any
                            )?.product_id
                          }
                        </ValidationError>
                      </div>
                      <div>
                        <label
                          htmlFor={`FocItemQty_${index}`}
                          className="text-[#292929] capitalize block mb-2"
                        >
                          {index + 1}. Quantity
                        </label>
                        <InputNumber
                          value={group.quantity}
                          onChange={(e) =>
                            handleInputChangeFocItemPocketCoffee(
                              index,
                              "quantity",
                              e.value || 0,
                            )
                          }
                          className={`w-full ${
                            formik_subscriptionBundlePocketCoffee.touched
                              .base_plan?.foc?.item_details?.[index]
                              ?.quantity &&
                            (
                              formik_subscriptionBundlePocketCoffee.errors
                                .base_plan?.foc?.item_details?.[index] as any
                            )?.quantity
                              ? "p-invalid"
                              : ""
                          }`}
                        />
                        <ValidationError
                          className={
                            formik_subscriptionBundlePocketCoffee.touched
                              .base_plan?.foc?.item_details?.[index]
                              ?.quantity &&
                            (
                              formik_subscriptionBundlePocketCoffee.errors
                                .base_plan?.foc?.item_details?.[index] as any
                            )?.quantity
                              ? "block"
                              : "hidden"
                          }
                        >
                          {
                            (
                              formik_subscriptionBundlePocketCoffee.errors
                                .base_plan?.foc?.item_details?.[index] as any
                            )?.quantity
                          }
                        </ValidationError>
                      </div>
                      <div>
                        <label
                          htmlFor={`FocItemFreeGiftNoPocketCoffee_${index}`}
                          className="text-[#292929] capitalize block mb-2"
                        >
                          Free Gift Group Number
                        </label>
                        <InputNumber
                          id={`FocItemFreeGiftNoPocketCoffee_${index}`}
                          value={group.freegift_no ?? 0}
                          onValueChange={(e) => {
                            const updatedFocItemDetailsPocketCoffee = [
                              ...focItemDetailsPocketCoffee,
                            ];
                            updatedFocItemDetailsPocketCoffee[index] = {
                              ...group,
                              freegift_no: e.value ?? 0,
                            };
                            setFocItemDetailsPocketCoffee(
                              updatedFocItemDetailsPocketCoffee,
                            );

                            formik_subscriptionBundlePocketCoffee.setFieldValue(
                              `base_plan.foc.item_details[${index}].freegift_no`,
                              e.value ?? 0,
                            );
                          }}
                          className={`w-full ${
                            formik_subscriptionBundlePocketCoffee.touched
                              .base_plan?.foc?.item_details?.[index]
                              ?.freegift_no &&
                            (
                              formik_subscriptionBundlePocketCoffee.errors
                                .base_plan?.foc?.item_details?.[index] as any
                            )?.freegift_no
                              ? "p-invalid"
                              : ""
                          }`}
                        />
                        <ValidationError
                          className={
                            formik_subscriptionBundlePocketCoffee.touched
                              .base_plan?.foc?.item_details?.[index]
                              ?.freegift_no &&
                            (
                              formik_subscriptionBundlePocketCoffee.errors
                                .base_plan?.foc?.item_details?.[index] as any
                            )?.freegift_no
                              ? "block"
                              : "hidden"
                          }
                        >
                          {
                            (
                              formik_subscriptionBundlePocketCoffee.errors
                                .base_plan?.foc?.item_details?.[index] as any
                            )?.freegift_no
                          }
                        </ValidationError>
                      </div>

                      <div>
                        <Checkbox
                          inputId={`optionalFocItemPocketCoffee_${index}`}
                          checked={group.optional === 1}
                          onChange={(e) => {
                            const updatedFocItemDetailsPocketCoffee = [
                              ...focItemDetailsPocketCoffee,
                            ];
                            updatedFocItemDetailsPocketCoffee[index] = {
                              ...group,
                              optional: e.checked ? 1 : 0,
                            };
                            setFocItemDetailsPocketCoffee(
                              updatedFocItemDetailsPocketCoffee,
                            );

                            formik_subscriptionBundlePocketCoffee.setFieldValue(
                              `base_plan.foc.item_details[${index}].optional`,
                              e.checked ? 1 : 0,
                            );
                          }}
                        />
                        <label
                          htmlFor={`optionalFocItemPocketCoffee_${index}`}
                          className="ml-2"
                        >
                          Optional
                        </label>
                      </div>

                      <div className={`mt-auto`}>
                        <button
                          onClick={() => removeFocItemGroupPocketCoffee(index)}
                          type="button"
                          className="rounded-[10px] bg-red-500 text-white py-[9px] px-[24px] w-fit"
                        >
                          {focItemDetailsPocketCoffee.length === 1
                            ? "Disable Free Gift"
                            : `Remove Free Gift`}
                        </button>
                      </div>
                    </div>
                  ))}

                <button
                  type="button"
                  onClick={addFocItemGroupPocketCoffee}
                  className="rounded-[10px] bg-[#82d7d2] py-[9px] px-[24px]"
                >
                  {focItemDetailsPocketCoffee.length > 0
                    ? "Add Another Free Gift"
                    : "Enable Free Gift"}
                </button>
              </Fieldset>
            </div>
            <div className="order-11 col-span-1 md:col-span-4">
              <Fieldset legend="Advance Free Gift" toggleable>
                {focAdvanceItemDetailsPocketCoffee.length > 0 && (
                  <div className="pb-[16px]">
                    <label
                      htmlFor="Advance Free Gift"
                      className="text-[#292929] capitalize block mb-2"
                    >
                      Minimum Bundle Purchase Quantity
                    </label>
                    <InputNumber
                      className={`${
                        formik_subscriptionBundlePocketCoffee.touched.base_plan
                          ?.advance_foc?.minimum_bundle_purchase_qty &&
                        formik_subscriptionBundlePocketCoffee.errors.base_plan
                          ?.advance_foc?.minimum_bundle_purchase_qty
                          ? "p-invalid"
                          : ""
                      }`}
                      placeholder="Purchase Quantity"
                      value={
                        formik_subscriptionBundlePocketCoffee.values.base_plan
                          ?.advance_foc?.minimum_bundle_purchase_qty
                      }
                      onValueChange={(e) =>
                        formik_subscriptionBundlePocketCoffee.setFieldValue(
                          "base_plan.advance_foc.minimum_bundle_purchase_qty",
                          e.value,
                        )
                      }
                      onBlur={formik_subscriptionBundlePocketCoffee.handleBlur}
                    />
                    <ValidationError
                      className={
                        formik_subscriptionBundlePocketCoffee.touched.base_plan
                          ?.advance_foc?.minimum_bundle_purchase_qty &&
                        formik_subscriptionBundlePocketCoffee.errors.base_plan
                          ?.advance_foc?.minimum_bundle_purchase_qty
                          ? "block"
                          : "hidden"
                      }
                    >
                      {
                        formik_subscriptionBundlePocketCoffee.errors.base_plan
                          ?.advance_foc?.minimum_bundle_purchase_qty
                      }
                    </ValidationError>
                  </div>
                )}

                {focAdvanceItemDetailsPocketCoffee.length > 0 &&
                  focAdvanceItemDetailsPocketCoffee.map(
                    (group: any, index: any) => (
                      <div
                        key={index}
                        className={`grid grid-cols-1 md:grid-cols-2 gap-4 items-start ${
                          index !== focAdvanceItemDetailsPocketCoffee.length - 1
                            ? "border-b-2"
                            : ""
                        } ${index === 0 ? "pb-[30px]" : "py-[30px]"}`}
                      >
                        <div className="col-span-2">
                          <label
                            htmlFor={`advance_api_key_id_${index}`}
                            className="text-[16px] text-[#292929] font-[500] capitalize mb-2 block"
                          >
                            {index + 1}. Sources
                          </label>
                          <Dropdown
                            name={`base_plan.advance_foc.item_details[${index}].api_key_id`}
                            className={`w-full ${
                              formik_subscriptionBundlePocketCoffee.touched
                                .base_plan?.advance_foc?.item_details?.[index]
                                ?.api_key_id &&
                              (
                                formik_subscriptionBundlePocketCoffee.errors
                                  .base_plan?.advance_foc?.item_details?.[
                                  index
                                ] as any
                              )?.api_key_id
                                ? "p-invalid"
                                : ""
                            }`}
                            options={sourcesList}
                            inputId={`api_key_id_${index}`}
                            placeholder="Select Source"
                            value={
                              formik_subscriptionBundlePocketCoffee.values
                                .base_plan.advance_foc.item_details[index]
                                .api_key_id
                            }
                            onChange={(e) =>
                              handleInputChangeFocAdvanceItemPocketCoffee(
                                index,
                                "api_key_id",
                                e.value || 0,
                              )
                            }
                          />
                          <ValidationError
                            className={
                              formik_subscriptionBundlePocketCoffee.touched
                                .base_plan?.advance_foc?.item_details?.[index]
                                ?.api_key_id &&
                              (
                                formik_subscriptionBundlePocketCoffee.errors
                                  .base_plan?.advance_foc?.item_details?.[
                                  index
                                ] as any
                              )?.api_key_id &&
                              selectedAdvanceCategoriesPocketCoffee[index] !== 0
                                ? "block"
                                : "hidden"
                            }
                          >
                            {
                              (
                                formik_subscriptionBundlePocketCoffee.errors
                                  .base_plan?.advance_foc?.item_details?.[
                                  index
                                ] as any
                              )?.api_key_id
                            }
                          </ValidationError>
                        </div>
                        <div>
                          <label
                            htmlFor={`AdvanceFocItemCategoryPocketCoffee_${index}`}
                            className="text-[#292929] capitalize block mb-2"
                          >
                            {index + 1}. Gift Type
                          </label>
                          <Dropdown
                            className={`w-full ${
                              formik_subscriptionBundlePocketCoffee.touched
                                .base_plan?.advance_foc?.item_details?.[index]
                                ?.sub_category_id &&
                              (
                                formik_subscriptionBundlePocketCoffee.errors
                                  .base_plan?.advance_foc?.item_details?.[
                                  index
                                ] as any
                              )?.sub_category_id
                                ? "p-invalid"
                                : ""
                            }`}
                            options={freeGiftCategoryListPocketCoffee}
                            value={selectedAdvanceCategoriesPocketCoffee[index]}
                            placeholder="Select Gift Type"
                            onChange={(e) => {
                              const updatedCategories = [
                                ...selectedAdvanceCategoriesPocketCoffee,
                              ];
                              updatedCategories[index] = e.value;
                              setSelectedAdvanceCategoriesPocketCoffee(
                                updatedCategories,
                              );
                              // Clear the existing product_id and sub_category_id values when category changes
                              const clearedFocAdvanceItemDetailsPocketCoffee =
                                focAdvanceItemDetailsPocketCoffee.map(
                                  (item: any, i: any) => {
                                    if (i === index) {
                                      return {
                                        ...item,
                                        product_id: null,
                                        sub_category_id: 0,
                                      };
                                    }
                                    return item;
                                  },
                                );
                              setFocAdvanceItemDetailsPocketCoffee(
                                clearedFocAdvanceItemDetailsPocketCoffee,
                              );

                              formik_subscriptionBundlePocketCoffee.setFieldValue(
                                `base_plan.advance_foc.item_details[${index}].sub_category_id`,
                                e.value,
                              );

                              // Fetch data based on the new category selection
                              if (e.value === 1) {
                                getAdvanceProductListPocketCoffee(index);
                              } else if (e.value === 2) {
                                getAdvanceProductCategoryPocketCoffee(index);
                              } else {
                                const updatedProductLists = [
                                  ...freeGiftProductsListAdvancePocketCoffee,
                                ];
                                updatedProductLists[index] = [];
                                setFreeGiftProductsListAdvancePocketCoffee(
                                  updatedProductLists,
                                );
                              }
                            }}
                          />
                          <ValidationError
                            className={
                              formik_subscriptionBundlePocketCoffee.touched
                                .base_plan?.advance_foc?.item_details?.[index]
                                ?.sub_category_id &&
                              (
                                formik_subscriptionBundlePocketCoffee.errors
                                  .base_plan?.advance_foc?.item_details?.[
                                  index
                                ] as any
                              )?.sub_category_id &&
                              selectedAdvanceCategoriesPocketCoffee[index] !== 0
                                ? "block"
                                : "hidden"
                            }
                          >
                            {
                              (
                                formik_subscriptionBundlePocketCoffee.errors
                                  .base_plan?.advance_foc?.item_details?.[
                                  index
                                ] as any
                              )?.sub_category_id
                            }
                          </ValidationError>
                        </div>
                        <div>
                          <label
                            htmlFor={`FocItemPocketCoffee_${index}`}
                            className="text-[#292929] capitalize block mb-2"
                          >
                            {index + 1}. Free Gift
                          </label>
                          {/*  */}
                          <Dropdown
                            className={`w-full ${
                              formik_subscriptionBundlePocketCoffee.touched
                                .base_plan?.advance_foc?.item_details?.[index]
                                ?.product_id &&
                              (
                                formik_subscriptionBundlePocketCoffee.errors
                                  .base_plan?.advance_foc?.item_details?.[
                                  index
                                ] as any
                              )?.product_id
                                ? "p-invalid"
                                : ""
                            }`}
                            filter
                            options={
                              freeGiftProductsListAdvancePocketCoffee[index] ||
                              []
                            }
                            name={`advanceFocItemPocketCoffee_${index}`}
                            inputId={`advanceFocItemPocketCoffee_${index}`}
                            placeholder="Select Free Gift"
                            value={group.product_id || group.sub_category_id}
                            onChange={(e) => {
                              const selectedValue = e.value;
                              const selectedItem = (
                                freeGiftProductsListAdvancePocketCoffee[
                                  index
                                ] || []
                              ).find(
                                (item: any) => item.value === selectedValue,
                              );

                              if (selectedItem) {
                                const updatedFocAdvanceItemDetails =
                                  focAdvanceItemDetailsPocketCoffee.map(
                                    (item: any, i: any) => {
                                      if (i === index) {
                                        if (
                                          selectedAdvanceCategoriesPocketCoffee[
                                            index
                                          ] === 1
                                        ) {
                                          // If category is 1, set both sub_category_id and product_id
                                          return {
                                            ...item,
                                            sub_category_id:
                                              selectedItem.sub_category_id,
                                            product_id: selectedValue,
                                          };
                                        } else if (
                                          selectedAdvanceCategoriesPocketCoffee[
                                            index
                                          ] === 2
                                        ) {
                                          // If category is 2, only set sub_category_id and clear product_id
                                          return {
                                            ...item,
                                            sub_category_id:
                                              selectedItem.sub_category_id,
                                            product_id: 0,
                                          };
                                        }
                                      }
                                      return item;
                                    },
                                  );
                                setFocAdvanceItemDetailsPocketCoffee(
                                  updatedFocAdvanceItemDetails,
                                );
                                if (
                                  selectedAdvanceCategoriesPocketCoffee[
                                    index
                                  ] === 1
                                ) {
                                  formik_subscriptionBundlePocketCoffee.setFieldValue(
                                    `base_plan.advance_foc.item_details[${index}].product_id`,
                                    e.value,
                                  );
                                  formik_subscriptionBundlePocketCoffee.setFieldValue(
                                    `base_plan.advance_foc.item_details[${index}].sub_category_id`,
                                    selectedItem?.sub_category_id,
                                  );
                                }
                                if (
                                  selectedAdvanceCategoriesPocketCoffee[
                                    index
                                  ] === 2
                                ) {
                                  formik_subscriptionBundlePocketCoffee.setFieldValue(
                                    `base_plan.advance_foc.item_details[${index}].product_id`,
                                    0,
                                  );
                                  formik_subscriptionBundlePocketCoffee.setFieldValue(
                                    `base_plan.advance_foc.item_details[${index}].sub_category_id`,
                                    selectedItem?.sub_category_id,
                                  );
                                }
                              }
                            }}
                            optionLabel="label"
                            optionValue="value"
                            itemTemplate={(option) => (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {option.image_url && (
                                  <img
                                    src={option.image_url}
                                    alt={option.label}
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      marginRight: "10px",
                                    }}
                                  />
                                )}
                                <span>{option.label}</span>
                              </div>
                            )}
                          />
                          <ValidationError
                            className={`w-full ${
                              formik_subscriptionBundlePocketCoffee.touched
                                .base_plan?.advance_foc?.item_details?.[index]
                                ?.product_id &&
                              (
                                formik_subscriptionBundlePocketCoffee.errors
                                  .base_plan?.advance_foc?.item_details?.[
                                  index
                                ] as any
                              )?.product_id
                                ? "block"
                                : "hidden"
                            }`}
                          >
                            {
                              (
                                formik_subscriptionBundlePocketCoffee.errors
                                  .base_plan?.advance_foc?.item_details?.[
                                  index
                                ] as any
                              )?.product_id
                            }
                          </ValidationError>
                        </div>
                        <div>
                          <label
                            htmlFor={`AdvanceFocItemQtyPocketCoffee_${index}`}
                            className="text-[#292929] capitalize block mb-2"
                          >
                            {index + 1}. Quantity
                          </label>
                          <InputNumber
                            value={group.quantity}
                            onChange={(e) =>
                              handleInputChangeFocAdvanceItemPocketCoffee(
                                index,
                                "quantity",
                                e.value || 0,
                              )
                            }
                            className={`w-full ${
                              formik_subscriptionBundlePocketCoffee.touched
                                .base_plan?.advance_foc?.item_details?.[index]
                                ?.quantity &&
                              (
                                formik_subscriptionBundlePocketCoffee.errors
                                  .base_plan?.advance_foc?.item_details?.[
                                  index
                                ] as any
                              )?.quantity
                                ? "p-invalid"
                                : ""
                            }`}
                          />
                          <ValidationError
                            className={
                              formik_subscriptionBundlePocketCoffee.touched
                                .base_plan?.advance_foc?.item_details?.[index]
                                ?.quantity &&
                              (
                                formik_subscriptionBundlePocketCoffee.errors
                                  .base_plan?.advance_foc?.item_details?.[
                                  index
                                ] as any
                              )?.quantity
                                ? "block"
                                : "hidden"
                            }
                          >
                            {
                              (
                                formik_subscriptionBundlePocketCoffee.errors
                                  .base_plan?.advance_foc?.item_details?.[
                                  index
                                ] as any
                              )?.quantity
                            }
                          </ValidationError>
                        </div>
                        <div>
                          <label
                            htmlFor={`AdvanceFocItemFreeGiftNoPocketCoffee_${index}`}
                            className="text-[#292929] capitalize block mb-2"
                          >
                            Free Gift Group Number
                          </label>
                          <InputNumber
                            id={`AdvanceFocItemFreeGiftNoPocketCoffee_${index}`}
                            value={group.freegift_no ?? 0}
                            onValueChange={(e) => {
                              const updatedFocAdvanceItemDetailsPocketCoffee =
                                focAdvanceItemDetailsPocketCoffee.map(
                                  (item: any, i: any) => {
                                    if (i === index) {
                                      return {
                                        ...item,
                                        freegift_no: e.value ?? 0,
                                      };
                                    }
                                    return item;
                                  },
                                );

                              setFocAdvanceItemDetailsPocketCoffee(
                                updatedFocAdvanceItemDetailsPocketCoffee,
                              );

                              formik_subscriptionBundlePocketCoffee.setFieldValue(
                                `base_plan.advance_foc.item_details[${index}].freegift_no`,
                                e.value ?? 0,
                              );
                            }}
                            className={`w-full ${
                              formik_subscriptionBundlePocketCoffee.touched
                                .base_plan?.advance_foc?.item_details?.[index]
                                ?.freegift_no &&
                              (
                                formik_subscriptionBundlePocketCoffee.errors
                                  .base_plan?.advance_foc?.item_details?.[
                                  index
                                ] as any
                              )?.freegift_no
                                ? "p-invalid"
                                : ""
                            }`}
                          />
                          <ValidationError
                            className={`w-full ${
                              formik_subscriptionBundlePocketCoffee.touched
                                .base_plan?.advance_foc?.item_details?.[index]
                                ?.freegift_no &&
                              (
                                formik_subscriptionBundlePocketCoffee.errors
                                  .base_plan?.advance_foc?.item_details?.[
                                  index
                                ] as any
                              )?.freegift_no
                                ? "block"
                                : "hidden"
                            }`}
                          >
                            {
                              (
                                formik_subscriptionBundlePocketCoffee.errors
                                  .base_plan?.advance_foc?.item_details?.[
                                  index
                                ] as any
                              )?.freegift_no
                            }
                          </ValidationError>
                        </div>
                        <div>
                          <Checkbox
                            inputId={`optionalAdvanceCheckboxPocketCoffee_${index}`}
                            checked={group.optional === 1}
                            onChange={(e) => {
                              const updatedFocAdvanceItemDetailsPocketCoffee =
                                focAdvanceItemDetailsPocketCoffee.map(
                                  (item: any, i: any) => {
                                    if (i === index) {
                                      return {
                                        ...item,
                                        optional: e.checked ? 1 : 0,
                                      };
                                    }
                                    return item;
                                  },
                                );

                              setFocAdvanceItemDetailsPocketCoffee(
                                updatedFocAdvanceItemDetailsPocketCoffee,
                              );

                              formik_subscriptionBundlePocketCoffee.setFieldValue(
                                `base_plan.advance_foc.item_details[${index}].optional`,
                                e.checked ? 1 : 0,
                              );
                            }}
                          />
                          <label
                            htmlFor={`optionalAdvanceCheckboxPocketCoffee_${index}`}
                            className="ml-2"
                          >
                            Optional
                          </label>
                        </div>
                        <div className={`mt-auto}`}>
                          <button
                            onClick={() =>
                              removeFocAdvanceItemGroupPocketCoffee(index)
                            }
                            type="button"
                            className="rounded-[10px] bg-red-500 text-white py-[9px] px-[24px] w-fit"
                          >
                            {focAdvanceItemDetailsPocketCoffee.length === 1
                              ? "Disable Advance Gift"
                              : `Remove Advance Gift`}
                          </button>
                        </div>
                      </div>
                    ),
                  )}
                <button
                  type="button"
                  onClick={addFocAdvanceItemGroupPocketCoffee}
                  className="rounded-[10px] bg-[#82d7d2] py-[9px] px-[24px]"
                >
                  {focAdvanceItemDetailsPocketCoffee.length > 0
                    ? "Add Another Advance Gift"
                    : "Enable Advance Gift"}
                </button>
              </Fieldset>
            </div>
          </div>
        </div>
        <div className="bg-[#f7f3f3] p-4 rounded-[7px] mt-[20px]">
          <Fieldset legend="Add On Option" toggleable>
            {formik_subscriptionBundlePocketCoffee.values.add_on_plans?.map(
              (plan: any, planIndex: any) => (
                <div
                  key={planIndex}
                  className={` ${
                    planIndex !==
                    formik_subscriptionBundlePocketCoffee.values.add_on_plans
                      .length -
                      1
                      ? "border-b-2"
                      : ""
                  }
            ${planIndex === 0 ? "pb-[30px]" : "py-[30px]"}
`}
                >
                  {isRole(["Admin"]) && (
                    <div className="grid md:grid-cols-3 xl:grid-cols-4 w-full gap-4">
                      <div className="order-5 md:order-6 xl:order-5">
                        <label
                          htmlFor={`add_on_plans[${planIndex}].add_on_name`}
                          className="text-[#292929] capitalize block mb-2"
                        >
                          {planIndex + 1}. Add On Name
                        </label>
                        <InputText
                          id={`add_on_plans[${planIndex}].add_on_name`}
                          className={`w-full ${
                            formik_subscriptionBundlePocketCoffee.touched
                              ?.add_on_plans?.[planIndex]?.add_on_name &&
                            (
                              formik_subscriptionBundlePocketCoffee.errors
                                .add_on_plans?.[planIndex] as any
                            )?.add_on_name
                              ? "p-invalid"
                              : ""
                          }`}
                          placeholder="Add On Name"
                          value={plan.add_on_name || ""}
                          onChange={
                            formik_subscriptionBundlePocketCoffee.handleChange
                          }
                          onBlur={
                            formik_subscriptionBundlePocketCoffee.handleBlur
                          }
                        />

                        <ValidationError
                          className={
                            formik_subscriptionBundlePocketCoffee.touched
                              .add_on_plans?.[planIndex]?.add_on_name &&
                            (
                              formik_subscriptionBundlePocketCoffee.errors
                                .add_on_plans?.[planIndex] as any
                            )?.add_on_name
                              ? "block"
                              : "hidden"
                          }
                        >
                          {(
                            formik_subscriptionBundlePocketCoffee.errors
                              .add_on_plans?.[planIndex] as any
                          )?.add_on_name || ""}
                        </ValidationError>
                      </div>
                      <div className="order-6 md:order-7 xl:order-6">
                        <label
                          htmlFor={`add_on_plans[${planIndex}].cost_price`}
                          className="text-[#292929] capitalize block mb-2"
                        >
                          {planIndex + 1}. Cost Price
                        </label>
                        <InputNumber
                          name={`add_on_plans[${planIndex}].cost_price`}
                          id={`add_on_plans[${planIndex}].cost_price`}
                          className={`w-full ${
                            formik_subscriptionBundlePocketCoffee.touched
                              ?.add_on_plans?.[planIndex]?.cost_price &&
                            (
                              formik_subscriptionBundlePocketCoffee.errors
                                .add_on_plans?.[planIndex] as any
                            )?.cost_price
                              ? "p-invalid"
                              : ""
                          }`}
                          placeholder="Cost Price"
                          value={plan.cost_price || ""}
                          onValueChange={(e) =>
                            formik_subscriptionBundlePocketCoffee.setFieldValue(
                              `add_on_plans[${planIndex}].cost_price`,
                              e.value,
                            )
                          }
                          onBlur={
                            formik_subscriptionBundlePocketCoffee.handleBlur
                          }
                          prefix="$ "
                          minFractionDigits={2}
                        />
                        <ValidationError
                          className={
                            formik_subscriptionBundlePocketCoffee.touched
                              .add_on_plans?.[planIndex]?.cost_price &&
                            (
                              formik_subscriptionBundlePocketCoffee.errors
                                .add_on_plans?.[planIndex] as any
                            )?.cost_price
                              ? "block"
                              : "hidden"
                          }
                        >
                          {
                            (
                              formik_subscriptionBundlePocketCoffee.errors
                                .add_on_plans?.[planIndex] as any
                            )?.cost_price
                          }
                        </ValidationError>
                      </div>

                      <div className="flex-1 order-7 md:order-6 xl:order-5">
                        <label
                          htmlFor={`add_on_plans[${planIndex}].price`}
                          className="text-[#292929] capitalize block mb-2"
                        >
                          {planIndex + 1}. Selling Price
                        </label>
                        <InputNumber
                          name={`add_on_plans[${planIndex}].price`}
                          id={`add_on_plans[${planIndex}].price`}
                          className={`w-full ${
                            formik_subscriptionBundlePocketCoffee.touched
                              ?.add_on_plans?.[planIndex]?.price &&
                            (
                              formik_subscriptionBundlePocketCoffee.errors
                                .add_on_plans?.[planIndex] as any
                            )?.price
                              ? "p-invalid"
                              : ""
                          }`}
                          placeholder="Selling Price"
                          value={plan.price || ""}
                          onValueChange={(e) =>
                            formik_subscriptionBundlePocketCoffee.setFieldValue(
                              `add_on_plans[${planIndex}].price`,
                              e.value,
                            )
                          }
                          onBlur={
                            formik_subscriptionBundlePocketCoffee.handleBlur
                          }
                          prefix="$ "
                          minFractionDigits={2}
                        />
                        <ValidationError
                          className={
                            formik_subscriptionBundlePocketCoffee.touched
                              .add_on_plans?.[planIndex]?.price &&
                            (
                              formik_subscriptionBundlePocketCoffee.errors
                                .add_on_plans?.[planIndex] as any
                            )?.price
                              ? "block"
                              : "hidden"
                          }
                        >
                          {
                            (
                              formik_subscriptionBundlePocketCoffee.errors
                                .add_on_plans?.[planIndex] as any
                            )?.price
                          }
                        </ValidationError>
                      </div>
                      <div className="flex-1 order-7 md:order-8 xl:order-7">
                        <label
                          htmlFor={`add_on_plans[${planIndex}].pocket_coffee_boxes`}
                          className="text-[#292929] capitalize block mb-2"
                        >
                          {planIndex + 1}. Pocket Coffee Boxes
                        </label>
                        <InputNumber
                          name={`add_on_plans[${planIndex}].pocket_coffee_boxes`}
                          id={`add_on_plans[${planIndex}].pocket_coffee_boxes`}
                          className={`w-full ${
                            formik_subscriptionBundlePocketCoffee.touched
                              ?.add_on_plans?.[planIndex]
                              ?.pocket_coffee_boxes &&
                            (
                              formik_subscriptionBundlePocketCoffee.errors
                                .add_on_plans?.[planIndex] as any
                            )?.pocket_coffee_boxes
                              ? "p-invalid"
                              : ""
                          }`}
                          placeholder="Pocket Coffee Boxes"
                          value={plan.pocket_coffee_boxes || ""}
                          onValueChange={(e) =>
                            formik_subscriptionBundlePocketCoffee.setFieldValue(
                              `add_on_plans[${planIndex}].pocket_coffee_boxes`,
                              e.value,
                            )
                          }
                          onBlur={
                            formik_subscriptionBundlePocketCoffee.handleBlur
                          }
                        />
                        <ValidationError
                          className={
                            formik_subscriptionBundlePocketCoffee.touched
                              .add_on_plans?.[planIndex]?.pocket_coffee_boxes &&
                            (
                              formik_subscriptionBundlePocketCoffee.errors
                                .add_on_plans?.[planIndex] as any
                            )?.pocket_coffee_boxes
                              ? "block"
                              : "hidden"
                          }
                        >
                          {
                            (
                              formik_subscriptionBundlePocketCoffee.errors
                                .add_on_plans?.[planIndex] as any
                            )?.pocket_coffee_boxes
                          }
                        </ValidationError>
                      </div>
                      {/* <div className="order-9 col-span-1 md:col-span-4">
                    <Panel
                      header={
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {`${
                            planIndex + 1
                          }. Total Beans Required - ${calculateTotalAddOnRequiredBeans(
                            plan.item_details,
                          )}`}
                          <ValidationError
                            className={`!p-0 ${
                              formik_subscriptionBundlePocketCoffee
                                .touched.add_on_plans?.[planIndex]
                                ?.total_beans &&
                              (
                                formik_subscriptionBundlePocketCoffee
                                  .errors.add_on_plans?.[
                                  planIndex
                                ] as any
                              )?.total_beans
                                ? "block ml-2"
                                : "hidden"
                            }`}
                          >
                            {
                              (
                                formik_subscriptionBundlePocketCoffee
                                  .errors.add_on_plans?.[
                                  planIndex
                                ] as any
                              )?.total_beans
                            }
                          </ValidationError>
                        </div>
                      }
                    >
                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                        {plan.item_details.map(
                          (item: any, itemIndex: any) => {
                            // Use sub_category_id to determine the label
                            const label =
                              subCategoryIdToLabel[
                                item.sub_category_id as SubCategoryID
                              ] || item.eg;

                            return (
                              <BeanInputOptional
                                key={`item-${planIndex}-${itemIndex}`}
                                inputId={`bean-input-${planIndex}-${itemIndex}`}
                                inputName={label.toLowerCase()}
                                label={label}
                                checkBoxValue={`optional_${label.toLowerCase()}`}
                                inputValue={item.quantity}
                                beansOptional={Boolean(
                                  item.optional,
                                )}
                                onBeansOptionalChange={(value) =>
                                  onBeansAddOnOptionalChange(
                                    planIndex,
                                    itemIndex,
                                    Boolean(value),
                                  )
                                }
                                handleInputOnChange={(value) => {
                                  formik_subscriptionBundlePocketCoffee.setFieldValue(
                                    `add_on_plans[${planIndex}].item_details[${itemIndex}].quantity`,
                                    value,
                                  );
                                }}
                              />
                            );
                          },
                        )}
                      </div>
                    </Panel>
                  </div> */}
                      <div className="order-10 col-span-1 md:col-span-4">
                        <Fieldset legend="Free Gift" toggleable>
                          {focAddOnItemDetailsPocketCoffee[planIndex]?.map(
                            (group: any, index: any) => (
                              <div
                                key={index}
                                className={`grid grid-cols-1 md:grid-cols-2 gap-4 items-start ${
                                  index !==
                                  focAddOnItemDetailsPocketCoffee[planIndex]
                                    ?.length -
                                    1
                                    ? "border-b-2"
                                    : ""
                                } ${index === 0 ? "pb-[30px]" : "py-[30px]"}
`}
                              >
                                <div className="col-span-2">
                                  <label
                                    htmlFor={`add_on_api_key_id_${index}`}
                                    className="text-[16px] text-[#292929] font-[500] capitalize mb-2 block"
                                  >
                                    {index + 1}. Sources
                                  </label>
                                  <Dropdown
                                    name={`add_on_plans.foc.item_details[${index}].api_key_id`}
                                    className={`w-full ${
                                      formik_subscriptionBundlePocketCoffee
                                        .touched.add_on_plans?.[planIndex]?.foc
                                        ?.item_details?.[index]?.api_key_id &&
                                      (
                                        formik_subscriptionBundlePocketCoffee
                                          .errors.add_on_plans as any
                                      )?.[planIndex]?.foc?.item_details?.[index]
                                        ?.api_key_id
                                        ? "p-invalid"
                                        : ""
                                    }`}
                                    options={sourcesList}
                                    inputId={`api_key_id_${index}`}
                                    placeholder="Select Source"
                                    value={
                                      formik_subscriptionBundlePocketCoffee
                                        .values.add_on_plans?.[planIndex]?.foc
                                        ?.item_details?.[index]?.api_key_id
                                    }
                                    onChange={(e) =>
                                      handleInputChangeFocAddOnItemPocketCoffee(
                                        planIndex,
                                        index,
                                        "api_key_id",
                                        e.value || 0,
                                      )
                                    }
                                  />
                                  <ValidationError
                                    className={`${
                                      formik_subscriptionBundlePocketCoffee
                                        .touched.add_on_plans?.[planIndex]?.foc
                                        ?.item_details?.[index]?.api_key_id &&
                                      (
                                        formik_subscriptionBundlePocketCoffee
                                          .errors.add_on_plans as any
                                      )?.[planIndex]?.foc?.item_details?.[index]
                                        ?.api_key_id
                                        ? "block"
                                        : "hidden"
                                    }`}
                                  >
                                    {
                                      (
                                        formik_subscriptionBundlePocketCoffee
                                          .errors.add_on_plans as any
                                      )?.[planIndex]?.foc?.item_details?.[index]
                                        ?.api_key_id
                                    }
                                  </ValidationError>
                                </div>
                                <div>
                                  <label
                                    htmlFor={`FocItemCategoryPocketCoffee_${index}`}
                                    className="text-[#292929] capitalize block mb-2"
                                  >
                                    {index + 1}. Gift Type
                                  </label>
                                  <Dropdown
                                    className={`w-full ${
                                      formik_subscriptionBundlePocketCoffee
                                        .touched.add_on_plans?.[planIndex]?.foc
                                        ?.item_details?.[index]
                                        ?.sub_category_id &&
                                      (
                                        formik_subscriptionBundlePocketCoffee
                                          .errors.add_on_plans as any
                                      )?.[planIndex]?.foc?.item_details?.[index]
                                        ?.sub_category_id
                                        ? "p-invalid"
                                        : ""
                                    }`}
                                    options={freeGiftCategoryListPocketCoffee}
                                    value={
                                      (
                                        selectedAddOnCategoriesPocketCoffee as any
                                      )[planIndex]?.[index] ?? ""
                                    }
                                    placeholder="Select Gift Type"
                                    onChange={(e) => {
                                      const updatedAddOnCategoriesPocketCoffee: any =
                                        {
                                          ...selectedAddOnCategoriesPocketCoffee,
                                        };

                                      if (
                                        !updatedAddOnCategoriesPocketCoffee[
                                          planIndex
                                        ]
                                      ) {
                                        updatedAddOnCategoriesPocketCoffee[
                                          planIndex
                                        ] = [];
                                      }
                                      updatedAddOnCategoriesPocketCoffee[
                                        planIndex
                                      ][index] = e.value;
                                      setSelectedAddOnCategoriesPocketCoffee(
                                        updatedAddOnCategoriesPocketCoffee,
                                      );

                                      const updatedFocAddOnItemDetailsPocketCoffee: any =
                                        {
                                          ...focAddOnItemDetailsPocketCoffee,
                                        };
                                      if (
                                        !updatedFocAddOnItemDetailsPocketCoffee[
                                          planIndex
                                        ]
                                      ) {
                                        updatedFocAddOnItemDetailsPocketCoffee[
                                          planIndex
                                        ] = [];
                                      }
                                      if (
                                        !updatedFocAddOnItemDetailsPocketCoffee[
                                          planIndex
                                        ][index]
                                      ) {
                                        updatedFocAddOnItemDetailsPocketCoffee[
                                          planIndex
                                        ][index] = {
                                          sub_category_id: 0,
                                          product_id: null,
                                          quantity: 0,
                                        };
                                      }
                                      updatedFocAddOnItemDetailsPocketCoffee[
                                        planIndex
                                      ][index].sub_category_id = e.value;

                                      // Reset product_id when category is changed
                                      updatedFocAddOnItemDetailsPocketCoffee[
                                        planIndex
                                      ][index].product_id = null;
                                      updatedFocAddOnItemDetailsPocketCoffee[
                                        planIndex
                                      ][index].sub_category_id = 0;
                                      setFocAddOnItemDetailsPocketCoffee(
                                        updatedFocAddOnItemDetailsPocketCoffee,
                                      );

                                      formik_subscriptionBundlePocketCoffee.setFieldValue(
                                        `add_on_plans[${planIndex}].foc.item_details[${index}].sub_category_id`,
                                        e.value,
                                      );

                                      if (e.value === 1) {
                                        getAddOnProductListPocketCoffee(
                                          planIndex,
                                          index,
                                        );
                                      } else if (e.value === 2) {
                                        getAddOnProductCategoryPocketCoffee(
                                          planIndex,
                                          index,
                                        );
                                      } else {
                                        const updatedProductListsPocketCoffee: any =
                                          {
                                            ...freeGiftAddOnProductsListPocketCoffee,
                                          };
                                        if (
                                          !updatedProductListsPocketCoffee[
                                            planIndex
                                          ]
                                        ) {
                                          updatedProductListsPocketCoffee[
                                            planIndex
                                          ] = [];
                                        }
                                        updatedProductListsPocketCoffee[
                                          planIndex
                                        ][index] = [];
                                        setFreeGiftAddOnProductsListPocketCoffee(
                                          updatedProductListsPocketCoffee,
                                        );
                                      }
                                    }}
                                  />
                                  <ValidationError
                                    className={`${
                                      formik_subscriptionBundlePocketCoffee
                                        .touched.add_on_plans?.[planIndex]?.foc
                                        ?.item_details?.[index]
                                        ?.sub_category_id &&
                                      (
                                        formik_subscriptionBundlePocketCoffee
                                          .errors.add_on_plans as any
                                      )?.[planIndex]?.foc?.item_details?.[index]
                                        ?.sub_category_id
                                        ? "block"
                                        : "hidden"
                                    }`}
                                  >
                                    {
                                      (
                                        formik_subscriptionBundlePocketCoffee
                                          .errors.add_on_plans as any
                                      )?.[planIndex]?.foc?.item_details?.[index]
                                        ?.sub_category_id
                                    }
                                  </ValidationError>
                                </div>
                                <div>
                                  <label
                                    htmlFor={`FocItemPocketCoffee_${index}`}
                                    className="text-[#292929] capitalize block mb-2"
                                  >
                                    {index + 1}. Free Gift
                                  </label>
                                  <Dropdown
                                    className={`w-full ${
                                      formik_subscriptionBundlePocketCoffee
                                        .touched.add_on_plans?.[planIndex]?.foc
                                        ?.item_details?.[index]?.product_id &&
                                      (
                                        formik_subscriptionBundlePocketCoffee
                                          .errors.add_on_plans as any
                                      )?.[planIndex]?.foc?.item_details?.[index]
                                        ?.product_id
                                        ? "p-invalid"
                                        : ""
                                    }`}
                                    filter
                                    options={
                                      freeGiftAddOnProductsListPocketCoffee[
                                        planIndex
                                      ]?.[index] ?? []
                                    }
                                    name={`focItem_${index}`}
                                    inputId={`focItem_${index}`}
                                    placeholder="Select Free Gift"
                                    value={
                                      group.product_id || group.sub_category_id
                                    }
                                    onChange={(e) => {
                                      const updatedFocAddOnItemDetailsPocketCoffee =
                                        {
                                          ...focAddOnItemDetailsPocketCoffee,
                                        };
                                      const selectedAddOnProductsId = e.value;

                                      const selectedAddOnProductsPocketCoffee =
                                        freeGiftAddOnProductsListPocketCoffee[
                                          planIndex
                                        ]?.[index]?.find(
                                          (product: any) =>
                                            product.value ===
                                            selectedAddOnProductsId,
                                        );

                                      if (
                                        !updatedFocAddOnItemDetailsPocketCoffee[
                                          planIndex
                                        ]
                                      ) {
                                        updatedFocAddOnItemDetailsPocketCoffee[
                                          planIndex
                                        ] = [];
                                      }

                                      if (
                                        !updatedFocAddOnItemDetailsPocketCoffee[
                                          planIndex
                                        ][index]
                                      ) {
                                        updatedFocAddOnItemDetailsPocketCoffee[
                                          planIndex
                                        ][index] = {};
                                      }

                                      const targetGroup =
                                        updatedFocAddOnItemDetailsPocketCoffee[
                                          planIndex
                                        ][index];
                                      targetGroup.sub_category_id =
                                        selectedAddOnProductsPocketCoffee?.sub_category_id ||
                                        0;
                                      targetGroup.eg =
                                        selectedAddOnProductsPocketCoffee?.label ||
                                        "";
                                      // Check if selectedAddOnCategories[planIndex][index] is 2 and set product_id to 0
                                      if (
                                        (
                                          selectedAddOnCategoriesPocketCoffee[
                                            planIndex
                                          ] as any
                                        )[index] === 2
                                      ) {
                                        targetGroup.product_id = 0;
                                        formik_subscriptionBundlePocketCoffee.setFieldValue(
                                          `add_on_plans[${planIndex}].foc.item_details[${index}].product_id`,
                                          targetGroup.product_id,
                                        );
                                        formik_subscriptionBundlePocketCoffee.setFieldValue(
                                          `add_on_plans[${planIndex}].foc.item_details[${index}].sub_category_id`,
                                          targetGroup.sub_category_id,
                                        );
                                      } else {
                                        targetGroup.product_id =
                                          selectedAddOnProductsId;
                                        formik_subscriptionBundlePocketCoffee.setFieldValue(
                                          `add_on_plans[${planIndex}].foc.item_details[${index}].product_id`,
                                          e.value,
                                        );
                                        formik_subscriptionBundlePocketCoffee.setFieldValue(
                                          `add_on_plans[${planIndex}].foc.item_details[${index}].sub_category_id`,
                                          targetGroup.sub_category_id,
                                        );
                                      }

                                      setFocAddOnItemDetailsPocketCoffee(
                                        updatedFocAddOnItemDetailsPocketCoffee,
                                      );
                                    }}
                                    optionLabel="label"
                                    optionValue="value"
                                    itemTemplate={(option) => (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        {option.image_url && (
                                          <img
                                            src={option.image_url}
                                            alt={option.label}
                                            style={{
                                              width: "30px",
                                              height: "30px",
                                              marginRight: "10px",
                                            }}
                                          />
                                        )}
                                        <span>{option.label}</span>
                                      </div>
                                    )}
                                  />
                                  <ValidationError
                                    className={`${
                                      (formik_subscriptionBundlePocketCoffee
                                        .touched.add_on_plans?.[planIndex]?.foc
                                        ?.item_details?.[index]?.product_id &&
                                        (
                                          formik_subscriptionBundlePocketCoffee
                                            .errors.add_on_plans as any
                                        )?.[planIndex]?.foc?.item_details?.[
                                          index
                                        ]?.product_id) ||
                                      ((
                                        formik_subscriptionBundlePocketCoffee
                                          .errors.add_on_plans as any
                                      )?.[planIndex]?.foc?.item_details &&
                                        typeof (
                                          formik_subscriptionBundlePocketCoffee
                                            .errors.add_on_plans as any
                                        )?.[planIndex]?.foc?.item_details ===
                                          "string")
                                        ? "block"
                                        : "hidden"
                                    }`}
                                  >
                                    {formik_subscriptionBundlePocketCoffee
                                      .touched.add_on_plans?.[planIndex]?.foc
                                      ?.item_details?.[index]?.product_id &&
                                      ((
                                        formik_subscriptionBundlePocketCoffee
                                          .errors.add_on_plans as any
                                      )?.[planIndex]?.foc?.item_details?.[index]
                                        ?.product_id ||
                                        ((
                                          formik_subscriptionBundlePocketCoffee
                                            .errors.add_on_plans as any
                                        )?.[planIndex]?.foc?.item_details &&
                                        typeof (
                                          formik_subscriptionBundlePocketCoffee
                                            .errors.add_on_plans as any
                                        )?.[planIndex]?.foc?.item_details ===
                                          "string"
                                          ? (
                                              formik_subscriptionBundlePocketCoffee
                                                .errors.add_on_plans as any
                                            )?.[planIndex]?.foc?.item_details
                                          : ""))}
                                  </ValidationError>
                                </div>

                                <div>
                                  <label
                                    htmlFor={`FocItemQtyPocketCoffee_${index}`}
                                    className="text-[#292929] capitalize block mb-2"
                                  >
                                    {index + 1}. Quantity
                                  </label>
                                  <InputNumber
                                    value={group.quantity}
                                    onChange={(e) =>
                                      handleInputChangeFocAddOnItemPocketCoffee(
                                        planIndex, // You'll need to have this value available
                                        index, // You'll need to have this value available
                                        "quantity",
                                        e.value || 0,
                                      )
                                    }
                                    className={`w-full ${
                                      formik_subscriptionBundlePocketCoffee
                                        .touched.add_on_plans?.[planIndex]?.foc
                                        ?.item_details?.[index]?.quantity &&
                                      (
                                        formik_subscriptionBundlePocketCoffee
                                          .errors.add_on_plans as any
                                      )?.[planIndex]?.foc?.item_details?.[index]
                                        ?.quantity
                                        ? "p-invalid"
                                        : ""
                                    }`}
                                  />
                                  <ValidationError
                                    className={`${
                                      formik_subscriptionBundlePocketCoffee
                                        .touched.add_on_plans?.[planIndex]?.foc
                                        ?.item_details?.[index]?.quantity &&
                                      (
                                        formik_subscriptionBundlePocketCoffee
                                          .errors.add_on_plans as any
                                      )?.[planIndex]?.foc?.item_details?.[index]
                                        ?.quantity
                                        ? "block"
                                        : "hidden"
                                    }`}
                                  >
                                    {
                                      (
                                        formik_subscriptionBundlePocketCoffee
                                          .errors.add_on_plans as any
                                      )?.[planIndex]?.foc?.item_details?.[index]
                                        ?.quantity
                                    }
                                  </ValidationError>
                                </div>
                                <div>
                                  <label
                                    htmlFor={`FocItemQty_${index}`}
                                    className="text-[#292929] capitalize block mb-2"
                                  >
                                    Free Gift Group Number
                                  </label>
                                  <InputNumber
                                    value={group.freegift_no ?? 0}
                                    onChange={(e) =>
                                      handleInputChangeFocAddOnItemPocketCoffee(
                                        planIndex, // You'll need to have this value available
                                        index, // You'll need to have this value available
                                        "freegift_no",
                                        e.value || 0,
                                      )
                                    }
                                    className={`w-full ${
                                      formik_subscriptionBundlePocketCoffee
                                        .touched.add_on_plans?.[planIndex]?.foc
                                        ?.item_details?.[index]?.freegift_no &&
                                      (
                                        formik_subscriptionBundlePocketCoffee
                                          .errors.add_on_plans as any
                                      )?.[planIndex]?.foc?.item_details?.[index]
                                        ?.freegift_no
                                        ? "p-invalid"
                                        : ""
                                    }`}
                                  />
                                  <ValidationError
                                    className={`${
                                      formik_subscriptionBundlePocketCoffee
                                        .touched.add_on_plans?.[planIndex]?.foc
                                        ?.item_details?.[index]?.freegift_no &&
                                      (
                                        formik_subscriptionBundlePocketCoffee
                                          .errors.add_on_plans as any
                                      )?.[planIndex]?.foc?.item_details?.[index]
                                        ?.freegift_no
                                        ? "block"
                                        : "hidden"
                                    }`}
                                  >
                                    {
                                      (
                                        formik_subscriptionBundlePocketCoffee
                                          .errors.add_on_plans as any
                                      )?.[planIndex]?.foc?.item_details?.[index]
                                        ?.freegift_no
                                    }
                                  </ValidationError>
                                </div>
                                <div>
                                  <Checkbox
                                    inputId={`optionalCheckboxPocketCoffee_${planIndex}_${index}`}
                                    checked={
                                      focAddOnItemDetailsPocketCoffee[
                                        planIndex
                                      ]?.[index]?.optional === 1
                                    }
                                    onChange={(e) => {
                                      const updatedFocAddOnItemDetailsPocketCoffee =
                                        {
                                          ...focAddOnItemDetailsPocketCoffee,
                                        };
                                      if (
                                        !updatedFocAddOnItemDetailsPocketCoffee[
                                          planIndex
                                        ][index]
                                      ) {
                                        updatedFocAddOnItemDetailsPocketCoffee[
                                          planIndex
                                        ][index] = {
                                          sub_category_id: 0,
                                          product_id: null,
                                          quantity: 0,
                                          optional: 0,
                                          freegift_no: 0,
                                        };
                                      }
                                      updatedFocAddOnItemDetailsPocketCoffee[
                                        planIndex
                                      ][index].optional = e.checked ? 1 : 0;

                                      setFocAddOnItemDetailsPocketCoffee(
                                        updatedFocAddOnItemDetailsPocketCoffee,
                                      );

                                      formik_subscriptionBundlePocketCoffee.setFieldValue(
                                        `add_on_plans[${planIndex}].foc.item_details[${index}].optional`,
                                        e.checked ? 1 : 0,
                                      );
                                    }}
                                  />
                                  <label
                                    htmlFor={`optionalCheckbox_${planIndex}_${index}`}
                                    className="ml-2"
                                  >
                                    Optional
                                  </label>
                                </div>

                                <div className={`mt-auto`}>
                                  <button
                                    onClick={() =>
                                      removeFocAddOnItemGroupPocketCoffee(
                                        planIndex,
                                        index,
                                      )
                                    }
                                    type="button"
                                    className="rounded-[10px] bg-red-500 text-white py-[9px] px-[24px] w-fit"
                                  >
                                    {focAddOnItemDetailsPocketCoffee[planIndex]
                                      ?.length === 1
                                      ? "Disable Free Gift"
                                      : `Remove Free Gift`}
                                  </button>
                                </div>
                              </div>
                            ),
                          )}
                          <button
                            type="button"
                            onClick={() =>
                              addFocAddOnItemGroupPocketCoffee(planIndex)
                            }
                            className="rounded-[10px] bg-[#82d7d2] py-[9px] px-[24px]"
                          >
                            {focAddOnItemDetailsPocketCoffee[planIndex]
                              ?.length > 0
                              ? "Add Another Free Gift"
                              : "Enable Free Gift"}
                          </button>
                        </Fieldset>
                      </div>
                    </div>
                  )}
                  <button
                    onClick={() => removeOptionGroupPocketCoffee(planIndex)}
                    type="button"
                    className="mt-5 block ml-auto rounded-[10px] bg-red-500 text-white py-[9px] px-[24px] w-fit"
                  >
                    {formik_subscriptionBundlePocketCoffee.values.add_on_plans
                      .length === 1
                      ? "Disable Add On Option"
                      : `Remove Add On Option ${planIndex + 1}`}
                  </button>
                </div>
              ),
            )}
            <button
              type="button"
              onClick={addOptionGroupPocketCoffee}
              className="rounded-[10px] bg-[#82d7d2] py-[9px] px-[24px]"
            >
              {formik_subscriptionBundlePocketCoffee.values?.add_on_plans
                ?.length > 0
                ? "Add Another Add On Option"
                : "Enable Add On Option"}
            </button>
          </Fieldset>
        </div>
        <div className="flex justify-end">
          <AcceptButton
            label={
              formik_subscriptionBundlePocketCoffee.values.id > 0
                ? "Update"
                : "Save"
            }
            loading={productLoading}
            className="mt-[20px]"
            type="submit"
          />
        </div>
      </form>
    </div>
  );
};

export default BundlePocketCoffeeComponent;
